import React,{useRef} from "react";
import { Box, Button, Typography, Grid, Card, CardContent,CardMedia, CardActions, MenuItem, OutlinedInput, FormControl } from "@mui/material";
import shoppingcounter from '../images/shopping-counter.png';
import p2 from '../images/p2.png';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddToCartModal from "./AddToCartModal";


export default function Items() {
    const ref7=useRef();

    return (
        < Grid container spacing={3} className="items" >
            <Grid item md={4}>
                <Card className="pos-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={p2}
                        className="card-img"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="p">
                            Everest Cholle Masala
                        </Typography>
                        <Box className="flex flex-between align-center">
                            <Typography component='span' className="text-danger">Rs. 50/-</Typography>
                            <Typography component='span' className="small">50 gms</Typography>
                        </Box>
                        <Box className="text-center">
                            <Button variant="contained" onClick={() => ref7.current.log()}><ShoppingCartIcon /> ADD TO Cart</Button>
                        </Box>
                    </CardContent>
                    <CardActions className="card-footer">
                        <Typography component='span'>
                            <Box component='img' src={shoppingcounter}></Box>
                            Sri Srinivasa merchants
                        </Typography>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item md={4}>
                <Card className="pos-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={p2}
                        className="card-img"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="p">
                            Everest Cholle Masala
                        </Typography>
                        <Box className="flex flex-between align-center">
                            <Typography component='span' className="text-danger">Rs. 50/-</Typography>
                            <Typography component='span' className="small">50 gms</Typography>
                        </Box>
                        <Box className="text-center">
                            <Button variant="contained"><ShoppingCartIcon /> ADD TO Cart</Button>
                        </Box>
                    </CardContent>
                    <CardActions className="card-footer">
                        <Typography component='span'>
                            <Box component='img' src={shoppingcounter}></Box>
                            Sri Srinivasa merchants
                        </Typography>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item md={4}>
                <Card className="pos-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={p2}
                        className="card-img"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="p">
                            Everest Cholle Masala
                        </Typography>
                        <Box className="flex flex-between align-center">
                            <Typography component='span' className="text-danger">Rs. 50/-</Typography>
                            <Typography component='span' className="small">50 gms</Typography>
                        </Box>
                        <Box className="text-center">
                            <Button variant="contained"><ShoppingCartIcon /> ADD TO Cart</Button>
                        </Box>
                    </CardContent>
                    <CardActions className="card-footer">
                        <Typography component='span'>
                            <Box component='img' src={shoppingcounter}></Box>
                            Sri Srinivasa merchants
                        </Typography>
                    </CardActions>
                </Card>

            </Grid>
            <Grid item md={4}>
                <Card className="pos-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={p2}
                        className="card-img"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="p">
                            Everest Cholle Masala
                        </Typography>
                        <Box className="flex flex-between align-center">
                            <Typography component='span' className="text-danger">Rs. 50/-</Typography>
                            <Typography component='span' className="small">50 gms</Typography>
                        </Box>
                        <Box className="text-center">
                            <Button variant="contained"><ShoppingCartIcon /> ADD TO Cart</Button>
                        </Box>
                    </CardContent>
                    <CardActions className="card-footer">
                        <Typography component='span'>
                            <Box component='img' src={shoppingcounter}></Box>
                            Sri Srinivasa merchants
                        </Typography>
                    </CardActions>
                </Card>

            </Grid>
            <Grid item md={4}>
                <Card className="pos-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={p2}
                        className="card-img"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="p">
                            Everest Cholle Masala
                        </Typography>
                        <Box className="flex flex-between align-center">
                            <Typography component='span' className="text-danger">Rs. 50/-</Typography>
                            <Typography component='span' className="small">50 gms</Typography>
                        </Box>
                        <Box className="text-center">
                            <Button variant="contained"><ShoppingCartIcon /> ADD TO Cart</Button>
                        </Box>
                    </CardContent>
                    <CardActions className="card-footer">
                        <Typography component='span'>
                            <Box component='img' src={shoppingcounter}></Box>
                            Sri Srinivasa merchants
                        </Typography>
                    </CardActions>
                </Card>

            </Grid>
            <Grid item md={4}>
                <Card className="pos-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={p2}
                        className="card-img"
                    />
                    <CardContent >
                        <Typography gutterBottom variant="h5" component="p">
                            Everest Cholle Masala
                        </Typography>
                        <Box className="flex flex-between align-center">
                            <Typography component='span' className="text-danger">Rs. 50/-</Typography>
                            <Typography component='span' className="small">50 gms</Typography>
                        </Box>
                        <Box className="text-center">
                            <Button variant="contained"><ShoppingCartIcon /> ADD TO Cart</Button>
                        </Box>
                    </CardContent>
                    <CardActions className="card-footer">
                        <Typography component='span'>
                            <Box component='img' src={shoppingcounter}></Box>
                            Sri Srinivasa merchants
                        </Typography>
                    </CardActions>
                </Card>
                <AddToCartModal ref={ref7} />

            </Grid>
        </Grid >
    )
}
import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { OutlinedInput, FormControl } from "@mui/material";
import AddCustomerModal from "../Customer/AddCustomerModal";
import MemberTable from "../Customer/MemberTable";
import EditModal from "../Customer/EditModal";
import ViewMoreModal from "../Customer/ViewMoreModal";
import Bredcrum from "../Bredcrum";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchBar from "material-ui-search-bar";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Transport() {
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const pcColumns = [
    { name: "Transport Id", options: { filterOptions: { fullWidth: true } } },
    "Customer Name",
    "Passenger Name",
    "Date",
    "Total Amount",
    "Mode Of Payment",
    "Status Of Transport",
    "Invoice Number",
  ];

  const data = [
    [
      "32779",
      "deepak test test",
      "test Application",
      "2023-05-16 07:00:00",
      "0.00",
      "COD",
      "Completed",
    ],
  ];

  const driverstatementsOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box sx={{ display: "flex" }} className="filters">
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Locations</MenuItem>
                          <MenuItem>Debray</MenuItem>
                        </Select>
                        <FormControl>
                          <OutlinedInput type="date" />
                        </FormControl>
                        <FormControl>
                          <OutlinedInput type="date" />
                        </FormControl>
                        <Button
                          color="error"
                          ml={3}
                          variant="contained"
                          className="download-btn"
                        >
                          Download Report
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          ml={3}
                          className="download-btn"
                        >
                          Download Detailed Transport Report
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container mt={3} className="pickers">
                    <Grid xs="12" mt={2} mb={2} className="transport-div">
                      <Box>
                        <Typography component="h4">0</Typography>
                        <Typography component="p" className="text-green">
                          No Of Transports
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="h4">$0.00</Typography>
                        <Typography component="p" className="text-pink">
                          Total Amount
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="h4">$0</Typography>
                        <Typography component="p" className="text-sand">
                          Driver Fee
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="h4">$0.00</Typography>
                        <Typography component="p" className="text-green">
                          No Of Transports
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={3}>
                  <Grid item xs={12}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="member-table w-100">
                          <MemberTable
                            columns={pcColumns}
                            data={data}
                            options={driverstatementsOptions}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddCustomerModal ref={ref} />
        <EditModal ref={ref1} />
        <ViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}

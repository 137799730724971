const FieldsArr = [
    {
      label: "S.NO",
      name: "sno",
      validation: e => {
        const name = e.target.value;
        if (name.length > 6) {
          return false;
        } else {
          return true;
        }
      },
      error: "Enter a good name"
    },
    {
      label: "SKU ID",
      name: "skuid",
      validation: e => {
        const name = e.target.value;
        if (name.length > 6) {
          return false;
        } else {
          return true;
        }
      },
      error: "Haha"
    },
    {
      label: "ITEM NAME & DETAILS",
      name: "item",
      validation: e => {
        const name = e.target.value;
        if (name.length > 6) {
          return false;
        } else {
          return true;
        }
      },
      error: "Haha"
    },
    {
      label: "QUANTITY",
      name: "qty",
      validation: e => {
        const name = e.target.value;
        if (name.length > 6) {
          return false;
        } else {
          return true;
        }
      },
      error: "Haha"
    },
    {
      label: "PRICE",
      name: "price",
      validation: e => {
        const name = e.target.value;
        if (name.length > 6) {
          return false;
        } else {
          return true;
        }
      },
      error: "Haha"
    },
    {
      label: "TOTAL",
      name: "total",
      validation: e => {
        const name = e.target.value;
        if (name.length > 6) {
          return false;
        } else {
          return true;
        }
      },
      error: "Haha"
    }
  ];
  
  export default FieldsArr;
  
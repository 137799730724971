import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput, TextareaAutosize, FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MemberTable from '../Customer/MemberTable';
import EditModal from './EditModal';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const EquipmentModal = forwardRef((props, ref3, ref4) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);

      const ref2 = useRef();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const memeberColumns = [
        "DATE",
        "DRIVER",
        "START TIME",
        "END TIME",
        "PRE TRIP CHECK",
        "POST TRIP CHECK"
    ];

    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,


        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref3, () => ({
        log() {
            handleOpen();
        }
    }));

    useImperativeHandle(ref4, () => ({
        log() {
            handleOpen();
        }
    }));

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content new-entry'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            EQUIPMENT INFORMATION
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <Divider></Divider>
                    <Box className="p-15 flex-between" mt={2} mb={2}>
                    <Typography>EQUIPMENT INFORMATION</Typography>
                    <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn' onClick={() => ref2.current.log()}>Edit</Button>

                    </Box>
                    <Grid container spacing={3} className='p-15' >
                        <Grid item xs={2} sm={3} md={4} >
                           
                            <Box className="box-150">

                            </Box>

                        </Grid>
                        <Grid item xs={2} sm={3} md={4}>
                            <Box className="">
                                <Box>
                                    <FormControl className='w-100' >
                                        <Typography component="label">Equipment ID:</Typography>
                                        <Typography component="p">#147258</Typography>
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <FormControl className='w-100'>
                                        <Typography component="label">Model</Typography>
                                        <Typography component="p">#147258</Typography>
                                    </FormControl>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={3} md={4}>
                            <Box className="">
                                <Box mt={2}>
                                    <FormControl className='w-100' mt={2}>
                                        <Typography component="label">Make</Typography>
                                        <Typography component="p">Neonatal Incubator</Typography>
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <FormControl className='w-100' mt={2}>
                                        <Typography component="label">year</Typography>
                                        <Typography component="p">2021</Typography>
                                    </FormControl>
                                </Box>

                            </Box>
                        </Grid>


                    </Grid>


                    <Divider></Divider>
                    <Grid container spacing={4} className='p-15' >

                        <Box className="w-100" mt={2}>

                            <Typography component="h5" className='promo-subtitle' mt={2}>VEHICLE INSPECTIONS</Typography>
                            <Box className="member-table w-100" mt={2}>
                                <MemberTable columns={memeberColumns} options={memberOptions} />
                            </Box>


                        </Box>

                    </Grid>
                    <Grid container spacing={4} className='p-15' >

                        <Box className="w-100" mt={2}>

                            <Typography component="h5" className='promo-subtitle' mt={2}>MAINTENANCE LOG</Typography>
                            <Box className="member-table w-100" mt={2}>
                                <MemberTable columns={memeberColumns} options={memberOptions} />
                            </Box>


                        </Box>

                    </Grid>
                    <Grid container spacing={4} className='p-15' >

                        <Box className="w-100" mt={2}>

                            <Typography component="h5" className='promo-subtitle' mt={2}>UTILIZATION LOG</Typography>
                            <Box className="member-table w-100" mt={2}>
                                <MemberTable columns={memeberColumns} options={memberOptions} />
                            </Box>


                        </Box>

                    </Grid>






                    <Divider></Divider>
                    <Box className="p-15">
                        <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' onClick={handleClose}>Submit</Button>
                    </Box>
                </Box>
            </Modal >
            <EditModal ref={ref2} />
        </div >
    );
});

export default EquipmentModal;
import React,{useState,forwardRef,useImperativeHandle} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import {Divider, OutlinedInput} from '@mui/material';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import CountrySelect from './CountrySelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EditModal= forwardRef((props, ref1) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref1, () => ({
    log() {
        handleOpen();
    }
}));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       
      >
        <Box  sx={style}  className='addCustomerModal'>
            <Box className="modal-header" mb={2}>
          <Typography id="modal-modal-title" variant="h5" component="h5">
            EDIT CUSTOMER DETAILS
          </Typography>
          <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Grid container mt={2}>
          <Grid item xs={12}>
      <FormControl  style={{width:'97.5%'}}>
            <Typography component="label">Company Name</Typography>
        <OutlinedInput />
        
      </FormControl>
      </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
          
          <Grid item xs={6} >
          <FormControl style={{width:'95%'}}>
            <Typography component="label">Phone No</Typography>
            <OutlinedInput />
        
      </FormControl>
      </Grid>
      <Grid item xs={6} >
      <FormControl style={{width:'95%'}}>
            <Typography component="label">Email</Typography>
        <OutlinedInput />
        
      </FormControl>
      </Grid>
     
          </Grid>
        
         
        
              <Divider></Divider>
              <Button variant='outlined' color='error' mt={2} mb={2} className='save-btn'>Save</Button>
        </Box>
      </Modal>
    </div>
  );
});

export default EditModal;
import React, { useState, useRef } from "react";
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    Select,
    MenuItem,
    OutlinedInput,
    FormControl,
    Breadcrumbs,
    Link,
    Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";
import logo from "../images/logo-white.png";
import GroupedButtons from "./GroupedButtons";
import globe from '../images/globe.png';
import cart from '../images/cart.svg';
import notification from '../images/notification.png';
import profile from '../images/user.png';
import back from '../images/back.png';
import SwiperSlider from "./SwiperSlider";
import store from "../images/store.png";
import delivery from "../images/delivery-time.png";
import Caurosel from "./Caurosel";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function ProductView() {
    const [open, setOpen] = useState(false);
    const [pos, setPos] = useState("Billing");
    const [alignment, setAlignment] = React.useState("left");
    const [searchBtn, setSearchBtn] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [data, setData] = useState([
        { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
    ]);

    const changeTitle = (title) => {
        setPos(title);
    };
    const MySwal = withReactContent(Swal);
    const ItemColumns = [
        "S.NO",
        "SKU ID",
        "ITEM NAME & DETAILS",
        "QTY",
        "PRICE",
        "TOTAL",
        "",
    ];

    const customerColumns = ["Customer ID", "Customer Name", "Email", "Phone"];

    const customerData = [
        ["#258974", "Akhil", "akhil.@gmail.com", "9478982317"],
        ["#258974", "Suresh", "suresh.@gmail.com", "8327641236"],
    ];

    const billColumns = [
        "S.NO",
        "Invoice Id",
        "Date/Time",
        "Bill Amount",
        "Payment Mode",
    ];

    const billData = [
        [
            1,
            <Box component="a" href="#" onClick={() => ref9.current.log()}>
                #SKU89948
            </Box>,
            "24 Jan 2020 / 03:34 PM",
            "2,500",
            "UPI",
        ],
        [
            2,
            <Box component="a" href="#" onClick={() => ref9.current.log()}>
                #SKU89948
            </Box>,
            "24 Jan 2020 / 03:34 PM",
            "2,500",
            "UPI",
        ],
    ];

    const itemData = [
        [1, "#98759", "Rice", 2, "$ 200", "$400"],
        [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
    ];

    const orderColumns = ["ORDER ID", "CITY", "DATE", "CUSTOMER", "STATUS"];

    const orderData = [
        [
            "#258974",
            "Visakhapatnam",
            "02 Feb 2021 03:16 PM",
            "naveen patnaik",
            "Completed",
        ],
        [
            "#258974",
            "Rajahmundry",
            "02 Feb 2021 03:16 PM",
            "naveen patnaik",
            "In Progress",
        ],
    ];

    const driverData = [];

    const shiftOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch:
                    driverData.length === 0 ? "No Employee has been Scheduled" : "",
            },
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        },
    };

    const showSwalWithLink = () => {
        MySwal.fire({
            icon: "error",
            text: "Please Select On Start & End Dates",
        });
    };

    const itemOptions = {
        search: true,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: true,
        selectableRows: false,
        confirmFilters: false,
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        },
    };
    const [value, setValue] = React.useState(1);

    const [values, setValues] = React.useState(0);
    const [itemValue, setIemValue] = React.useState(0);
    const handleChangeItems = (event, newValue) => {
        setIemValue(newValue);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChanges = (event, newValue) => {
        setValues(newValue);
    };
    const [calenderValue, setCalenderValue] = React.useState(0);
    const handleChangeCalender = (event, newValue) => {
        setCalenderValue(newValue);
    };
    const ref = useRef();
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();
    const ref5 = useRef();
    const ref6 = useRef();
    const ref7 = useRef();
    const ref8 = useRef();
    const ref9 = useRef();

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <>
            <Box component="main">
                <Grid container>
                    <Grid item xs="12">
                        <Box className="w-100 text-center h-50">
                            <Typography component="p">Shop Wholesale online from 85,000 brands</Typography>
                        </Box>
                        {/* <Card xs="11" className="custom_card"> */}
                        <Grid container className="main-breadcrumb">
                            <Grid xs="12">
                                <Box className="top-bredcrum custom-padding">
                                    <Box src={logo} className="main-logo" component="img" />
                                    <Box>
                                        <SearchBar className="search-bar" />
                                    </Box>
                                    <Box className="icons">

                                        <Box
                                            component="img"
                                            src={globe}
                                            onClick={() => ref.current.log()}
                                        />


                                        <Typography component="h5" ml={1}>
                                            EN-GB |
                                        </Typography>
                                        <Box className="p-relative">
                                            <Box
                                                component="img"
                                                src={cart}
                                                onClick={() => ref.current.log()}
                                                ml={1}
                                            />
                                            <Box class="count">
                                                <Typography component='p'><Typography component='small'>34</Typography></Typography>
                                            </Box>
                                        </Box>
                                        <Typography component="label" ml={1}>
                                            Cart
                                        </Typography>
                                        <Box className="p-relative">
                                            <Box
                                                component="img"
                                                src={notification}
                                                onClick={() => ref.current.log()}
                                                ml={1}
                                            />
                                            <Box class="p-count">
                                                <Typography component='p'><Typography component='small'>34</Typography></Typography>
                                            </Box>
                                        </Box>
                                        <Typography component="label" ml={1}>
                                            Notification
                                        </Typography>
                                        <Box
                                            component="img"
                                            src={profile}
                                            onClick={() => ref.current.log()}
                                            ml={1}
                                        />
                                        <Typography component="label" ml={1}>
                                            Profile
                                        </Typography>
                                        <Button variant="filled" className="invite-supplier-btn">INVITE SUPPLIER</Button>
                                    </Box>


                                </Box>
                                <Box className="breadcrumb-sec">
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/Procurement/Bazaar">
                                            <Box component='img' src={back} />
                                        </Link>
                                        <Link underline="hover" color="inherit" href="/">
                                            Home
                                        </Link>
                                        <Typography color="text.primary">Product Detail View</Typography>
                                    </Breadcrumbs>

                                </Box>
                            </Grid>
                        </Grid>
                        <Box padding={2}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <Grid container className="text-center">
                                        <Grid item md={8}>
                                            <Box className="w-100 p-15 custom-swiper-slider">
                                                <SwiperSlider />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Typography component='h1'>
                                        Tata Salt Iodized
                                    </Typography>
                                    <Box className="flex" mt={1}>
                                        <Typography>
                                            Rs 50
                                        </Typography>
                                        <Stack spacing={1}>
                                            <Rating name="half-rating-read" defaultValue={4.0} precision={0.5} readOnly />
                                        </Stack>
                                        <Typography component='span' className="badge bg-success">
                                            4.0
                                        </Typography>
                                    </Box>
                                    <Typography component='label' mt={1}>
                                        @ Sri Srinivasa Merchants
                                    </Typography>
                                    <Typography component='h4' mt={1}>
                                        Indian authentic flavours with no added flavours. best in quality & premium taste.Indian authentic flavours with no added flavours. best in quality & premium taste.
                                    </Typography>
                                    <Box class="pro-view-qantity" mt={1}>
                                        <Button variant="outlined" className="active">50 gms</Button>
                                        <Button variant="outlined">100 gms</Button>
                                        <Button variant="outlined">250 gms</Button>
                                        <Button variant="outlined">500 gms</Button>
                                        <Button variant="outlined">1 KG</Button>
                                    </Box>
                                    <Box className="flex" mt={2}>
                                        <GroupedButtons />
                                        <Button variant="contained ml-20 p-button" href="/procurement/Invoice">Add to Cart</Button>
                                        <FormControl className="flex ml-20">

                                            <OutlinedInput placeholder="promo code" />
                                            <Box className="pcode custom-ml">Apply</Box>
                                        </FormControl>
                                    </Box>
                                    <Box className="flex" mt={2}>
                                        <Box component='img' src={store} />
                                        <Typography component='label' ml={1}>Store Location</Typography>
                                        <Typography component='p' ml={1} className="font-bold">Hyderabad</Typography>
                                    </Box>
                                    <Box className="flex" mt={1}>
                                        <Box component='img' src={delivery}  />
                                        <Typography component='label' ml={1}>Delivery Time</Typography>
                                        <Typography component='p' ml={1} className="text-success font-bold"> 6 Hrs</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                        <Box className="p-20">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Instructions" {...a11yProps(0)} />
                                <Tab label="Specifications" {...a11yProps(1)} />
                                <Tab label="Offers" {...a11yProps(2)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <Box mt={2}>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire.
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                            <Box mt={2}>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire.
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                            <Box className="flex" mt={2}>
                                <Card className="w-200 p-15">
                                    <CardContent>
                                        <Box component='a' href="#" className="text-a">GET 15% OFF</Box>
                                        <Typography component='p' mt={1} mb={1}>Grab Now !!!!!!
                                            Get upto 15% OFF on ordering of 10 boxes at a time</Typography>
                                            <Button variant="outlined" mt={1} className="offers-apply-btn">-Apply Now-</Button>
                                            <Box component='br' />
                                            <Box mt={2} className="offers-card" component="a" href="#">*Terms & Conditions apply</Box>
                                    </CardContent>
                                </Card>
                                <Card className="w-200 p-15">
                                    <CardContent>
                                        <Box component='a' href="#" className="text-a">GET 15% OFF</Box>
                                        <Typography component='p' mt={1} mb={1}>Grab Now !!!!!!
                                            Get upto 15% OFF on ordering of 10 boxes at a time</Typography>
                                            <Button variant="outlined" mt={1} className="offers-apply-btn">-Apply Now-</Button>
                                            <Box component='br' />
                                            <Box mt={2} className="offers-card" component="a" href="#">*Terms & Conditions apply</Box>
                                    </CardContent>
                                </Card>
                                <Card className="w-200 p-15">
                                    <CardContent>
                                        <Box component='a' href="#" className="text-a">GET 15% OFF</Box>
                                        <Typography component='p' mt={1} mb={1}>Grab Now !!!!!!
                                            Get upto 15% OFF on ordering of 10 boxes at a time</Typography>
                                            <Button variant="outlined" mt={1} className="offers-apply-btn">-Apply Now-</Button>
                                            <Box component='br' />
                                            <Box mt={2} className="offers-card" component="a" href="#">*Terms & Conditions apply</Box>
                                    </CardContent>
                                </Card>
                                </Box>
                            </TabPanel>
                        </Box>
                        <Box>
                            <Caurosel />
                        </Box>
                        {/* </Card> */}
                    </Grid>
                </Grid>
                {/* <Toolbar /> */}
            </Box>
        </>
    );
}

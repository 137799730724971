import React from "react";

import EditableTable from "./EditableTable";
import FieldsArr from "./FieldsArr";


const defaultData = [
  {
    sno: "1",
    skuid: "#98759",
    item: "Rice",
    qty:"2",
    price:'	Rs 200',
    total:'Rs 400'
  }
];
export default function EditTable() {
  const getData = row => {
    console.log(row, "rows data");
  };

  return (
  
      <EditableTable
        initWithoutHead
        defaultData={defaultData}
        getData={getData}
        fieldsArr={FieldsArr}
      />

  );
}



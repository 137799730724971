import React, { useImperativeHandle, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput, Divider } from '@mui/material';
import MemberTable from '../Customer/MemberTable';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const EditProductModal = forwardRef((props, ref8) => {
    const [open, setOpen] = React.useState(false);
    const [passwd, setPasswd] = React.useState(false);
    const handlePassword = () => {
        setPasswd(passwd => !passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const ItemColumns = [
        "S.NO",
        "SKU ID",
        "ITEM NAME & DETAILS",
        "QUANTITY",
        "PRICE",
        "TOTAL"
    ];
    const itemData = [
        [1, "#123456", "Chena Dal", "2kgs", "₹ 45", "₹ 90"],
        [2, "#78901", "Moong Dal", "3kgs", "₹ 120", "₹ 360"],
        [3, "#234567", "Freedom Oil", "3 lit", "₹ 140", "₹ 420"]
    ];
    const itemOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: true,
        selectableRows: false,
        confirmFilters: false,
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };
    const showSwalWithLink = () => {
        MySwal.fire({
            icon: 'error',
            text: "All Fields Are Mandatory",
        });
    };

    useImperativeHandle(ref8, () => ({
        log() {
            handleOpen();
        }
    }));


    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal'
            >
                <Box sx={{ ...style }} className='profile-modal'>
                    <Grid container>
                        <Grid item xs={12} className="profile-header p-15" >

                            <Typography id="parent-modal-title" component="h4">EDIT PRODUCTS</Typography>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <Divider></Divider>

                    <Grid container className='details-div p-15' columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid xs={12} md={12}>
                            <MemberTable columns={ItemColumns} options={itemOptions} data={itemData} />
                        </Grid>
                        <Grid xs={12} md={12} mt={2}>
                            <FormControl>
                                <Typography component="label">Admin Password</Typography>
                            <OutlinedInput type='password' placeholder='*******' className='search' />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
               <Box className="p-15 text-right">
               <Button variant='outlined' color='error' className='retail-btn mr-15'  onClick={handleClose}>Submit</Button>

               </Box>
                </Box>
            </Modal>
        </div>
    );
});

export default EditProductModal;
import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Bredcrum from "../Bredcrum";
import Calender from "../Calender/Calender";
import MemberTable from "../Customer/MemberTable";
import AddEmployeeModal from "./AddEmployeeModal";
import ManagementViewMoreModal from "./ManagementViewMoreModal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormControlLabel } from "@mui/material";
// import { useDemoData } from "@mui/x-data-grid-generator";

const year = ["2023", "2022", "2021", "2020"];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const pastmanagementColumns = [
  { name: "Employee Name", options: { filterOptions: { fullWidth: true } } },
  "Location",
  "Designation",
  "Date of Joining",
  "Last Working Day",
  "Reason",
  "Status",
];
const pastmanagementdata = [
  [
    "Andrew",
    "Debary",
    "Cashier",
    "05 July 2023",
    "14 July 2023",
    "Sick Leave",
    "",
  ],
  [
    "Zeus",
    "Debary",
    "Cashier",
    "05 July 2023",
    "14 July 2023",
    "Sick Leave",
    "",
  ],
];

const FeesColumns = [
  { name: "Fee Name", options: { filterOptions: { fullWidth: true } } },
  "Fee Type",
  "Fee Amount",
  "Fee Status",
  "",
];
const Feesdata = [
  [
    "Convenience Fee",
    "Percentage",
    "1",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
  [
    "Shipping & Handling Fee",
    "Percentage",
    "3",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];
const managementColumns = [
  { name: "Tax Name", options: { filterOptions: { fullWidth: true } } },
  "Tax Type",
  "Tax Amount",
  "Tax Segment",
  "Tax Sub Segment",
  "Tax Status",
  "",
];
const managementdata = [
  [
    "CGST",
    "Percentage",
    "9",
    "",
    "",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
  [
    "IGST",
    "Percentage",
    "19",
    "",
    "",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
  [
    "Consumer Tax",
    "Flat",
    "12",
    "",
    "",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const tstatementsColumns = [
  { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
  "End Date",
  "Total Transport",
  "Total Amount",
  "PayOut Date",
  "PayOut Mode",
  "Payment CNF ID",
  "Action",
  "",
  "",
];
const tstatementsdata = [
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
];

const tstatementsOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: true,
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    editable: true,
  },
  {
    field: "totalTransport",
    headerName: "Total Transport",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "earnings",
    headerName: "Earnings",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "leaves",
    headerName: "Leaves",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "compliance",
    headerName: "Compliance",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 150,
    editable: true,
  },
];

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const rows = [
  { id: 1, driverCity: "Snow", driverName: "Jon", phoneNumber: 8121542167 },
  {
    id: 2,
    driverCity: "Lannister",
    driverName: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    driverCity: "Lannister",
    driverName: "Jaime",
    phoneNumber: 8121542167,
  },
  { id: 4, driverCity: "Stark", driverName: "Arya", phoneNumber: 8121542167 },
  {
    id: 5,
    driverCity: "Targaryen",
    driverName: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    driverCity: "Melisandre",
    driverName: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    driverCity: "Clifford",
    driverName: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    driverCity: "Frances",
    driverName: "Rossini",
    phoneNumber: 8121542167,
  },
  { id: 9, driverCity: "Roxie", driverName: "Harvey", phoneNumber: 8121542167 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TaxFees() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const ref4 = React.useRef();
  const ref1 = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />

              <CardContent>
                <Grid container className="full_content">
                  <Grid container spacing={2}>
                    <Grid item md="12" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container>
                            <Grid item md="12">
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Typography mb={2} className="t-heading">
                                  Taxes
                                </Typography>
                                <Button
                                  variant="outlined"
                                  className="btn-outline-primary"
                                >
                                  Add
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item md="12">
                              <MemberTable
                                columns={managementColumns}
                                options={managementOptions}
                                data={managementdata}
                              />
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid item md="12">
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Typography mb={2} className="t-heading">
                                  Fees
                                </Typography>
                                <Button
                                  variant="outlined"
                                  className="btn-outline-primary"
                                >
                                  Add
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item md="12">
                              <MemberTable
                                columns={FeesColumns}
                                options={managementOptions}
                                data={Feesdata}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddEmployeeModal ref={ref4} />
        <ManagementViewMoreModal ref={ref1} />
      </Box>
    </>
  );
}

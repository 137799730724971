import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CardMedia from "@mui/material/CardMedia";
import customers from "../images/customers.svg";
import logout from "../images/logout.svg";
import p2 from "../images/p2.png";
import Swal from "sweetalert2";
import customer from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import mail from "../images/mail.svg";
import withReactContent from "sweetalert2-react-content";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";
import MemberTable from "../Customer/MemberTable";
import addPerson from "../images/person-plus-fill.svg";
import door_delivery from "../images/door-delivery.svg";
import logo from "../images/logo-white.png";
import curb from "../images/curb.svg";
import store from "../images/store.svg";
import Tooltip from "@mui/material/Tooltip";
import info from "../images/info-circle.svg";
import clear from "../images/clear.svg";
import discount from "../images/Discount.svg";
import invoice from "../images/Invoice.svg";
import upscan from "../images/upc-scan.svg";
import Slider from "./Slider";
import Items from "./Items";
import walmart from "../images/Walmart.jpg";
import CustomerDetailsModal from "./CustomerDetailsModal";
import CurbModal from "./CurbModal";
import StorePickupModal from "./StorePickupModal";
import DiscountModal from "./DiscountModal";
import ScanModal from "./ScanModal";
import InvoiceDetailsModal from "./InvoiceDetailsModal";
import print from "../images/print-invoice.svg";
import edit from "../images/edit.svg";
import EditProductModal from "./EditProductModal";
import GroupedButtons from "./GroupedButtons";
import cross1 from "../images/cross.svg";
import CustomerInvoiceDetailsModal from "./CustomerInvoiceDetailsModal";
import EditTable from "./EditTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Pos() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = [
    "S.NO",
    "SKU ID",
    "ITEM NAME & DETAILS",
    "QTY",
    "PRICE",
    "TOTAL",
    "",
  ];

  const customerColumns = ["Customer ID", "Customer Name", "Email", "Phone"];

  const customerData = [
    ["#258974", "Akhil", "akhil.@gmail.com", "9478982317"],
    ["#258974", "Suresh", "suresh.@gmail.com", "8327641236"],
  ];

  const billColumns = [
    "S.NO",
    "Invoice Id",
    "Date/Time",
    "Bill Amount",
    "Payment Mode",
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];
  const orderItemData = [
    [
      5,
      "#SKU89948",
      "Samosa & Snacks",
      <GroupedButtons />,
      "$ 200",
      "$400",
      <img className="cross" src={cross1} />,
    ],
    [
      10,
      "#SKU89948",
      "Samosa & Snacks",
      <GroupedButtons />,
      "$ 200",
      "$400",
      <img className="cross" src={cross1} />,
    ],
  ];
  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns = ["ORDER ID", "CITY", "DATE", "CUSTOMER", "STATUS"];

  const orderData = [
    [
      "#258974",
      "Visakhapatnam",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "Completed",
    ],
    [
      "#258974",
      "Rajahmundry",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "In Progress",
    ],
  ];

  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(0);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  <Box>
                    <Typography component="h2">Phone Orders {pos}</Typography>
                  </Box>
                  <Box className="icons">
                    <Box
                      component="img"
                      src={customers}
                      onClick={() => ref.current.log()}
                    />

                    <Typography component="h5" ml={1}>
                      Ramesh Kumar
                    </Typography>
                    <Box component="img" src={logout} ml={2} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box className="w-100 sub-div flex-between plr-15">
              <Box className="flex ml-20">
                <Box className="flex color-white">
                  <DashboardIcon />
                  <Typography component="h4" ml={1}>
                    Dashboard
                  </Typography>
                </Box>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="ml-20 location-select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Location</MenuItem>
                  <MenuItem>MVP</MenuItem>
                  <MenuItem>CBM</MenuItem>
                  <MenuItem>RK Beach</MenuItem>
                </Select>
              </Box>
              <FullscreenIcon className="fullscreen mr-20" />
            </Box>
            <Box className="w-100 pos-cardcontent">
              <Grid container className="full_content">
                <Box className="main-bg" sx={{ flexGrow: 1, display: "flex" }}>
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                    className="vertical-tabs"
                  >
                    <Tab
                      label="BILLING"
                      {...a11yProps(0)}
                      onClick={() => changeTitle("Billing")}
                    />
                    <Tab
                      label="CUSTOMERS"
                      {...a11yProps(1)}
                      onClick={() => changeTitle("Customers")}
                    />
                    <Tab
                      label="ORDERS"
                      {...a11yProps(2)}
                      onClick={() => changeTitle("Orders")}
                    />
                  </Tabs>
                  <TabPanel value={value} index={0} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box
                          className="w-100 d-flex align-center flex-between"
                          mb={2}
                        >
                          <SearchBar className="search-bar" />
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={3}
                            className="retail-btn float-right posmain-btn"
                            onClick={() => ref5.current.log()}
                          >
                            <Box
                              src={upscan}
                              component="img"
                              className="upscan"
                            />
                            <Box component="span">SCAN</Box>
                          </Button>
                        </Box>
                        <Box mb={3} mt={3}>
                          <Slider />
                        </Box>
                        <Box sx={{ width: "100%" }} className="items-div">
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={itemValue}
                              onChange={handleChangeItems}
                              aria-label="basic tabs example"
                              className="multitabs"
                            >
                              <Tab label="ATTA & FLOURS" {...a11yProps(0)} />
                              <Tab label="OIL & GHEE" {...a11yProps(1)} />
                              <Tab label="RICE" {...a11yProps(2)} />
                              <Tab label="DALS&PULSES" {...a11yProps(3)} />
                              <Tab label="MASALA" {...a11yProps(4)} />
                              <Tab label="BODY CARE" {...a11yProps(5)} />
                              <Tab label="DAIRY" {...a11yProps(6)} />
                              <Tab label="MASALA" {...a11yProps(7)} />
                            </Tabs>
                          </Box>
                          <TabPanel value={itemValue} index={0}>
                            <Items />
                          </TabPanel>
                          <TabPanel value={itemValue} index={1}>
                            <Items />
                          </TabPanel>
                          <TabPanel value={itemValue} index={2}>
                            <Items />
                          </TabPanel>
                          <TabPanel value={itemValue} index={3}>
                            <Items />
                          </TabPanel>
                          <TabPanel value={itemValue} index={4}>
                            <Items />
                          </TabPanel>
                          <TabPanel value={itemValue} index={5}>
                            <Items />
                          </TabPanel>
                          <TabPanel value={itemValue} index={6}>
                            <Items />
                          </TabPanel>
                          <TabPanel value={itemValue} index={7}>
                            <Items />
                          </TabPanel>
                          <TabPanel value={itemValue} index={8}>
                            <Items />
                          </TabPanel>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={values}
                              onChange={handleChanges}
                              aria-label="basic tabs example"
                              className="pos-billing"
                            >
                              <Tab label="Billing 1" {...a11yProps(0)} />
                              <Tab label="Billing 2" {...a11yProps(1)} />
                              <Tab label="Billing 3" {...a11yProps(2)} />
                            </Tabs>
                          </Box>
                          <TabPanel
                            value={values}
                            index={0}
                            className="bg-white  p-15"
                          >
                            <Box className="w-100 flex-between" mb={2} mt={1}>
                              <Typography
                                component="h4"
                                className="tab-sub-title"
                              >
                                #INVOICE23009
                              </Typography>
                              <Typography component="label">
                                5/11/2023 | 19:2
                              </Typography>
                            </Box>
                            <Box className="w-100 flex align-center" mb={2}>
                              <FormControl className="flex">
                                <Box className="code">+91</Box>
                                <OutlinedInput
                                  placeholder="9876543210"
                                  className="custom-ml"
                                />
                              </FormControl>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                mb={3}
                                className="retail-btn ml-20 height-22"
                                onClick={handleClose}
                              >
                                Search
                              </Button>
                              <Box
                                ml={4}
                                src={addPerson}
                                component="img"
                                className="add-img"
                                onClick={() => ref1.current.log()}
                              />
                            </Box>
                            <Box className="w-100 flex flex-between order-values">
                              <Box className="information-div">
                                <Box component="img" src={customer} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography component="h5">
                                    Sri Srinivasa
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography component="h5">
                                    +91 90528 45869
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={mail} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography component="h5">
                                    Srinivas@gmail.com
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              className="w-100 flex flex-between order-values sub-order"
                              mt={2}
                            >
                              <Box className="information-div active">
                                <Box component="img" src={door_delivery} />
                                <Box>
                                  <Typography component="h5">
                                    Door Delivery
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                className="information-div"
                                onClick={() => ref2.current.log()}
                              >
                                <Box component="img" src={curb} />
                                <Box>
                                  <Typography component="h5">
                                    Curb Slide Pickup
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                className="information-div"
                                onClick={() => ref3.current.log()}
                              >
                                <Box component="img" src={store} />
                                <Box>
                                  <Typography component="h5">
                                    Store Pickup
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box className="w-100" mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={ItemColumns}
                                data={orderItemData}
                              />
                            </Box>
                            <Box className="flex-between bg-light px-10" mt={2}>
                              <Typography component="p">
                                No Of Items : 18
                              </Typography>
                              <Typography component="p" className="flex">
                                Grand Total <b className="ml-20"> $ 2980.00</b>
                              </Typography>
                              <Tooltip
                                className="tooltip"
                                title={
                                  <React.Fragment>
                                    <Typography component="label">
                                      Sub Total - $ 95.00
                                    </Typography>
                                    <Typography component="label">
                                      Tax - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Gst - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Discount - $ 15.00
                                    </Typography>
                                    <Typography component="p">
                                      <b>Grand Total - $ 15.00</b>
                                    </Typography>
                                  </React.Fragment>
                                }
                                placement="left"
                                arrow
                              >
                                <Box component="img" src={info} />
                              </Tooltip>
                            </Box>
                            <Box mt={2} className="flex-around pos-btns">
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={clear} component="img" />
                                <Typography component="span"> Clear</Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={() => ref4.current.log()}
                              >
                                {" "}
                                <Box src={discount} component="img" />
                                <Typography component="span">
                                  Discount
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={() => ref6.current.log()}
                              >
                                <Box src={invoice} component="img" />
                                <Typography component="span">
                                  Place Order
                                </Typography>
                              </Button>
                            </Box>
                          </TabPanel>
                          <TabPanel
                            value={values}
                            index={1}
                            className="bg-white  p-15"
                          >
                            <Box className="w-100 flex-between" mb={2} mt={2}>
                              <Typography
                                component="h4"
                                className="tab-sub-title"
                              >
                                #INVOICE237714
                              </Typography>
                            </Box>
                            <Box className="w-100 flex align-center" mb={2}>
                              <FormControl className="flex">
                                <Box className="code">+91</Box>
                                <OutlinedInput placeholder="9876543210" />
                              </FormControl>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                mb={3}
                                className="retail-btn ml-20"
                                onClick={handleClose}
                              >
                                Search
                              </Button>
                              <Box
                                ml={4}
                                src={addPerson}
                                component="img"
                                className="add-img"
                              />
                            </Box>
                            <Box className="w-100 flex flex-between order-values">
                              <Box className="information-div">
                                <Box component="img" src={customer} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography component="h5">
                                    Ghanshyam
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography component="h5">
                                    +91 90528 45869
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={mail} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography component="h5">
                                    ghanshyam@gmail.com
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              className="w-100 flex flex-between order-values sub-order"
                              mt={2}
                            >
                              <Box className="information-div active">
                                <Box component="img" src={door_delivery} />
                                <Box>
                                  <Typography component="h5">
                                    Door Delivery
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={curb} />
                                <Box>
                                  <Typography component="h5">
                                    Curb Slide Pickup
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={store} />
                                <Box>
                                  <Typography component="h5">
                                    Store Pickup
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box className="w-100" mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={ItemColumns}
                                data={itemData}
                              />
                            </Box>
                            <Box className="flex-between bg-light px-10" mt={2}>
                              <Typography component="p">
                                No Of Items : 18
                              </Typography>
                              <Typography component="p" className="flex">
                                Grand Total <b className="ml-20"> $ 2980.00</b>
                              </Typography>
                              <Tooltip
                                className="tooltip"
                                title={
                                  <React.Fragment>
                                    <Typography component="label">
                                      Sub Total - $ 95.00
                                    </Typography>
                                    <Typography component="label">
                                      Tax - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Gst - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Discount - $ 15.00
                                    </Typography>
                                    <Typography component="p">
                                      <b>Grand Total - $ 15.00</b>
                                    </Typography>
                                  </React.Fragment>
                                }
                                placement="left"
                                arrow
                              >
                                <Box component="img" src={info} />
                              </Tooltip>
                            </Box>
                            <Box mt={2} className="flex-around pos-btns">
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={clear} component="img" />
                                <Typography component="span"> Clear</Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                {" "}
                                <Box src={discount} component="img" />
                                <Typography component="span">
                                  Discount
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={invoice} component="img" />
                                <Typography component="span">
                                  Place Order
                                </Typography>
                              </Button>
                            </Box>
                          </TabPanel>
                          <TabPanel
                            value={values}
                            index={2}
                            className="bg-white  p-15"
                          >
                            <Box className="w-100 flex-between" mb={2} mt={2}>
                              <Typography
                                component="h4"
                                className="tab-sub-title"
                              >
                                #INVOICE23009
                              </Typography>
                            </Box>
                            <Box className="w-100 flex align-center" mb={2}>
                              <FormControl className="flex custom-ml">
                                <Box className="code">+91</Box>
                                <OutlinedInput placeholder="9876543210" />
                              </FormControl>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                mb={3}
                                className="retail-btn ml-20"
                                onClick={handleClose}
                              >
                                Search
                              </Button>
                              <Box
                                ml={4}
                                src={addPerson}
                                component="img"
                                className="add-img"
                              />
                            </Box>
                            <Box className="w-100 flex flex-between order-values">
                              <Box className="information-div">
                                <Box component="img" src={customer} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography component="h5">
                                    Vasanth
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography component="h5">
                                    +91 90528 45869
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={mail} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography component="h5">
                                    vasanth@gmail.com
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              className="w-100 flex flex-between order-values sub-order"
                              mt={2}
                            >
                              <Box className="information-div active">
                                <Box component="img" src={door_delivery} />
                                <Box>
                                  <Typography component="h5">
                                    Door Delivery
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={curb} />
                                <Box>
                                  <Typography component="h5">
                                    Curb Slide Pickup
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={store} />
                                <Box>
                                  <Typography component="h5">
                                    Store Pickup
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box className="w-100" mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={ItemColumns}
                                data={itemData}
                              />
                            </Box>
                            <Box className="flex-between bg-light px-10" mt={2}>
                              <Typography component="p">
                                No Of Items : 18
                              </Typography>
                              <Typography component="p" className="flex">
                                Grand Total <b className="ml-20"> $ 2980.00</b>
                              </Typography>
                              <Tooltip
                                className="tooltip"
                                title={
                                  <React.Fragment>
                                    <Typography component="label">
                                      Sub Total - $ 95.00
                                    </Typography>
                                    <Typography component="label">
                                      Tax - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Gst - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Discount - $ 15.00
                                    </Typography>
                                    <Typography component="p">
                                      <b>Grand Total - $ 15.00</b>
                                    </Typography>
                                  </React.Fragment>
                                }
                                placement="left"
                                arrow
                              >
                                <Box component="img" src={info} />
                              </Tooltip>
                            </Box>
                            <Box mt={2} className="flex-around pos-btns">
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={clear} component="img" />
                                <Typography component="span"> Clear</Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                {" "}
                                <Box src={discount} component="img" />
                                <Typography component="span">
                                  Discount
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={invoice} component="img" />
                                <Typography component="span">
                                  Place Order
                                </Typography>
                              </Button>
                            </Box>
                          </TabPanel>
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Card>
                          <CardContent className="p-15">
                            <Box className="w-100 " mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={customerColumns}
                                data={customerData}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Card className="bill-pos">
                          <CardContent>
                            <Grid container spacing={4}>
                              <Grid item md={3}>
                                <Box className="w-100">
                                  <Box
                                    src={walmart}
                                    style={{ width: "150px" }}
                                    component="img"
                                  />
                                </Box>
                              </Grid>

                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Customer Name
                                  </Typography>
                                  <Typography component="p">
                                    Richards Parker
                                  </Typography>
                                </Box>
                                <Box mt={2}>
                                  <Typography component="label">
                                    Address
                                  </Typography>
                                  <Typography component="p">
                                    Flat N0 502, Doctors Colony, Prism
                                    Street,Vishakhapatnam
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Contact No
                                  </Typography>
                                  <Typography component="p">
                                    +91-9638527410
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Email Id
                                  </Typography>
                                  <Typography component="p">
                                    prasad@gmail.com
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="w-100 " mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={billColumns}
                                data={billData}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={2} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent className="p-15">
                            <Box className="flex w-100 flex-between" mt={2}>
                              <FormControl className="flex">
                                <Typography component="label"> Show</Typography>
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="ml-20 location-select ws-80"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="locations"
                                >
                                  <MenuItem value="locations">10</MenuItem>
                                  <MenuItem>One</MenuItem>
                                  <MenuItem>Two</MenuItem>
                                  <MenuItem>Three</MenuItem>
                                </Select>
                              </FormControl>
                              <Box>
                                <OutlinedInput
                                  placeholder="Search"
                                  className="search w-130"
                                />

                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="ml-20 location-select w-130"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="locations"
                                >
                                  <MenuItem value="locations">
                                    In Progress
                                  </MenuItem>
                                  <MenuItem>One</MenuItem>
                                  <MenuItem>Two</MenuItem>
                                  <MenuItem>Three</MenuItem>
                                </Select>
                              </Box>
                            </Box>

                            <Box className="w-100 " mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={orderColumns}
                                data={orderData}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent className="p-15 custom-edit">
                            <Box className="w-100 flex-between" mb={2} mt={2}>
                              <Typography component="h4">
                                #INVOICE23009
                              </Typography>
                              <Typography component="h4">
                                16-Mar-2021 | 03:34 PM
                              </Typography>
                            </Box>

                            <Box className="w-100 flex flex-between order-values orders">
                              <Box className="information-div">
                                <Box component="img" src={customer} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography component="h5">
                                    Sri Srinivasa
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mobile}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography component="h5">
                                    +91 90528 45869
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mail}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography component="h5">
                                    Srinivas@gmail.com
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mail}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Delivery Type
                                  </Typography>
                                  <Typography component="h5">
                                    Curbside Pickup
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              mt={2}
                              mb={2}
                              className="flex-between pos-btns pos-orders-btns"
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={print} component="img" />
                                <Typography component="span">
                                  {" "}
                                  Print Invoice
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={() => ref8.current.log()}
                              >
                                {" "}
                                <Box src={edit} component="img" />
                                <Typography component="span">Submit</Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                              >
                                <Typography component="span">Add</Typography>
                              </Button>
                            </Box>
                            <Box
                              className="member-table scroll-editabletable"
                              mt={2}
                            >
                              {/* <MemberTable options={itemOptions} columns={ItemColumns} data={itemData} /> */}
                              <EditTable />
                            </Box>
                            <Box className="grand-total">
                              <Typography component="p">
                                No Of Items : 18
                              </Typography>
                              <Box>
                                <Typography component="p">
                                  Sub Total ₹ 1050.00
                                </Typography>
                                <Typography component="p">
                                  Discount ₹ 100.00
                                </Typography>
                                <Typography component="p">
                                  Tax ₹ 20.00
                                </Typography>
                                <Typography component="p">
                                  Grand Total ₹ 2980.00
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </Grid>
            </Box>

            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <ProfileModal ref={ref} />
        <CustomerDetailsModal ref={ref1} />
        <CurbModal ref={ref2} />
        <StorePickupModal ref={ref3} />
        <DiscountModal ref={ref4} />
        <ScanModal ref={ref5} />
        <InvoiceDetailsModal ref={ref6} />
        <EditProductModal ref={ref8} />
        <CustomerInvoiceDetailsModal ref={ref9} />
      </Box>
    </>
  );
}

import React, { useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Divider,
} from "@mui/material";
import MemberTable from "../Customer/MemberTable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import wallet from "../images/wallet.png";
import cod from "../images/cod.png";
import cash from "../images/cash.png";
import credit from "../images/credit.png";
import upi from "../images/upi.png";
import customer from "../images/customers.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MySwal = withReactContent(Swal);

const InvoiceDetailsModal = forwardRef((props, ref6) => {
  const [showState, setShowState] = React.useState(false);
  const [show, setShow] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [pay, setPay] = React.useState(false);
  const [passwd, setPasswd] = React.useState(false);
  const handlePassword = () => {
    setPasswd((passwd) => !passwd);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changePay = () => {
    setPay((pay) => !pay);
  };

  const handleClick = () => {
    setShowState(!showState);
  };
  const payclick = () => {
    setShowState(!showState);
  };
  const ItemColumns = [
    "S.NO",
    "SKU ID",
    "ITEM NAME & DETAILS",
    "QTY",
    "PRICE",
    "TOTAL",
  ];
  const itemData = [
    ["Cream", "#648744", "35", "₹ 100.00", "₹ 3500.00"],
    ["Facewash", "#627888", "40", "₹ 200.00", "	₹ 8000.00"],
    ["", "", "", "	Sub Total", "	₹ 11500.00"],
    ["", "", "", "	Discount", "	₹ 500.00"],
    ["", "", "", "	Total Tax", "	₹ 400.00"],
    ["", "", "", "	CGST", "	₹ 100.00"],
    ["", "", "", "	SGST", "	₹ 100.00"],

    ["", "", "", "	IGST", "	₹ 100.00"],
    ["", "", "", "	UTGST", "	₹ 100.00"],
    ["", "", "", "	Compensatory Cess Rate", "		₹ 50.00"],
  ];
  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "All Fields Are Mandatory",
    });
  };

  useImperativeHandle(ref6, () => ({
    log() {
      handleOpen();
    },
  }));

  //   if(openM){
  //     handleOpen();
  //   }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="parent-modal "
      >
        <Box sx={{ ...style }} className="profile-modal modal-md">
          <Grid container>
            <Grid item xs={12} className="profile-header p-15">
              <Typography id="parent-modal-title" component="h4">
                INVOICE DETAILS
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <Divider></Divider>

          <Grid container spacing={2} className="details-div p-15">
            <Grid item xs={12} md={7}>
              <Box className="w-100" mb={2}>
                <Typography component="p">#INVOICE23009</Typography>
              </Box>
              <Box className="w-100 flex-between id-p" mb={2}>
                <Box>
                  <Typography component="p">Name:Sri Srinivas</Typography>
                </Box>
                <Box>
                  <Typography component="p">Contact No:9638527410</Typography>
                </Box>
                <Box>
                  <Typography component="p">Email:Prasad@gmail.com</Typography>
                </Box>
              </Box>
              <MemberTable
                columns={ItemColumns}
                data={itemData}
                options={pcOptions}
              />
            </Grid>
            {pay ? (
              <Grid item xs={12} md={5} className="payment-container">
                <Box>
                  <Typography component="h5">Payment Details</Typography>
                </Box>
                <Grid container spacing={2} mt={2} className="p-15">
                  <Grid Item xs={12} md={6}>
                    <Box className="information-div">
                      <Box component="img" src={customer} />
                      <Box>
                        <Typography component="label">Payment Type</Typography>
                        <Typography component="h5">UPI</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid Item xs={12} md={6}>
                    <Box className="information-div">
                      <Box component="img" src={customer} />
                      <Box>
                        <Typography component="label">
                          Transaction ID
                        </Typography>
                        <Typography component="h5">278365367828</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid Item xs={12} md={6}>
                    <Box className="information-div">
                      <Box component="img" src={customer} />
                      <Box>
                        <Typography component="label">
                          Transaction Date & Time
                        </Typography>
                        <Typography component="h5">
                          23-03-23 | 12:30 PM
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid Item xs={12} md={6}>
                    <Box className="information-div">
                      <Box component="img" src={customer} />
                      <Box>
                        <Typography component="label">
                          Transaction Status
                        </Typography>
                        <Typography component="h5">Completed</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box className="text-center" mt={3}>
                  <Button
                    variant="outlined"
                    color="error"
                    mt={2}
                    className="retail-btn"
                    onClick={changePay}
                  >
                    Pay Now
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} md={5} className="payment-container p-15">
                <Box>
                  <Typography component="h5">Payment Type</Typography>
                </Box>
                <Box className="flex">
                  <Box src={cash} component="img" mr={1} />
                  <Typography component="h5">Cash</Typography>
                </Box>
                <Box className="flex">
                  <Box src={cod} component="img" mr={1} />
                  <Typography component="h5">COD</Typography>
                </Box>
                <Box className="flex" onClick={handleClick}>
                  <Box src={cash} component="img" mr={1} />
                  <Typography component="h5">Credit</Typography>
                </Box>
                {showState && (
                  <Box className="flex">
                    <Grid container>
                      <Grid item md={6}>
                        <Box className="">
                          <Typography component="p">Credit Limit</Typography>
                          <Typography component="h5">₹ 50,000</Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box className="">
                          <Typography component="p">
                            Available Credit Limit
                          </Typography>
                          <Typography component="h5">₹ 50,000</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Box className="flex" onClick={payclick}>
                  <Box src={credit} component="img" mr={1} />
                  <Typography component="h5">Online</Typography>
                </Box>

                {showState && (
                  <Box className="flex">
                    <Grid container>
                      <Grid item md={6}>
                        <Box className="flex">
                          <Box src={upi} component="img" mr={1} />
                          <Typography component="h5">UPI</Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box className="flex">
                          <Box src={upi} component="img" mr={1} />
                          <Typography component="h5">
                            Credit / Debit Card
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box className="flex">
                          <Box src={upi} component="img" mr={1} />
                          <Typography component="h5">Net Banking</Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <Box className="flex">
                          <Box src={upi} component="img" mr={1} />
                          <Typography component="h5">UPI</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {/* <Box className="flex">
                  <Box src={upi} component="img" mr={1} />
                  <Typography component="h5">UPI</Typography>
                </Box> */}
                {/* <Box className="flex">
                  <Box src={wallet} component="img" mr={1} />
                  <Typography component="h5">
                    Add To Supplier Account
                  </Typography>
                </Box> */}
                <Box mt={5} className="text-center">
                  <Button
                    variant="outlined"
                    color="error"
                    mt={2}
                    className="retail-btn"
                    onClick={changePay}
                  >
                    Pay Now
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
});

export default InvoiceDetailsModal;

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import CountrySelect from './CountrySelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddCustomerModal = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    log() {
      handleOpen();
    }
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style} className='view-more-content addCustomerModal'>
          <Box className="modal-header p-15" >
            <Typography id="modal-modal-title" variant="h5" component="h5">
              ADD NEW CUSTOMER
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Box className="w-100">
            <Grid container spacing={3} mt={1}>

              <Grid item xs={2} sm={4} md={4} >
                <FormControl >
                  <Typography component="label">Company Name</Typography>
                  <OutlinedInput />

                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
                <FormControl>
                  <Typography component="label">Email</Typography>
                  <OutlinedInput />

                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <FormControl  >
                  <Typography component="label">Phone No</Typography>
                  <OutlinedInput />

                </FormControl>
              </Grid>
            
              <Grid item md={4}>
                <FormControl className='country-select'>
                  <Typography component="label">Country</Typography>
                  <CountrySelect />

                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <FormControl >
                  <Typography component="label">City</Typography>
                  <OutlinedInput />

                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <FormControl  >
                  <Typography component="label">Approved Limit</Typography>
                  <OutlinedInput />

                </FormControl>
              </Grid>
            
              <Grid item xs={2} sm={4} md={4}>
                <FormControl >
                  <Typography component="label">Password</Typography>
                  <OutlinedInput />

                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <FormControl className='input-field'>
                  <Typography component="label">Confirm Password</Typography>
                  <OutlinedInput />

                </FormControl>
              </Grid>


            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12} md={12}>
                <FormControl style={{ width: '94%' }}>
                  <Typography component="label">Facility Name</Typography>
                  <OutlinedInput />

                </FormControl>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={12} md={4} ml={2} mb={2}>
                <FormControl className='w-100' >
                  <Typography component="label">Facility Address</Typography>
                  <TextareaAutosize mt={3} minRows={5} placeholder='Please Enter Facility Address' />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Divider></Divider>
          <Grid container>
            <Box className="w-100">
              <Button variant='outlined' color='error' className='save-btn mr-20'>Save</Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
});

export default AddCustomerModal;
import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CardMedia from "@mui/material/CardMedia";
import customers from "../images/customers.svg";
import mapmarker from "../images/map-marker.png";
import logout from "../images/logout.svg";
import p2 from "../images/p2.png";
import Swal from "sweetalert2";
import customer from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import mail from "../images/mail.svg";
import withReactContent from "sweetalert2-react-content";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";
import MemberTable from "../Customer/MemberTable";
import addPerson from "../images/person-plus-fill.svg";
import door_delivery from "../images/door-delivery.svg";
import logo from "../images/logo-white.png";
import curb from "../images/curb.svg";
import store from "../images/store.svg";
import Tooltip from "@mui/material/Tooltip";
import info from "../images/info-circle.svg";
import clear from "../images/clear.svg";
import discount from "../images/Discount.svg";
import invoice from "../images/Invoice.svg";
import upscan from "../images/upc-scan.svg";
import walmart from "../images/Walmart.jpg";
import print from "../images/print-invoice.svg";
import edit from "../images/edit.svg";
import cross1 from "../images/cross.svg";
import ClearIcon from "../images/clear-cross.svg";
import distributorimg1 from "../images/distributor1.png";
import distributorimg2 from "../images/distributor2.png";
import distributorimg3 from "../images/distributor3.png";
import pr1 from "../images/p1.png";
import pr2 from "../images/p2.png";
import pr3 from "../images/p3.png";
import pr4 from "../images/p4.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Bazaar() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = [
    "S.NO",
    "SKU ID",
    "ITEM NAME & DETAILS",
    "QTY",
    "PRICE",
    "TOTAL",
    "",
  ];

  const customerColumns = ["Customer ID", "Customer Name", "Email", "Phone"];

  const customerData = [
    ["#258974", "Akhil", "akhil.@gmail.com", "9478982317"],
    ["#258974", "Suresh", "suresh.@gmail.com", "8327641236"],
  ];

  const billColumns = [
    "S.NO",
    "Invoice Id",
    "Date/Time",
    "Bill Amount",
    "Payment Mode",
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];

  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns = ["ORDER ID", "CITY", "DATE", "CUSTOMER", "STATUS"];

  const orderData = [
    [
      "#258974",
      "Visakhapatnam",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "Completed",
    ],
    [
      "#258974",
      "Rajahmundry",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "In Progress",
    ],
  ];

  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid item xs="12">
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  <Box>
                    <Typography component="h2">POS {pos}</Typography>
                  </Box>
                  <Box className="icons">
                    <Box
                      component="img"
                      src={customers}
                      onClick={() => ref.current.log()}
                    />

                    <Typography component="h5" ml={1}>
                      Ramesh Kumar
                    </Typography>
                    <Box component="img" src={logout} ml={2} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box padding={2}>
              <Grid container>
                <Grid item md={12}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    className="procurement-tabs"
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Product" {...a11yProps(1)} />
                      <Tab label="Distributor" {...a11yProps(0)} />
                    </Tabs>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={2}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item md={12}>
                          <Typography className="m-heading">Filters</Typography>
                        </Grid>
                      </Grid>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Card>
                            <CardContent>
                              <Box display={"flex"}>
                                <Box
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Groceries</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                                <Box
                                  ml={1}
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Masala</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                              </Box>

                              <Box display={"flex"} mt={1}>
                                <Box
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">
                                    Chat Masala
                                  </Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                                <Box
                                  ml={1}
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Chunky</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Typography className="t-heading">Fruits</Typography>
                        </Grid>
                        <Grid item md={12} mt={1}>
                          <Typography className="t-heading">
                            Vegetables
                          </Typography>
                        </Grid>
                        <Grid item md={12} mt={1}>
                          <Typography className="t-heading">
                            Cleaning
                          </Typography>
                        </Grid>
                        <Grid item md={12} mt={1} mb={1}>
                          <Typography className="t-heading">
                            Cold Drinks
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Typography className="t-heading">Brands</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={10}>
                  <TabPanel
                    value={value}
                    index={1}
                    className="custom-shift-tab"
                  >
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Sri Srinivasa Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                    href="/procurement/DistributorDetails"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Venkateshwara Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg3}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Karachiwala
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container mt={1} spacing={2}>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Sri Srinivasa Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Venkateshwara Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg3}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Karachiwala
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container mt={1} spacing={2}>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Sri Srinivasa Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Venkateshwara Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={4}>
                        <Box className="distributor-box">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg3}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading">
                                Karachiwala
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business. He started small
                                  to sell household provisions in 1952.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Appliances
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Bakeware
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Household
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Fruits
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Vegetables
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Electronics
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Snacks
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={3}>
                                  <Box className="tag-badge">
                                    <Typography className="tags">
                                      Cakes
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box
                              display={"flex"}
                              justifyContent={"space-around"}
                            >
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span" textAlign={"center"}>
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span" textAlign={"center"}>
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span" textAlign={"center"}>
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>

            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}

import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import Calender from "../Calender/Calender";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { OutlinedInput, FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Retailers/ExpandableRowTable";
import email from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import OrderModal from "./OrderModal";
import AddNewEntryModal from "./AddNewEntryModal";

const drawerWidth = 240;

export default function ExpenseReport() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const memeberColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Vender Name",
    "Status",
    "Amount",
  ];

  const erData = [
    ["09-03-2023", "Sri Sai Kirana and General Stores", "Pending", "₹ 3201.00"],
    [
      "09-03-2023",
      "Suryanarayana Departmental Stores",
      "In Progress",
      "₹ 7589.00",
    ],
  ];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const pcColumns = [
    { name: "SKU ID", options: { filterOptions: { fullWidth: true } } },
    "SKU NAME",
    "QTY",
    "PRICE",
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Expense Type:</b> Research and Development
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Expense Name:</b> Research and Development
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  const data = [
    [
      "12487",
      "Sri Sai Kirana and General Stores",
      "₹ 25.00",
      "Completed",
      "₹ 24000.00",
    ],
    [
      "12456",
      "Suryanarayana Departmental Stores",
      "₹ 25.00",
      "Completed",
      "₹ 36000.00",
    ],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Breadcrumbs aria-label="breadcrumb">
                      {/* <Link underline="hover" color="inherit" href="/">
                                                Home
                                            </Link> */}
                      <Link underline="hover" color="inherit" href="#">
                        Accounting
                      </Link>
                      <Typography color="text.primary">
                        Expense Report
                      </Typography>
                    </Breadcrumbs>
                    <Box className="icons bredcum-icons">
                      <SearchBar className="search-bar" />
                      <PersonIcon onClick={() => ref.current.log()} />

                      <NotificationsIcon />
                      <LogoutIcon onClick={logoutDashboard} ml={2} />
                    </Box>
                  </Grid>
                  <Divider className="bredcum-hr"></Divider>
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex">
                        <Calender className="calenders" />
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn active"
                        >
                          Today
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          This Month
                        </Button>
                      </Box>
                      <Box className="flex">
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Search
                        </Button>

                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">Download</MenuItem>
                          <MenuItem value="visakhapatnam">PDF</MenuItem>
                          <MenuItem value="vijayawada">Excel</MenuItem>

                          <MenuItem value="kurnool">Image</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="member-table">
                          <ExpandableRowTable
                            columns={memeberColumns}
                            options={options}
                            data={erData}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            mb={2}
                          >
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Date:
                                  </Typography>
                                  <Typography>02-03-2023</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Journal ID
                                  </Typography>
                                  <Typography>AB865983</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                className="retail-btn"
                                onClick={() => ref2.current.log()}
                              >
                                Add New Entry
                              </Button>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Expense Category
                                  </Typography>
                                  <Typography>
                                    General and Administrative
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Expense Type
                                  </Typography>
                                  <Typography>Vehicle Insurance</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Expense Description
                                  </Typography>
                                  <Typography>Vehicle Insurance</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            mt={2}
                            mb={2}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Vendor
                                  </Typography>
                                  <Typography>
                                    Srinivasa Kirana Store
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography>+91 90528 45869</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2} mb={2}>
                              <Box className="information-div">
                                <Box component="img" src={email} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>
                                    lokesh.bezavada@gmail.com
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2} mb={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Address
                                  </Typography>
                                  <Typography>
                                    203, HiTech city Hyderabad
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Divider></Divider>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Vendor GST
                                  </Typography>
                                  <Typography>AHSJ649816289</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Vendor PAN
                                  </Typography>
                                  <Typography>AHSJ62148969</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={email} />
                                <Box>
                                  <Typography component="label">
                                    Vendor Bank Details
                                  </Typography>
                                  <Typography>HDFC</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Payment Instructions
                                  </Typography>
                                  <Typography>629846912864986</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Divider></Divider>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            mb={2}
                            pt={2}
                          >
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Total Amount:
                                  </Typography>
                                  <Typography>₹ 10000.00</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">TDS</Typography>
                                  <Typography>₹ 200.00</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">GST</Typography>
                                  <Typography>₹ 1000.00</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Payment Detail
                                  </Typography>
                                  <Typography>1100294788964</Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Payment Mode
                                  </Typography>
                                  <Typography>Check</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    GST Input
                                  </Typography>
                                  <Typography>NA</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <OrderModal ref={ref3} />
        <AddNewEntryModal ref={ref2} />
      </Box>
    </>
  );
}

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput,Select,MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import MUIRichTextEditor from 'mui-rte';
import status from '../images/customers.svg';
import rupee from '../images/rupee.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const myTheme = createTheme({
  // Set up your custom MUI theme here
})

const MessageModal = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    log() {
      handleOpen();
    }
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style} className='view-more-content addCustomerModal message-modal'>
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
            CREATE MESSAGE
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Grid  container spacing={2} mt={2}>

            <Grid item xs={12} md={6} >
              <FormControl className='form_control'>
                <Typography component="label">Channel</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select float-right"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Select Channel</MenuItem>
                  <MenuItem value="visakhapatnam">SMS</MenuItem>
                  <MenuItem value="vijayawada">Whats App</MenuItem>

                  <MenuItem value="kurnool">Email</MenuItem>


                </Select>

              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} >
              <Box className="information-div">
                <Box component="img" src={status} />
                <Box>
                  <Typography component="label">Total Selected Customers</Typography>
                  <Typography>6789</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} >
              <FormControl className='form_control'>
                <Typography component="label">Notification Date</Typography>
                <OutlinedInput placeholder='from Date' />

              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="information-div">
                <Box component="img" src={rupee} />
                <Box>
                  <Typography component="label">Cost of each message</Typography>
                  <Typography>Rs. 5 paisa</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} >
              <FormControl className='form_control'>
                <Typography component="label">Notification Time</Typography>
                <OutlinedInput placeholder='time' />

              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} >
              <Box className="information-div">
                <Box component="img" src={rupee} />
                <Box>
                  <Typography component="label">Total Cost</Typography>
                  <Typography>Rs. 5 paisa</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>



          <Grid container xs={12} mb={5} className='p-15'>
            <ThemeProvider theme={myTheme}>
              <MUIRichTextEditor label="Start typing..." />
            </ThemeProvider>
          </Grid>
          <Divider></Divider>
          <Box mt={1} mb={2} className="p-15">
          <Button variant='outlined' color='error'   className='retail-btn float-right'>Submit</Button>
          <Button variant='outlined' color='error'  className='retail-btn mr-2 float-right'>Cancel</Button>
  
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default MessageModal;
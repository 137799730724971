import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import reddot from "../images/reddot.png";
import Calender from "../Calender/Calender";
import { Image, InputOutlined, Label, Phone } from "@mui/icons-material";
import AddCustomerModal from "../Customer/AddCustomerModal";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import MemberTable from "../Customer/MemberTable";
import EditModal from "../Customer/EditModal";
import ViewMoreModal from "../Customer/ViewMoreModal";
import home from "../images/home.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Bredcrum from "../Bredcrum";
import graph from "../images/graph.png";
import lord from "../images/metal-ganesh.png";
import DatePicker from "react-datepicker";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Promotions() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = React.useState(true);

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  const jobColumns = [
    { name: "ApplicationId", options: { filterOptions: { fullWidth: true } } },
    "Applicant Name",
    "Phone",
    "Email",
  ];

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const [valuep, setValueP] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeP = (event, newValue) => {
    setValueP(newValue);
  };
  const pcColumns = [
    { name: "Promo Code", options: { filterOptions: { fullWidth: true } } },
    "Applies To",
    "Type",
    "From Date",
    "To Date",
  ];
  const selectColumns = [
    { name: "Name", options: { filterOptions: { fullWidth: true } } },
    "Contact No",
    "Location",
  ];
  const selectData = [
    ["patnaik", "9513574862", "Kakinada"],
    ["Neeha", "7084327025", "Vizianagram"],
    ["naveen", "8624791305", "Visakhapatnam"],
  ];

  const pcData = [
    ["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["New1", "Food", "Discount", "11 Feb 2021", "19 Feb 2021"],
  ];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const jobOptions = {
    search: searchBtn,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box className="filters sales-filters">
                      <Box className="flex promotions-range-picker">
                        <Calender />

                        <Button variant="outlined" className="today active">
                          Today
                        </Button>
                        <Button variant="outlined" className="today">
                          Yesterday
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item xs={12} md={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={
                          <svg
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="ArrowDropUpIcon"
                          >
                            <path d="m7 16 4-7 4 7z"></path>
                          </svg>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="accordian-header acc-promotion-header"
                      >
                        <Typography>Office</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography component="h4" className="font-bolder">
                          Promo Offers
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item md={4}>
                            <Box mt={1} class="flex-column color-btns">
                              <Button className="text-pink" variant="contained">
                                Summer Special - 40
                              </Button>
                              <Button
                                className="text-green"
                                variant="contained"
                              >
                                Diwali - 25
                              </Button>
                              <Button className="text-blue" variant="contained">
                                Summer Special - 40
                              </Button>
                              <Button className="text-sand" variant="contained">
                                Summer Special - 40
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Box>
                              <Typography>Summer Special</Typography>
                              <Box>
                                <Typography component="label">
                                  Total Sent
                                </Typography>
                                <Typography component="span">40</Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Total Redeemed
                                </Typography>
                                <Typography component="span">28</Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Expiry Date
                                </Typography>
                                <Typography component="span">20%</Typography>
                              </Box>
                              <Box>
                                <Typography component="label">Type</Typography>
                                <Typography component="span">
                                  Merchant
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={6}>
                            <Box
                              src={graph}
                              component="img"
                              className="w-100"
                            />
                          </Grid>
                        </Grid>
                        {/* <MemberTable data={driverData} columns={ShiftColumns} options={shiftOptions} /> */}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">PROMO CODE</Typography>

                          <Box>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              className="custom-tab tax-tabs"
                            >
                              <Tab label="NEW" {...a11yProps(0)} />
                              <Tab label="CURRENT" {...a11yProps(1)} />
                              <Tab label="EXPIRY" {...a11yProps(2)} />
                            </Tabs>
                          </Box>
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            className="retail-btn"
                          >
                            Download
                          </Button>

                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            className="retail-btn"
                            href="/Promotions/PromotionsCreate"
                          >
                            Create Promo
                          </Button>
                        </Box>

                        <TabPanel
                          value={value}
                          index={0}
                          className="scroll-auto"
                        >
                          <Box mt={2} mb={2}>
                            <MemberTable
                              columns={pcColumns}
                              options={pcOptions}
                              data={pcData}
                            />
                          </Box>
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={1}
                          className="scroll-auto"
                        >
                          <Box mt={2} mb={2}>
                            <MemberTable
                              columns={pcColumns}
                              options={pcOptions}
                              data={pcData}
                            />

                            {/* <MemberTable columns={ShiftColumns} options={pricingOptions} /> */}
                          </Box>
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={2}
                          className="scroll-auto"
                        >
                          <Box mt={2} mb={2}>
                            <MemberTable
                              columns={pcColumns}
                              options={pcOptions}
                              data={pcData}
                            />

                            {/* <MemberTable columns={ShiftColumns} options={pricingOptions} /> */}
                          </Box>
                        </TabPanel>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information applicant_information">
                        <Tabs
                          value={valuep}
                          onChange={handleChangeP}
                          aria-label="basic tabs example"
                          className="custom-tab promo-tabs promotion-tabs"
                        >
                          <Tab label="Description" {...a11yProps(0)} />

                          {/* <Tab label="Analytics" {...a11yProps(1)} /> */}
                        </Tabs>
                        <Box className="flex-between" mb={2}>
                          <Box>
                            <Typography component="p">PROMO CODE</Typography>
                            <Typography component="label">
                              Summer Special
                            </Typography>
                          </Box>
                          <Box>
                            <Typography component="p">PROMO SENT</Typography>
                            <Typography component="label">
                              Summer Special
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              variant="outlined"
                              color="error"
                              className="retail-btn"
                              href="/Promotions/PromotionsEdit"
                            >
                              Edit
                            </Button>
                          </Box>
                        </Box>
                        <Divider></Divider>
                        <TabPanel
                          value={valuep}
                          index={0}
                          className="scroll-auto"
                          mt={2}
                        >
                          <Box mt={2} mb={2}>
                            <Box className="flex-between">
                              <Box>
                                <Typography component="p">
                                  Discount Applies to
                                </Typography>
                                <Typography component="label">
                                  Grocires
                                </Typography>
                              </Box>
                              <Box>
                                <Typography component="p">
                                  Discount Type
                                </Typography>
                                <Typography component="label">10 %</Typography>
                              </Box>
                              <Box>
                                <Typography component="p">
                                  Promo Used
                                </Typography>
                                <Typography component="label">
                                  450 Customers
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between" mt={2}>
                              <Box
                                src={lord}
                                component="img"
                                className="w-80"
                              />
                              <Typography component="label" className="w-50">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s.
                              </Typography>
                            </Box>
                            <Box>
                              <Typography component="h5" className="font-bold">
                                VALIDITY
                              </Typography>
                            </Box>
                            <Box mt={2} mb={2}>
                              <Box className="flex">
                                <Box>
                                  <Typography component="p">
                                    From Date
                                  </Typography>
                                  <Typography component="label">
                                    10 Jan 2020
                                  </Typography>
                                </Box>
                                <Box ml={4}>
                                  <Typography component="p">To Date</Typography>
                                  <Typography component="label">
                                    25 Jan 2020
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            {/* <Box>
                              <Typography component="h5" className="font-bold">
                                CONDITIONS
                              </Typography>
                            </Box>
                            <Box className="flex-between" mt={2}>
                              <Box>
                                <Typography component="p">
                                  Min Bill Value
                                </Typography>
                                <Typography component="label">$ 250</Typography>
                              </Box>
                              <Box>
                                <Typography component="p">
                                  Applies Base Charge
                                </Typography>
                                <Typography component="label">$ 25</Typography>
                              </Box>
                              <Box>
                                <Typography component="p">
                                  Applies GST Charge
                                </Typography>
                                <Typography component="label">$ 0.5</Typography>
                              </Box>
                            </Box>
                            <Box mt={2}>
                              <Typography component="h5" className="font-bold">
                                FEES
                              </Typography>
                            </Box>
                            <Box className="flex" mt={2}>
                              <Box>
                                <Typography component="p">
                                  Delivery Fee
                                </Typography>
                                <Typography component="label">
                                  $ 1420
                                </Typography>
                              </Box>
                              <Box ml={4}>
                                <Typography component="p">
                                  Convenience Fee
                                </Typography>
                                <Typography component="label">
                                  $ 1420
                                </Typography>
                              </Box>
                              <Box ml={5}>
                                <Typography component="p">
                                  Item Total
                                </Typography>
                                <Typography component="label">
                                  $ 1420
                                </Typography>
                              </Box>
                            </Box>
                            <Box mt={2} mb={2}>
                              <Box className="flex">
                                <Box>
                                  <Typography component="p">
                                    Total Amount
                                  </Typography>
                                  <Typography component="label">
                                    $ 1420
                                  </Typography>
                                </Box>
                                <Box ml={4}>
                                  <Typography component="p">
                                    Applies on Total Amount
                                  </Typography>
                                  <Typography component="label">
                                    $ 1420
                                  </Typography>
                                </Box>
                              </Box>
                            </Box> */}
                            <Divider></Divider>
                            <Box mt={2} mb={2}>
                              <Box>
                                <Typography
                                  component="h5"
                                  className="font-bold"
                                >
                                  SELECTED CUSTOMERS
                                </Typography>
                              </Box>

                              <Box className="member-table w-100" mt={2}>
                                <MemberTable
                                  columns={selectColumns}
                                  options={pcOptions}
                                  data={selectData}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </TabPanel>
                        {/* <TabPanel
                          value={valuep}
                          index={1}
                          className="scroll-auto"
                        >
                          <Box mt={2} mb={2}>
                            <Box mt={2} mb={2}>
                              <Box display="flex" mb={2}>
                                <Box>
                                  <Typography component="p">
                                    From Date
                                  </Typography>
                                  <OutlinedInput
                                    type="date"
                                    className="outline-none"
                                  />
                                </Box>
                                <Box ml={3}>
                                  <Typography component="p">To Date</Typography>
                                  <OutlinedInput
                                    type="date"
                                    className="outline-none"
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <Typography component="p" className="">
                                  No of Customers Applied
                                </Typography>
                                <Typography component="label" className="">
                                  200
                                </Typography>
                              </Box>

                              <Box
                                src={graph}
                                component="img"
                                className="w-100"
                              />
                            </Box>
                          </Box>
                        </TabPanel> */}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddCustomerModal ref={ref} />
        <EditModal ref={ref1} />
        <ViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import dairy from '../images/Dairy.png';
import skincare from '../images/SkinCare.png';
import babycare from '../images/BabyCare.png';
import beverages from '../images/Beverages.png';
import {Card,Typography,Box} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default function Slider(props) {

    return (
        <Carousel
            swipeable={false}
            draggable={false}
            // showDots={true}
            responsive={responsive}
            
            ssr={true} // means to render carousel on server-side.
            //   infinite={true}
            //   autoPlay={props.deviceType !== "mobile" ? true : false}
            //   autoPlaySpeed={1000}
            keyBoardControl={true}
            // customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            //   deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <div className="custom-slider">
                <Card sx={{ maxWidth: 110 }}>
                <CardContent>
                        <Box src={dairy} component="img" className="slide-img" />
                        <Typography gutterBottom variant="small" component="small">
                            Dairy
                        </Typography>

                    </CardContent>

                </Card>
            </div>
            <div className="custom-slider">
            <Card sx={{ maxWidth: 110 }}>
                    
                    <CardContent>
                        <Box src={skincare} component="img" className="slide-img" />
                        <Typography gutterBottom variant="small" component="small">
                        Skin Care
                        </Typography>

                    </CardContent>

                </Card>
            </div>
            <div className="custom-slider">
            <Card sx={{ maxWidth: 110 }}>
            <CardContent>
                        <Box src={babycare} component="img" className="slide-img" />
                        <Typography gutterBottom variant="small" component="small">
                            Baby Care
                        </Typography>

                    </CardContent>

                </Card>
            </div>
            <div className="custom-slider">
            <Card sx={{ maxWidth: 110 }}>
            <CardContent>
                        <Box src={beverages} component="img" className="slide-img" />
                        <Typography gutterBottom variant="small" component="small">
                        Beverages
                        </Typography>

                    </CardContent>

                </Card>
            </div>
            <div className="custom-slider">
            <Card sx={{ maxWidth: 110 }}>
            <CardContent>
                        <Box src={dairy} component="img" className="slide-img" />
                        <Typography gutterBottom variant="small" component="small">
                            Dairy
                        </Typography>

                    </CardContent>

                </Card>
            </div>
            <div className="custom-slider">
            <Card sx={{ maxWidth: 110 }}>
            <CardContent>
                        <Box src={skincare} component="img" className="slide-img" />
                        <Typography gutterBottom variant="small" component="small">
                            Skin Care
                        </Typography>

                    </CardContent>

                </Card>
            </div>
            <div className="custom-slider">
            <Card sx={{ maxWidth: 110 }}>
            <CardContent>
                        <Box src={babycare} component="img" className="slide-img" />
                        <Typography gutterBottom variant="small" component="small">
                            Baby Care
                        </Typography>

                    </CardContent>

                </Card>
            </div>
        </Carousel>
    )
}
import React, { useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import rupee from "../images/rupee.svg";
import mail from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import test from "../images/default.jpg";
import custicon from "../images/cust-icon.svg";
import upload from "../images/upload.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FleetAddModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const viColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Inspection",
    "Status",
    "Comments",
  ];

  const viOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content">
          <Box className="modal-header p-15">
            <Typography
              id="modal-modal-title"
              variant="h5"
              className="m-heading"
              component="h5"
            >
              Add Vehicle Information
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Grid container spacing={2} mt={1} mb={2}>
            <Grid item md={4}>
              <Box component="img" src={upload} mt={2} />
              <Box mt={2}>
                <Button
                  variant="contained"
                  component="label"
                  className="btn-upload btn-primary-outline"
                >
                  Upload image
                  <input hidden accept="image/*" multiple type="file" />
                </Button>
              </Box>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Vehicle ID</Typography>
                <OutlinedInput placeholder="ID" />
              </FormControl>
              <FormControl className="form_control mt-3" fullWidth>
                <Typography component="label">Vehicle Model</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Select Model</MenuItem>
                  <MenuItem value="visakhapatnam">Transit Connect</MenuItem>
                  <MenuItem value="vijayawada">Sprinter</MenuItem>

                  <MenuItem value="kurnool">Transit T-350</MenuItem>
                </Select>
              </FormControl>
              <FormControl className="form_control mt-3" fullWidth>
                <Typography component="label">Vehicle Number</Typography>
                <OutlinedInput placeholder="Number" />
              </FormControl>
              <FormControl className="form_control mt-3" fullWidth>
                <Typography component="label">Vehicle Colour</Typography>
                <OutlinedInput placeholder="Colour" />
              </FormControl>
            </Grid>
            <Grid item md={4} paddingRight={2}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">City</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">All Locations</MenuItem>
                  <MenuItem value="visakhapatnam">Debary</MenuItem>
                </Select>
              </FormControl>
              <FormControl className="form_control  mt-3" fullWidth>
                <Typography component="label">Vehicle Make</Typography>
                <OutlinedInput placeholder="Make" />
              </FormControl>

              <FormControl className="form_control mt-3" fullWidth>
                <Typography component="label">Vehicle Year</Typography>
                <OutlinedInput placeholder="Year" />
              </FormControl>
              <FormControl className="form_control mt-3" fullWidth>
                <Typography component="label">Vehicle Name</Typography>
                <OutlinedInput placeholder="Name" />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={2}
              className="save-btn"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-user-modal" sx={style}>
          <Box className="add-user-div p-15">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New user
            </Typography>
            <CloseIcon onClick={handleCloseAdd} />
          </Box>
          <Divider></Divider>
          <Grid
            className="inputfield-container"
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
          >
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">First Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Phone No</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Email</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Password</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Button
            variant="outlined"
            color="error"
            mt={2}
            mb={2}
            className="save-btn p-15"
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

export default FleetAddModal;

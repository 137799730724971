import React, { useImperativeHandle, forwardRef } from "react";
import { Box, Toolbar, Button, Typography, Grid, Card, CardContent, FormControl, CardActions, Breadcrumbs, Link, Divider, Select, MenuItem, OutlinedInput } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import reddot from '../images/reddot.png';
import Calender from "../Calender/Calender";
import { Image, InputOutlined, Label, Phone } from "@mui/icons-material";
import AddCustomerModal from "../Customer/AddCustomerModal";
import id from '../images/ID.svg';
import customers from '../images/customers.svg';
import mobile from "../images/mobile.svg";
import location from '../images/location.svg';
import status from '../images/order.svg';
import active from '../images/last-active.svg';
import MemberTable from "../Customer/MemberTable";
import EditModal from "../Customer/EditModal";
import ViewMoreModal from "../Customer/ViewMoreModal";
import home from '../images/home.svg';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Bredcrum from "../Bredcrum";
import graph from '../images/graph.png';
import lord from '../images/metal-ganesh.png';
import DatePicker from "react-datepicker";
import MessageModal from "./MessageModal";

const drawerWidth = 240;

const year = [
    '2023',
    '2022',
    '2021',
    '2020',
];

const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export default function Messaging() {

    const [showState, setShowState] = React.useState(false);
    const buttonClickedHandler = () => {
        setShowState((showState) => showState = !showState)
    }

    const [alignment, setAlignment] = React.useState('left');
    const [searchBtn, setSearchBtn] = React.useState(true);

    const ref = React.useRef();
    const ref1 = React.useRef();
    const ref3 = React.useRef();

    const jobColumns = [
        { name: "ApplicationId", options: { filterOptions: { fullWidth: true } } },
        "Applicant Name",
        "Phone",
        "Email"
    ];

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);
    const [valuep, setValueP] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeP = (event, newValue) => {
        setValueP(newValue);
    };
    const pcColumns = [
        { name: "Promo Code", options: { filterOptions: { fullWidth: true } } },
        "Applies To",
        "Type",
        "From Date",
        "To Date"
    ];
    const customerColumns = [
        "Customer Id",
        "Customer Name",
        "Phone",
        "Email"
    ];
    const customerData = [["#147258", "Oliver", "+1 (305) 555-5672","Oliver@gmail.com"],
    ["	#258369", "Chris Gardner", "+1 (305) 555-9831","Chris Gardner@gmail.com"],
    ["#369147", "Kiren Swez", "+1 (305) 555-6523","Kiren Swez@gmail.com"]];
    const teamColumns = [
        "Employee Id",
        "Employee Name",
        "Phone",
        "Email",
        "Role"
    ];
    const teamData = [["#147258", "Oliver", "+1 (305) 555-5672","Oliver@gmail.com","Manager"],
    ["	#258369", "Chris Gardner", "+1 (305) 555-9831","Chris Gardner@gmail.com","City Manager"],
    ["#369147", "Kiren Swez", "+1 (305) 555-6523","Kiren Swez@gmail.com","Driver"]];
    const merchantColumns = [
        "Merchant Id",
        "Merchant Name",
        "Phone",
        "Email"
    ];
    const merchantData = [["#147258", "Oliver", "+1 (305) 555-5672","Oliver@gmail.com"],
    ["	#258369", "Chris Gardner", "+1 (305) 555-9831","Chris Gardner@gmail.com"],
    ["#369147", "Kiren Swez", "+1 (305) 555-6523","Kiren Swez@gmail.com"]];

    const pcData = [["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["old", "Grocires", "Percentage", "09 Mar 2021", "29 Mar 2021"],
    ["New1", "Food", "Discount", "11 Feb 2021", "19 Feb 2021"]];

    const pcOptions = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
        filterType: false,
        selectableRows: true,
        pagination: true,
        responsive: "scrollMaxHeight",
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    const jobOptions = {
        search: searchBtn,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <>

            <Box
                component="main"


            >
                <Grid container >
                    <Grid xs="12">
                        <Card xs="11" className="custom_card">
                            <Bredcrum />
                            <CardContent>
                                <Grid container className="full_content">


                                    <Grid xs="12" mt={1}>
                                        <Box className="filters sales-filters">
                                            <Box className="flex-between w-100">
                                                <Select
                                                    labelId="demo-isolation-label"
                                                    id="isolation-select"
                                                    className="select"
                                                    // onChange={handleSelectOxygen}
                                                    // input={<OutlinedInput />}
                                                    defaultValue="locations"
                                                >
                                                    <MenuItem value="locations">All Cities</MenuItem>
                                                    <MenuItem value="visakhapatnam">Fort Lauderdale</MenuItem>
                                                    <MenuItem value="vijayawada">Jacksonville</MenuItem>

                                                    <MenuItem value="kurnool">Miami</MenuItem>


                                                </Select>

                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="custom-tab messaging-tabs">
                                                    <Tab label="Customers" {...a11yProps(0)} />

                                                    <Tab label="Teams" {...a11yProps(1)} />
                                                </Tabs>

                                            </Box>

                                        </Box>
                                    </Grid>

                                </Grid>
                                <Grid container mt={4} className="pickers">
                                    <Grid xs="12" mt={2} mb={2}>
                                        <Box className="member-datepickers">
                                            <Box>
                                                <Box className="border-right">
                                                    <Typography>JOIN DATE</Typography>
                                                    <Box className="sub_date">

                                                        <FormControl>
                                                            <Typography component="label">From</Typography>
                                                            <OutlinedInput type="date" placeholder="Please enter text" />
                                                            {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}

                                                        </FormControl>
                                                        <FormControl>
                                                            <Typography component="label">To</Typography>
                                                            <OutlinedInput type="date" placeholder="Please enter text" />

                                                        </FormControl>

                                                    </Box>
                                                </Box>

                                            </Box>
                                            <Box className="border-right">
                                                <Typography>LAST ACTIVE</Typography>
                                                <Box className="sub_date">

                                                    <FormControl>
                                                        <Typography component="label">From</Typography>
                                                        <OutlinedInput type="date" />

                                                    </FormControl>
                                                    <FormControl>
                                                        <Typography component="label">To</Typography>
                                                        <OutlinedInput type="date" />

                                                    </FormControl>

                                                </Box>
                                            </Box>
                                            <Box className="border-right">
                                                <Typography>BILLINGS</Typography>
                                                <Box className="sub_date">

                                                    <FormControl>
                                                        <Typography component="label">Min</Typography>
                                                        <OutlinedInput placeholder="Spend Min" />

                                                    </FormControl>
                                                    <FormControl>
                                                        <Typography component="label">Max</Typography>
                                                        <OutlinedInput placeholder="Spend Max" />

                                                    </FormControl>

                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography>TRANSPORTS</Typography>
                                                <Box className="sub_date">

                                                    <FormControl>
                                                        <Typography component="label">Min</Typography>
                                                        <OutlinedInput placeholder="Orders Min" />

                                                    </FormControl>
                                                    <FormControl>
                                                        <Typography component="label">Max</Typography>
                                                        <OutlinedInput placeholder="Orders Max" />

                                                    </FormControl>

                                                </Box>
                                            </Box>

                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={12} md={12}>
                                    <TabPanel value={value} index={0} className="scroll-auto">
                                                        <Card className="customer_cards mb-20">
                                                            <CardContent>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Typography component='h6'>CUSTOMERS</Typography>



                                                                    <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => ref3.current.log()}>Create Message</Button>

                                                                </Box>
                                                                <Box mt={2} mb={2} className="w-100 member-table">

                                                                    <MemberTable columns={customerColumns} options={pcOptions} data={customerData} />
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                        <Card className="customer_cards">
                                                            <CardContent>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Typography component='h6'>MERCHANTS</Typography>



                                                                    <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => ref3.current.log()} >Create Message</Button>

                                                                </Box>
                                                                <Box mt={2} mb={2} className="w-100 member-table">

                                                                    <MemberTable columns={merchantColumns} options={pcOptions} data={merchantData} />
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                        
                                                        
                                                    </TabPanel>
                                                    <TabPanel value={value} index={1} className="scroll-auto">
                                                    <Card className="customer_cards">
                                                            <CardContent>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Typography component='h6'>TEAM</Typography>



                                                                    <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => ref3.current.log()} >Create Message</Button>

                                                                </Box>
                                                                <Box mt={2} mb={2} className="w-100 member-table">

                                                                    <MemberTable columns={teamColumns} options={pcOptions} data={teamData} />
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                        
                                                    </TabPanel>
                                    </Grid>

                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
                {/* <Toolbar /> */}
                
                <MessageModal ref={ref3} />
            </Box>
        </>
    )
}
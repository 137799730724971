import React, { useImperativeHandle, useRef, forwardRef } from "react";
import { Box,Switch, Toolbar, Button, Typography, Grid, Card, CardContent, CardActions, Breadcrumbs, Link, Divider, Select, MenuItem, SvgIcon, FormControl, OutlinedInput } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Tab from '@mui/material/Tab';
import status from '../images/last-active.svg';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import SearchBar from "material-ui-search-bar";
import EquipmentTable from "./EquipmentTable";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default function InspectionsSetup() {

    const [showState, setShowState] = React.useState(false);
    const buttonClickedHandler = () => {
        setShowState((showState) => showState = !showState)
    }
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [alignment, setAlignment] = React.useState('left');

    const ref = React.useRef();
    const ref1 = React.useRef();
    const ref3 = React.useRef();

    let navigate = useNavigate();
    const logoutDashboard = () => {
        navigate('/');
    }
    const ref4 = useRef();

    const pcColumns = [
        { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
        "Retailer Name",
        "Total PO Value",
        "Status",
        "Total PO"
    ];

    const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

    const pcOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    const memeberColumns = [
        "Batch Id",
        { name: "Vender Name", options: { filterOptions: { fullWidth: true } } },
        "PO Number",
        "PO Date",
        "Delivery Date",
        "Qty",
        "Sale Quantity",
        "Expiry Date"
    ];

    const options = {
        filter: true,
        onFilterChange: (changedColumn, filterList) => {
            console.log(changedColumn, filterList);
        },
        selectableRows: false,
        isRowSelectable: false,
        expandableRowsHeader: false,
        icons: { Add: () => 'Add Row' },
        filterType: "dropdown",
        responsive: "scrollMaxHeight",
        rowsPerPage: 10,
        // expandableRows: true,
      
        page: 1
    };

    const data = []
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <>

            <Box
                component="main"


            >
                <Grid container >
                    <Grid xs="12">
                        <Card xs="11" className="custom_card store-item-card">
                            <Grid container className="full_content bazaar-breadcrumb">
                                <Grid className="inner-bredcum" xs="12">
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/">
                                            Home
                                        </Link>
                                        <Link underline="hover" color="inherit" href="#">
                                            Product Catalogue
                                        </Link>
                                        <Typography color="text.primary">Add New Item</Typography>
                                    </Breadcrumbs>
                                    <Box className="icons bredcum-icons">
                                         <Box className="flex mr-20">
                                            <Typography component='label' className="mr-5">Auto Assign </Typography>
                                            <IOSSwitch />
                                         </Box>
                                        <SearchBar className="search-bar" />
                                        <PersonIcon onClick={() => ref.current.log()} />

                                        <NotificationsIcon />
                                        <LogoutIcon onClick={logoutDashboard} ml={2} />

                                    </Box>

                                </Grid>
                            </Grid>
                            <CardContent>
                                <Grid container mt={3} >
                                    <Grid item xs={12} md={12}>
                                        <Card className="customer_cards">
                                            <CardContent className="p-0">
                                                <Box mb={2} className="sm-selects bg-light p-15">
                                                    <Typography component="h4" className="sub_title">EQUIPMENT</Typography>
                                                    <Box className="flex">
                                                        <Typography component='label'>Equipment</Typography>
                                                        <Select
                                                            labelId="demo-isolation-label"
                                                            id="isolation-select"
                                                            className="select select-equipment"
                                                            // onChange={handleSelectOxygen}
                                                            // input={<OutlinedInput />}
                                                            defaultValue="Percentage"
                                                        >
                                                            <MenuItem value="Percentage">Percentage Discount</MenuItem>
                                                            <MenuItem value="Medicines">Promo Code</MenuItem>
                                                            <MenuItem value="Grocires">Gift Card</MenuItem>
                                                            <MenuItem value="Grocires">Coupon</MenuItem>
                                                        </Select>
                                                    </Box>
                                                </Box>
                                                <Grid container spacing={3} className="p-15">
                                                    <Grid item xs={2} sm={3} md={4} >
                                                        <Box className="information-div">
                                                            <FormControl className='w-100'>
                                                                <Typography component="label">Task Name</Typography>
                                                                <OutlinedInput value='' />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={2} sm={3} md={4}>
                                                        <Box className="information-div">
                                                            <FormControl className='w-100'>
                                                                <Typography component="label">Priority</Typography>
                                                                <OutlinedInput value='' />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={2} sm={3} md={4}>
                                                        <Box className="information-div">
                                                            <FormControl className='w-100'>
                                                                <Typography component="label">
                                                                    Alert</Typography>
                                                                <OutlinedInput value='' type='' />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={2} sm={3} md={4}>
                                                        <Box className="information-div">
                                                            <FormControl className='w-100'>
                                                                <Typography component="label">Alert Type</Typography>
                                                                <OutlinedInput value='' />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={2} sm={3} md={4}>
                                                        <Box className="information-div">
                                                            <FormControl className='w-100'>
                                                                <Typography component="label">Equipment Status</Typography>
                                                                <OutlinedInput value='' />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={2} sm={3} mt={3}>
                                                        <Button variant='outlined' color='error' className='retail-btn'>Add</Button>

                                                    </Grid>


                                                </Grid>
                                                <Box className="w-100 equipment-table" mt={2}>
                                                <EquipmentTable />
                                                </Box>
                                            </CardContent>

                                        </Card>
                                    </Grid>


                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
                {/* <Toolbar /> */}

            </Box>
        </>
    )
}
import React from "react";
import { Box, Button, Typography, Grid, Card, CardContent,Switch, Breadcrumbs, Link, Select, MenuItem } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';

import SearchBar from "material-ui-search-bar";

import MemberTable from "../Customer/MemberTable";
import AddNewModal from "./AddNewModal";
import EquipmentModal from "./EquipmentModal";
import AlertModal from "./AlertModal";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default function Equipment() {

    const [showState, setShowState] = React.useState(false);
    const buttonClickedHandler = () => {
        setShowState((showState) => showState = !showState)
    }
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [alignment, setAlignment] = React.useState('left');

    const ref = React.useRef();
    const ref4 = React.useRef();
    const ref3 = React.useRef();
    const ref2 = React.useRef();

    let navigate = useNavigate();
    const logoutDashboard = () => {
        navigate('/');
    }


    const pcColumns = [
        { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
        "Retailer Name",
        "Total PO Value",
        "Status",
        "Total PO"
    ];

    const eiColumns    = [
        "DATE",
        "INSPECTION",
        "STATUS"
    ];
    const eiData = [["11/12/2021", "Brake Lights", "Fail"],
    ["11/12/2021", "Emergency Flashers", "Pass"],
    ["11/12/2021", "Head Lights", "Not Applicable"]];
    const mlColumns    = [
        "DATE",
        "TYPE",
        "TASK",
        "LIFE OF REPAIR",
        "COST",
        "COMMENTS"
    ];
    const ulData = [["11/12/2021", "Hialeah", "09:00","19:00","12 Equipment","12 Equipment"]];
    const ulColumns    = [
        "DATE",
        "EMPLOYEE",
        "START TIME",
        "END TIME",
        "PRE TRIP CHECK",
        "POST TRIP CHECK"
    ];
    const mlData = [["11/12/2021", "Brakes", "Fail","HandBrake Fail","6 months","$ 120","Hand Brake Issue"]];
    const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

    const pcOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    const memeberColumns = [
        "EQUIPMENT ID",
        "EQUIPMENT LOCATION",
        "EQUIPMENT NAME",
        "EQUIPMENT REGISTRATION NUMBER",
        {options:{
            "label": "STATUS",
            customBodyRender: () => {
                return (
                    <IOSSwitch onClick={() => ref2.current.log()} />
                )
            },
        }
        }
       
    ];
    const equipmentoptions = {
        filter: true,
        onFilterChange: (changedColumn, filterList) => {
            console.log(changedColumn, filterList);
        },
        selectableRows: true,
        isRowSelectable: false,
        expandableRowsHeader: false,
        icons: { Add: () => 'Add Row' },
        filterType: "dropdown",
        responsive: "scrollMaxHeight",
        rowsPerPage: 10,
        expandableRows: false,

        page: 1
    };
    const options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        filterType: "dropdown",
        responsive: "scrollMaxHeight",
        rowsPerPage: 10,
        expandableRows: false,
        pagination:false
    };

    const data = [["Coffee", "Beverages", "Bru coffee", "21 Aug 2021", '86', "₹ 200.00"],
    ["Coffee", "Beverages", "Bru coffee", "21 Aug 2021", '86', "₹ 200.00"]]
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <>

            <Box
                component="main"


            >
                <Grid container >
                    <Grid xs="12">
                        <Card xs="11" className="custom_card">
                            <Grid container className="full_content bazaar-breadcrumb">
                                <Grid className="inner-bredcum" xs="12">
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/">
                                            Home
                                        </Link>
                                        <Link underline="hover" color="inherit" href="#">
                                            Service Catalogue
                                        </Link>
                                        <Typography color="text.primary">Equipment</Typography>
                                    </Breadcrumbs>
                                    <Box className="icons bredcum-icons">
                                        <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Suppliers</MenuItem>
                                            <MenuItem value="visakhapatnam">S.S.M Kirana</MenuItem>
                                            <MenuItem value="vijayawada">Sri Raghavendra Super Market</MenuItem>
                                            <MenuItem value="vijayawada">Suryanarayana Kirana and General</MenuItem>
                                            <MenuItem value="vijayawada">SKML Kirana and Genral</MenuItem>


                                        </Select>
                                        <SearchBar className="search-bar" />
                                        <PersonIcon onClick={() => ref.current.log()} />

                                        <NotificationsIcon />
                                        <LogoutIcon onClick={logoutDashboard} ml={2} />

                                    </Box>

                                </Grid>
                            </Grid>
                            <CardContent>
                                <Grid container mt={3} spacing={2}>
                                    <Grid item xs={12} md={7}>
                                        <Card className="customer_cards">
                                            <CardContent className="p-0">
                                                <Box mb={2} className="sm-selects bg-light p-15">
                                                    <Typography component="h4" className="sub_title">EQUIPMENT</Typography>
                                                    <Box>
                                                        <Button variant='outlined' mt={2} mb={2} className='retail-btn bg-white' mr={2} onClick={() => ref3.current.log()}>Add</Button>
                                                        <Button variant='outlined' mt={2} mb={2} className='retail-btn bg-white' mr={2}>Delete</Button>
                                                    </Box>
                                                </Box>
                                                <Box className=" w-95 p-15 member-table">
                                                    <MemberTable columns={memeberColumns} options={equipmentoptions} data={data} />
                                                </Box>

                                            </CardContent>

                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Card className="customer_cards">
                                            <CardContent className="p-0">
                                                <Box mb={2} className="sm-selects bg-light p-15">
                                                    <Typography component="h4" className="sub_title">EQUIPMENT INFORMATION</Typography>
                                                    <Box>
                                                        <Button variant='outlined' mt={2} mb={2} className='retail-btn bg-white' onClick={() => ref4.current.log()} mr={2}>View More</Button>
                                                    </Box>
                                                </Box>
                                                <Box className="flex p-15 w-100" mt={2}>
                                                    <Box className="box-150">

                                                    </Box>
                                                    <Box>
                                                        <Box>
                                                            <Typography component='label'>Equipment ID:</Typography>
                                                            <Typography component='p'>#2</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography component='label'>Model</Typography>
                                                            <Typography component='p'>Transit Connect</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <Box>
                                                            <Typography component='label'>Make</Typography>
                                                            <Typography component='p'>Ford</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography component='label'>Year</Typography>
                                                            <Typography component='p'>2015</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box className="p-15">
                                                    <Typography component="h5" className="promo-subtitle">EQUIPMENT INSPECTIONS</Typography>
                                                </Box>
                                                <Box className=" w-95 p-15 member-table">
                                                    <MemberTable columns={eiColumns} options={options} data={eiData} />
                                                </Box>
                                                <Box className="p-15">
                                                    <Typography component="h5" className="promo-subtitle">MAINTENANCE LOG</Typography>
                                                </Box>
                                                <Box className=" w-95 p-15 member-table">
                                                    <MemberTable columns={mlColumns} options={options} data={mlData} />
                                                </Box>
                                                <Box className="p-15">
                                                    <Typography component="h5" className="promo-subtitle">UTILIZATION LOG</Typography>
                                                </Box>
                                                <Box className=" w-95 p-15 member-table">
                                                    <MemberTable columns={ulColumns} options={options} data={ulData} />
                                                </Box>

                                            </CardContent>
                                        </Card>
                                    </Grid>

                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
                {/* <Toolbar /> */}
                <AddNewModal ref={ref3} />
                <EquipmentModal ref={ref4} />
                <AlertModal ref={ref2}/>
            </Box>
        </>
    )
}
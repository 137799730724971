import React from 'react';
import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .required('Please enter Username'),
  password: Yup.string()
    .required('Please enter Password')
});

export default LoginSchema;
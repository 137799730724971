import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";




const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});
const getMuiTheme = () =>
createTheme({
  components: {
    MUIDataTable: {
      styleOverrides: {
        root: {
          backgroundColor: '#red',
        },
        paper: {
          boxShadow: 'none',
        },
      },
    },
    
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#d3dfff',
          fontSize:'0.75rem',
          borderBottom:'solid 1px #000',
        },
      },
    },
    
    MuiTableFooter: {
      styleOverrides: {
        root: {
          '& .MuiToolbar-root': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});


export default function MemberTable(props) {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const columns = [
    { name: "Name", options: { filterOptions: { fullWidth: true } } },
    "Title",
    "Location"
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  

  return (
    
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={props.data}
          columns={props.columns}
          options={props.options}
          className="member-table"
          
        />
      </ThemeProvider>
    </CacheProvider>
  );
}


import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchBar from "material-ui-search-bar";
import ambulance from "../images/Ambulance.svg";
import transports from "../images/transports.svg";
import customer from "../images/c_customer.svg";
import dollar from "../images/dollar.svg";
import ceye from "../images/C-eye.svg";
import transport from "../images/transport.png";
import transport1 from "../images/transport2.png";
import Revenue from "../images/revenue.png";
import cust from "../images/customer.png";
import utilization from "../images/utilization.png";
import utilization1 from "../images/utilization1.png";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Bredcrum from "../Bredcrum";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Calender from "../Calender/Calender";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const cities = ["All Cities", "Visakhapatnam", "Srikakulam"];
const stores = ["All Stores", "Groceries", "Fancy"];
const merchants = ["All Merchants", "Srinivasa", "Harsha"];
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = React.useState("left");
  const [show, setShow] = React.useState(true);
  const [switchs, setSwitchs] = React.useState(true);
  // const openModal = () =>{
  //     setOpen(!open);
  // }

  const ref = useRef();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    setShow((show) => !show);
  };
  const handleSwitch = (event, newAlignment) => {
    setAlignment(newAlignment);
    setSwitchs((switchs) => !switchs);
  };
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent className="main-container">
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Calender />
                        <Button variant="outlined" className="btn-primary">
                          Today
                        </Button>
                        <Button
                          variant="outlined"
                          // className="btn-primary-outline"
                          className="retail-btn bg-white"
                        >
                          Yesterday
                        </Button>
                      </Box>

                      <Box className="select_div filter-adjustment">
                        <Select
                          className="select"
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="All Cities"
                        >
                          <MenuItem>All Cities</MenuItem>
                          {cities.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <Select
                          className="select"
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="All Stores"
                        >
                          <MenuItem>All Stores</MenuItem>
                          {stores.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="All Merchants"
                        >
                          <MenuItem>All Merchants</MenuItem>
                          {merchants.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container>
                    <Typography component="h2" mt={4} fontWeight="bold">
                      Live Dashboard
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} className="custom-dashboard">
                  <Grid item md={3}>
                    <Card>
                      <CardContent>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <ShoppingBagIcon className="orders-icon" />
                          </Box>
                          <Box textAlign={"right"}>
                            <Typography
                              variant="p"
                              className="l-dashboard-title"
                            >
                              Total Orders
                            </Typography>
                            <Typography
                              variant="h3"
                              className="l-dashboard-value"
                            >
                              3,500
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={3}>
                    <Card>
                      <CardContent>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <PersonIcon className="orders-icon" />
                          </Box>
                          <Box textAlign={"right"}>
                            <Typography
                              variant="p"
                              className="l-dashboard-title"
                            >
                              Customers
                            </Typography>
                            <Typography
                              variant="h3"
                              className="l-dashboard-value"
                            >
                              25,000
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={3}>
                    <Card>
                      <CardContent>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <CurrencyRupeeIcon className="orders-icon" />
                          </Box>
                          <Box textAlign={"right"}>
                            <Typography
                              variant="p"
                              className="l-dashboard-title"
                            >
                              Revenue
                            </Typography>
                            <Typography
                              variant="h3"
                              className="l-dashboard-value"
                            >
                              7,21,357
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={3}>
                    <Card>
                      <CardContent>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <RemoveRedEyeIcon className="orders-icon" />
                          </Box>
                          <Box textAlign={"right"}>
                            <Typography
                              variant="p"
                              className="l-dashboard-title"
                            >
                              Merchants
                            </Typography>
                            <Typography
                              variant="h3"
                              className="l-dashboard-value"
                            >
                              1,285
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                  mt={3}
                >
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">Transports</Typography>
                          <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="text alignment"
                            style={{ float: "right" }}
                            className="custom_toggle"
                          >
                            <ToggleButton
                              value="left"
                              aria-label="left aligned"
                            >
                              Status
                            </ToggleButton>
                            <ToggleButton
                              value="justify"
                              aria-label="justified"
                            >
                              Customer
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                        <Box>
                          {show ? (
                            <Box
                              component="img"
                              src={transport}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <Box
                              component="img"
                              src={transport1}
                              style={{ width: "100%" }}
                            />
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">Customer</Typography>
                          <Button variant="outlined" className="source-btn">
                            Source
                          </Button>
                        </Box>
                        <Box>
                          <Box
                            component="img"
                            src={cust}
                            style={{ width: "100%" }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                  mt={3}
                >
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">Utilization</Typography>
                          <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleSwitch}
                            aria-label="text alignment"
                            style={{ float: "right" }}
                            className="custom_toggle"
                          >
                            <ToggleButton
                              value="left"
                              aria-label="left aligned"
                            >
                              Status
                            </ToggleButton>
                            <ToggleButton
                              value="justify"
                              aria-label="justified"
                            >
                              Customer
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                        <Box>
                          {switchs ? (
                            <Box
                              component="img"
                              src={utilization}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <Box
                              component="img"
                              src={utilization1}
                              style={{ width: "100%" }}
                            />
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">Transports</Typography>
                          <Typography component="h6">360166</Typography>
                        </Box>
                        <Box>
                          <Box
                            component="img"
                            src={Revenue}
                            style={{ width: "100%" }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}

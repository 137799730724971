import React, { useState, useRef } from "react";
import { Box, Toolbar, Button, Typography, Grid, Card, CardContent, CardActions, Breadcrumbs, Link, Divider, Select, MenuItem } from "@mui/material";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import ambulance from '../../images/Ambulance.svg';
import transports from '../../images/transports.svg';
import customer from '../../images/c_customer.svg';
import dollar from '../../images/dollar.svg';
import ceye from '../../images/C-eye.svg';

import transport from '../../images/transports.svg';
import Revenue from '../../images/revenue.png';
import cust from '../../images/customer.png';
import utilization from '../../images/utilization.png';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ProfileModal from "../../ProfileModal";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MemberTable from "../../Customer/MemberTable";
import { OutlinedInput, FormControl,Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import Bredcrum from "../../Bredcrum";

const drawerWidth = 240;

const year = [
    '2023',
    '2022',
    '2021',
    '2020',
];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const tires = ['Tire1', 'Tire2', 'Tire3', 'Tire4'];
const roles = ['Driver', 'Dispatcher', 'Owner', 'Book Keeper', 'Manager'];
const shifts = ['Regular', 'Late Night'];
const transportType = ['Ambulatory(Able to Walk)', 'Wheelchair', 'Stretcher', 'Banker Act'];
const driver = ['Single', 'Multiple'];
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function FeeEngine() {

    const [open, setOpen] = useState(false);
    const [alignment, setAlignment] = React.useState('left');
    const [searchBtn, setSearchBtn] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [data,setData] = useState([{"shiftname":'Late Night','startTime':'23.01','endTime':'06:59'}]);
    // const openModal = () =>{
    //     setOpen(!open);
    // }
    const ShiftColumns = [
        { name: "Shift Name", options: { filterOptions: { fullWidth: true } } },
        "Start time",
        "End Time",
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn" onClick={handleOpen}>
                        Edit
                    </Button>
                )
            },
        }
        }
    ];

    const pricingEngine = [
        { name: "TIER1(0-50 MILES)", options: { filterOptions: { fullWidth: true } } },
        "07:00 - 23:00",
        "23:01 - 06:59",
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn" onClick={handleOpen}>
                        Edit
                    </Button>
                )
            },
        }
        }
    ];

    const payEngine = [
        { name: "ON DUTY HOURLY PAY", options: { filterOptions: { fullWidth: true } } },
        "MINIMUM WAGE",
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn" onClick={handleOpen}>
                        Edit
                    </Button>
                )
            },
        }
        }
    ];
    const payEngineTransports = [
        { name: "INCENTIVE", options: { filterOptions: { fullWidth: true } } },
        "APPLICABLE",
        "TYPE (FLAT / %)",
        "FEE",
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn" onClick={handleOpen}>
                        Edit
                    </Button>
                )
            },
        }
        }
    ];
    
    const payEngineData = [["$ 2","$ 11"]];

    const payEngineDataTransports = [["transport","","percentage","20 %"]];

    const pricingEnginedata = [["Ambulatory (Able to Walk) PickUp Fee","$ 65","$ 110"]];
    const ShiftData = [["Late Night","	23:01","06:59"]];
    const zoneData = [["Tier1","0","50"]];
    const transportData = [["Wheelchair"]];
    const serviceData = [["Auxiliary Power Pack"]];
    const roleData = [["Book Keeper"]];
    const holidayData = [["Christmas","25-12-2022","35","10"]];
    const zoneColumns = [
        { name: "Zone Name", options: { filterOptions: { fullWidth: true } } },
        "Start Mile",
        "End Mile",
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn">
                        Edit
                    </Button>
                )
            },
        }
        }
    ];

    const transportColumns = [
        { name: "Transport Name", options: { filterOptions: { fullWidth: true } } },
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn">
                        Edit
                    </Button>
                )
            },
        }
        }
    ];
    const serviceColumns = [
        { name: "Add on services Name", options: { filterOptions: { fullWidth: true } } },
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn">
                        Edit
                    </Button>
                )
            },
        }
        }
    ];
    const roleColumns = [
        { name: "Role Name", options: { filterOptions: { fullWidth: true } } },
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn">
                        Edit
                    </Button>
                )
            },
        }
        }
    ];

    const holidayColumns = [
        { name: "Holiday Name", options: { filterOptions: { fullWidth: true } } },
        "Holiday Date",
        "Pricing Percentage",
        "Pay Percentage",
        {options:{
            customBodyRender: () => {
                return (
                    <Button variant="outlined" color="error" className="save-btn">
                        Edit
                    </Button>
                )
            },
        }
        }
    ];
    const shiftOptions = {
        search: searchBtn,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };
    const pricingOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const ref = useRef();
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <>

            <Box
                component="main"


            >
                <Grid container >
                    <Grid xs="12">
                        <Card xs="11" className="custom_card">
                            <Bredcrum />
                            <CardContent>
                                <Grid container className="full_content">
                                    
                                    <Grid xs="12" mt={1}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} className="filters fee-engine">

                                            <Select
                                                labelId="demo-isolation-label"
                                                id="isolation-select"
                                                className="select"
                                                // onChange={handleSelectOxygen}
                                                // input={<OutlinedInput />}
                                                defaultValue="locations"
                                            >
                                                <MenuItem value="locations">Select Location</MenuItem>
                                                <MenuItem>Debray</MenuItem>

                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid container mt={4}>
                                        <Grid item xs="12">
                                            <Card>
                                                <CardContent>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="custom-tabs">
                                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="custom-tab">
                                                                <Tab label="Setup" {...a11yProps(0)} />
                                                                <Tab label="Pricing Engine" {...a11yProps(1)} />
                                                                <Tab label="Pay Engine" {...a11yProps(2)} />
                                                            </Tabs>
                                                        </Box>
                                                        <TabPanel value={value} index={0}>
                                                            <Grid container spacing={2} mt={3} style={{ textAlign: 'center' }} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                <Grid item xs={12} md={6}>
                                                                    <Card className="customer_cards">
                                                                        <CardContent>
                                                                            <Typography component="h4" className="engine-h4">SHIFTS</Typography>
                                                                            <Box className="shift-box" mt={4}>
                                                                                <FormControl>
                                                                                    <Typography component="label">Shift Name</Typography>
                                                                                    <OutlinedInput />

                                                                                </FormControl>
                                                                                <FormControl>
                                                                                    <Typography component="label">Start time</Typography>
                                                                                    <OutlinedInput type="time" />

                                                                                </FormControl>
                                                                                <FormControl>
                                                                                    <Typography component="label">End Time</Typography>
                                                                                    <OutlinedInput type="time" />

                                                                                </FormControl>
                                                                                <Button variant="outlined" color="error" className="add-btn" >Add</Button>
                                                                            </Box>
                                                                            <Box>
                                                                                <MemberTable columns={ShiftColumns} options={shiftOptions} data={ShiftData} />
                                                                            </Box>


                                                                        </CardContent>

                                                                    </Card>
                                                                </Grid>
                                                                <Grid item xs={12} md={6} >
                                                                    <Card className="customer_cards">
                                                                        <CardContent>
                                                                            <Typography component="h4" className="engine-h4">ZONES</Typography>
                                                                            <Box className="shift-box" mt={4}>
                                                                                <FormControl>
                                                                                    <Typography component="label">Zone Name</Typography>
                                                                                    <OutlinedInput />

                                                                                </FormControl>
                                                                                <FormControl>
                                                                                    <Typography component="label">Start Mile</Typography>
                                                                                    <OutlinedInput />

                                                                                </FormControl>
                                                                                <FormControl>
                                                                                    <Typography component="label">End Mile</Typography>
                                                                                    <OutlinedInput />

                                                                                </FormControl>
                                                                                <Button variant="outlined" color="error" className="add-btn" >Add</Button>
                                                                            </Box>
                                                                            <Box>
                                                                                <MemberTable columns={zoneColumns} options={shiftOptions} data={zoneData} />
                                                                            </Box>


                                                                        </CardContent>


                                                                    </Card>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Card className="customer_cards">
                                                                        <CardContent>
                                                                            <Typography component="h4" className="engine-h4">TRANSPORTS</Typography>
                                                                            <Box className="shift-box" mt={4}>
                                                                                <FormControl>
                                                                                    <Typography component="label">Transport Name</Typography>
                                                                                    <OutlinedInput />

                                                                                </FormControl>

                                                                                <Button variant="outlined" color="error" className="add-btn" >Add</Button>
                                                                            </Box>
                                                                            <Box>
                                                                                <MemberTable columns={transportColumns} options={shiftOptions} data={transportData} />
                                                                            </Box>


                                                                        </CardContent>

                                                                    </Card>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Card className="customer_cards">
                                                                        <CardContent>
                                                                            <Typography component="h4" className="engine-h4">ADD ON SERVICES</Typography>
                                                                            <Box className="shift-box" mt={4}>
                                                                                <FormControl>
                                                                                    <Typography component="label">Add on services</Typography>
                                                                                    <OutlinedInput />

                                                                                </FormControl>

                                                                                <Button variant="outlined" color="error" className="add-btn">Add</Button>
                                                                            </Box>
                                                                            <Box>
                                                                                <MemberTable columns={serviceColumns} options={shiftOptions} data={serviceData} />
                                                                            </Box>


                                                                        </CardContent>

                                                                    </Card>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Card className="customer_cards">
                                                                        <CardContent>
                                                                            <Typography component="h4" className="engine-h4">ROLES</Typography>
                                                                            <Box className="shift-box" mt={4}>
                                                                                <FormControl>
                                                                                    <Typography component="label">Role Name</Typography>
                                                                                    <OutlinedInput />

                                                                                </FormControl>

                                                                                <Button variant="outlined" color="error" className="add-btn">Add</Button>
                                                                            </Box>
                                                                            <Box>
                                                                                <MemberTable columns={roleColumns} options={shiftOptions} data={roleData} />
                                                                            </Box>


                                                                        </CardContent>

                                                                    </Card>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Card className="customer_cards">
                                                                        <CardContent>
                                                                            <Typography component="h4" className="engine-h4">HOLIDAY SHIFT</Typography>
                                                                            <Box className="shift-box" mt={4}>
                                                                                <FormControl>
                                                                                    <Typography component="label">Holiday Name</Typography>
                                                                                    <OutlinedInput />

                                                                                </FormControl>
                                                                                <FormControl>
                                                                                    <Typography component="label">Date of Holiday</Typography>
                                                                                    <OutlinedInput type="date" />

                                                                                </FormControl>
                                                                                <FormControl>
                                                                                    <Typography component="label">Pricing Percentage</Typography>
                                                                                    <OutlinedInput type="text" />

                                                                                </FormControl>
                                                                                <FormControl>
                                                                                    <Typography component="label">Pay Percentage</Typography>
                                                                                    <OutlinedInput type="text" />

                                                                                </FormControl>
                                                                                <Button variant="outlined" color="error" className="add-btn" >Add</Button>
                                                                            </Box>
                                                                            <Box>
                                                                                <MemberTable columns={holidayColumns} options={shiftOptions} data={holidayData} />
                                                                            </Box>


                                                                        </CardContent>

                                                                    </Card>
                                                                </Grid>
                                                            </Grid>
                                                        </TabPanel>
                                                        <TabPanel value={value} index={1}>
                                                            <Grid container mt={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Select
                                                                        labelId="demo-isolation-label"
                                                                        id="isolation-select"
                                                                        className="select"
                                                                        // onChange={handleSelectOxygen}
                                                                        // input={<OutlinedInput />}
                                                                        defaultValue="Tire1"

                                                                    >
                                                                        {
                                                                            tires.map((item) => {
                                                                                return <MenuItem value={item}>{item}</MenuItem>
                                                                            })
                                                                        }


                                                                    </Select>
                                                                    <Card className="customer_cards" style={{ marginTop: '20px' }}>
                                                                        <CardContent>

                                                                            <MemberTable columns={pricingEngine} options={pricingOptions} data={pricingEnginedata} />

                                                                        </CardContent>

                                                                    </Card>
                                                                </Grid>

                                                            </Grid>
                                                        </TabPanel>
                                                        <TabPanel value={value} index={2}>
                                                            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-around' }} mt={3}>
                                                                <Grid item xs={12} md={6}>
                                                                    <Card className="customer_cards">
                                                                        <CardContent>
                                                                            <Box className="select-container" mb={3}>
                                                                                <Typography component="label">Role</Typography>
                                                                                <Select
                                                                                    labelId="demo-isolation-label"
                                                                                    id="isolation-select"
                                                                                    className="select"
                                                                                    // onChange={handleSelectOxygen}
                                                                                    // input={<OutlinedInput />}
                                                                                    defaultValue="Driver"

                                                                                >
                                                                                    <MenuItem value="" selected=''>Select Role</MenuItem>
                                                                                    {
                                                                                        roles.map((item) => {
                                                                                            return <MenuItem value={item}>{item}</MenuItem>
                                                                                        })
                                                                                    }

                                                                                </Select>
                                                                                <Typography component="label">Shift</Typography>
                                                                                <Select
                                                                                    labelId="demo-isolation-label"
                                                                                    id="isolation-select"
                                                                                    className="select"
                                                                                    // onChange={handleSelectOxygen}
                                                                                    // input={<OutlinedInput />}
                                                                                    defaultValue="Regular"

                                                                                >
                                                                                    <MenuItem value="">Select Shift</MenuItem>
                                                                                    {
                                                                                        shifts.map((item) => {
                                                                                            return <MenuItem value={item}>{item}</MenuItem>
                                                                                        })
                                                                                    }

                                                                                </Select>
                                                                            </Box>
                                                                            <MemberTable columns={payEngine} options={pricingOptions} data={payEngineData} />

                                                                        </CardContent>

                                                                    </Card>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Card className="customer_cards">
                                                                        <CardContent>
                                                                            <Box className="select-container" mb={3}>
                                                                            <Typography component="label" mr={1}>Transport Type</Typography>
                                                                                <Select
                                                                                    labelId="demo-isolation-label"
                                                                                    id="isolation-select"
                                                                                    className="select"
                                                                                // onChange={handleSelectOxygen}
                                                                                // input={<OutlinedInput />}
                                                                                defaultValue="Ambulatory(Able to Walk)"

                                                                                >
                                                                                    <MenuItem value="">Select Transport Type</MenuItem>
                                                                                    {
                                                                                        transportType.map((item) => {
                                                                                            return <MenuItem value={item}>{item}</MenuItem>
                                                                                        })
                                                                                    }

                                                                                </Select>
                                                                                <Typography component="label" ml={1}>Driver Count</Typography>
                                                                                <Select
                                                                                    labelId="demo-isolation-label"
                                                                                    id="isolation-select"
                                                                                    className="select"
                                                                                    // onChange={handleSelectOxygen}
                                                                                    // input={<OutlinedInput />}
                                                                                    defaultValue="Single"

                                                                                >
                                                                                    <MenuItem value="">Select Driver Count</MenuItem>
                                                                                    {
                                                                                        driver.map((item) => {
                                                                                            return <MenuItem value={item}>{item}</MenuItem>
                                                                                        })
                                                                                    }

                                                                                </Select>
                                                                            </Box>
                                                                            <MemberTable columns={payEngineTransports} options={pricingOptions} data={payEngineDataTransports} />

                                                                        </CardContent>

                                                                    </Card>
                                                                </Grid>
                                                            </Grid>
                                                        </TabPanel>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>



                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
                {/* <Toolbar /> */}
                <ProfileModal ref={ref} />
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Box className="edit-modal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
          SHIFT DETAILS
          </Typography>
          <Close onClick={handleClose} />
          </Box>
             {
                data.map((item)=>{
                    for (const [key, value] of Object.entries(item)) {
                     return <FormControl>
                        <Typography>{key}</Typography>
                        <OutlinedInput />
                      </FormControl>
                      }
                })
             }
        </Box>
      </Modal>
            </Box>
        </>
    )
}
import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import Calender from "../Calender/Calender";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { OutlinedInput, FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Retailers/ExpandableRowTable";
import email from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import OrderModal from "./OrderModal";

const drawerWidth = 240;

export default function EmployeeReport() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const memeberColumns = [
    { name: "EMP ID", options: { filterOptions: { fullWidth: true } } },
    "EMP Name",
    "Days",
    "Pay/Day",
    "Amount",
    "Status",
  ];

  const payColumns = [
    { name: "", options: { filterOptions: { fullWidth: true } } },
    "DAYS",
    "PAY / DAY",
    "TOTAL",
  ];

  const pcData = [
    ["19825", "Ramesh Rao", "35", "₹ 100", "₹ 3201.00", "Paid"],
    ["19826", "Suresh Kumar", "40", "₹ 150.00", "₹ 7589.00", "Pending"],
  ];
  const payData = [
    ["Total Days", "14", "₹ 100.00", "₹ 1400.00"],
    ["Leaves", "02", "₹ 100.00", "₹ 200.00"],
    ["Over Time", "0.5", "₹ 150.00", "₹ 75.00"],
    ["TDS", "", "", "₹ 100.00"],
    ["Incentives", "", "", "₹ 50.00"],
    ["Deductions & Allowances", "", "", "₹ 25.00"],
    ["", "", "Net Pay", "Rs. 1000"],
  ];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const pcColumns = [
    { name: "SKU ID", options: { filterOptions: { fullWidth: true } } },
    "SKU NAME",
    "QTY",
    "PRICE",
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Total Orders:</b> 31
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>City:</b> Visakhapatnam
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Retailer Type:</b> Kirana Store
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  const data = [
    [
      "12487",
      "Sri Sai Kirana and General Stores",
      "₹ 25.00",
      "Completed",
      "₹ 24000.00",
    ],
    [
      "12456",
      "Suryanarayana Departmental Stores",
      "₹ 25.00",
      "Completed",
      "₹ 36000.00",
    ],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Breadcrumbs aria-label="breadcrumb">
                      {/* <Link underline="hover" color="inherit" href="/">
                                                Home
                                            </Link> */}
                      <Link underline="hover" color="inherit" href="#">
                        Accounting
                      </Link>
                      <Typography color="text.primary">
                        Employee Report
                      </Typography>
                    </Breadcrumbs>
                    <Box className="icons bredcum-icons">
                      <SearchBar className="search-bar" />
                      <PersonIcon onClick={() => ref.current.log()} />

                      <NotificationsIcon />
                      <LogoutIcon onClick={logoutDashboard} ml={2} />
                    </Box>
                  </Grid>
                  <Divider className="bredcum-hr"></Divider>
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex">
                        <Calender className="calenders" />
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Today
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          This Month
                        </Button>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">Retailers</MenuItem>
                          <MenuItem value="visakhapatnam">
                            S.S.M Kirana
                          </MenuItem>
                          <MenuItem value="vijayawada">
                            Sri Raghavendra Super Market
                          </MenuItem>

                          <MenuItem value="kurnool">
                            Suryanarayana Kirana and General
                          </MenuItem>
                          <MenuItem value="kurnool">
                            SKML Kirana and General
                          </MenuItem>
                          <MenuItem value="kurnool">
                            Veeranjaneya Departmental Stores
                          </MenuItem>
                        </Select>
                      </Box>
                      <Box className="flex">
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Search
                        </Button>

                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">Download</MenuItem>
                          <MenuItem value="visakhapatnam">PDF</MenuItem>
                          <MenuItem value="vijayawada">Excel</MenuItem>

                          <MenuItem value="kurnool">Image</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="member-table">
                          <ExpandableRowTable
                            columns={memeberColumns}
                            options={options}
                            data={pcData}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            mt={2}
                            mb={2}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography>Ramana Murthy</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mb={2}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography>+91 90528 45869</Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Divider></Divider>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                  <Typography component="label">
                                    Emp ID
                                  </Typography>
                                  <Typography>#19829</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>
                                    ramanamurthy@gmail.com
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={email} />
                                <Box>
                                  <Typography component="label">
                                    Address
                                  </Typography>
                                  <Typography>
                                    203, HiTech city Hyderabad
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">DOB</Typography>
                                  <Typography>12/12/2000</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Typography component="h2" mb={2}>
                              JOB DETAILS
                            </Typography>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="box-cell">
                                    <Typography component="p">
                                      Job Name
                                    </Typography>
                                    <Typography component="h4">
                                      Dispatcher
                                    </Typography>
                                  </TableCell>
                                  <TableCell className="box-cell">
                                    <Typography component="p">
                                      Job Type
                                    </Typography>
                                    <Typography component="h4">
                                      Part Time
                                    </Typography>
                                  </TableCell>
                                  <TableCell className="box-cell">
                                    <Typography component="p">
                                      Comment
                                    </Typography>
                                    <Typography component="h4">NA</Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Grid mt={5} className="view-more-btn">
                          <Box className="member-table">
                            <MemberTable
                              columns={payColumns}
                              options={pcOptions}
                              data={payData}
                            />
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <OrderModal ref={ref3} />
      </Box>
    </>
  );
}

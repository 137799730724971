import React, { useImperativeHandle, useRef, forwardRef } from "react";
import { Box, Toolbar, Button, Typography, Grid, Card, CardContent, CardActions, Breadcrumbs, Link, Divider, Select, MenuItem, SvgIcon, FormControl, OutlinedInput } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import Calender from "../Calender/Calender";
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import Tab from '@mui/material/Tab';
import status from '../images/last-active.svg';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Retailers/ExpandableRowTable";
import email from '../images/mail.svg';
import MemberTable from "../Customer/MemberTable";
import EditPromoModal from "./EditPromoModal";
import MultiSelect from "./MultiSelect";
import { CheckBox } from "@mui/icons-material";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function PromotionsEdit() {

    const [showState, setShowState] = React.useState(false);
    const buttonClickedHandler = () => {
        setShowState((showState) => showState = !showState)
    }
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [alignment, setAlignment] = React.useState('left');

    const ref = React.useRef();
    const ref1 = React.useRef();
    const ref3 = React.useRef();

    let navigate = useNavigate();
    const logoutDashboard = () => {
        navigate('/');
    }
    const ref4 = useRef();

   


    const pcOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    

    const customerColumns = [
        "Customer Type",
        "Customer Name",
        {
        options: {
            filter: true,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
               <Checkbox />
              );
            }
          }
        }
    ];

    const segmentColumns = [
        "Segment",
        "Sub Segment",
        "Product",
        {
        options: {
            filter: true,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
               <DeleteIcon className='delete' />
              );
            }
          }
        }
    ];

    const segmentData = [

        ["Diary", "Milk & Ghee", "Amul Butter"],
        ["Cooking", "Dals", "Moong Dal"],
        ["Beverages", "Soft Drinks", "Real Fruit Mango"]

    ];

    const customerData = [

        ["Wholesaler", "Sri Venkateswara Traders"],
        ["Wholesaler", "Vishnu Traders"],
        ["Retailer", "Hanuman General Stores"]

    ];

    const options = {
        filter: true,
        onFilterChange: (changedColumn, filterList) => {
            console.log(changedColumn, filterList);
        },
        selectableRows: false,
        isRowSelectable: false,
        expandableRowsHeader: false,
        icons: { Add: () => 'Add Row' },
        filterType: "dropdown",
        responsive: "scrollMaxHeight",
        rowsPerPage: 10,
        // expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            console.log(rowData, rowMeta);
            return (
                <React.Fragment>
                    <tr className="inner-row">
                        <td colSpan={6}>
                            <TableContainer>
                                <Table aria-label="simple table">

                                    <TableBody>
                                        {/* {rows.map(row => ( */}
                                        {/* <TableRow key={row.id}> */}
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Taxes:</b> ₹ 485.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Advance:</b> ₹ 321.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Due Amount:</b> ₹ 542.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>State:</b> State</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {/* ))} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>

                </React.Fragment>
            );
        },
        page: 1
    };

    const data = []
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <>

            <Box
                component="main"


            >
                <Grid container >
                    <Grid xs="12">
                        <Card xs="11" className="custom_card store-item-card">
                            <Grid container className="full_content bazaar-breadcrumb">
                                <Grid className="inner-bredcum" xs="12">
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/">
                                            Home
                                        </Link>

                                        <Typography color="text.primary">Promotions</Typography>
                                    </Breadcrumbs>
                                    <Box className="icons bredcum-icons">

                                        <SearchBar className="search-bar" />
                                        <PersonIcon onClick={() => ref.current.log()} />

                                        <NotificationsIcon />
                                        <LogoutIcon onClick={logoutDashboard} ml={2} />

                                    </Box>

                                </Grid>
                            </Grid>
                            <CardContent className="add_new_item">
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={4} >

                                            <Grid item xs={12} md={3}>
                                                <Typography component='h4' className="sub-title">Edit Promo</Typography>
                                                <FormControl mt={2}>
                                                    <Typography component='label' className="primary-color">Promo Code</Typography>
                                                    <OutlinedInput placeholder="IPL Offer" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}></Grid>
                                            <Grid item xs={12} md={6}>


                                                <MultiSelect />
                                                <Box mt={2}>
                                                    <Typography component='h4' className="promo-subtitle">APPLICABLE TO</Typography>
                                                    <FormGroup className="checkbox-group">
                                                        <FormControlLabel control={<Checkbox />} label="POS" />
                                                        <FormControlLabel control={<Checkbox />} label="Phone Orders" />
                                                        <FormControlLabel control={<Checkbox />} label="Ecommerce" />
                                                    </FormGroup>
                                                </Box>

                                            </Grid>

                                        </Grid>




                                        <Grid container>


                                            <Grid container spacing={4} mt={1} mb={2}>
                                                <Grid item xs={12} md={3} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Discount Type</Typography>
                                                        <Select
                                                            labelId="demo-isolation-label"
                                                            id="isolation-select"
                                                            className="select"
                                                            // onChange={handleSelectOxygen}
                                                            // input={<OutlinedInput />}
                                                            defaultValue="Percentage"
                                                        >
                                                            <MenuItem value="Percentage">Percentage Discount</MenuItem>
                                                            <MenuItem value="Medicines">Promo Code</MenuItem>
                                                            <MenuItem value="Grocires">Gift Card</MenuItem>
                                                            <MenuItem value="Grocires">Coupon</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Discount Value</Typography>
                                                        <OutlinedInput type="value" value="20%" />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3} >
                                                    
                                                </Grid>
                                                <Grid item xs={12} md={3} >
                                                    
                                                </Grid>
                                               
                                            </Grid>
                                            <Box className="flex-between w-100">
                                            <Typography component="h4" mt={1} mb={2} className="font-bold">APPLIES TO</Typography>

                                            <Button variant='outlined' mt={2} mb={2} className='retail-btn float-right' mr={2} >Add</Button>

                                            </Box>
                                            <Grid container spacing={3} mb={2}>
                                                <Grid item xs={12} md={4} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Segment</Typography>
                                                        <Select
                                                            labelId="demo-isolation-label"
                                                            id="isolation-select"
                                                            className="select"
                                                            // onChange={handleSelectOxygen}
                                                            // input={<OutlinedInput />}
                                                            defaultValue="5"
                                                        >
                                                            <MenuItem value="yes">Select Segment</MenuItem>
                                                            <MenuItem value="5">Diary</MenuItem>
                                                            <MenuItem value="10">Skin Care</MenuItem>
                                                            <MenuItem value="15">Baby Care</MenuItem>
                                                            <MenuItem value="15">Beverages</MenuItem>
                                                            <MenuItem value="15">Cooking</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Sub Segment</Typography>
                                                        <Select
                                                            labelId="demo-isolation-label"
                                                            id="isolation-select"
                                                            className="select"
                                                            // onChange={handleSelectOxygen}
                                                            // input={<OutlinedInput />}
                                                            defaultValue="5"
                                                        >
                                                            <MenuItem value="yes">Sub Segment</MenuItem>
                                                            <MenuItem value="5">Atta & Flours</MenuItem>
                                                            <MenuItem value="10">Oil & Ghee</MenuItem>
                                                            <MenuItem value="15">Rice</MenuItem>
                                                            <MenuItem value="15">Dal & Pulses</MenuItem>
                                                            <MenuItem value="15">Masala</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Product</Typography>
                                                        <Select
                                                            labelId="demo-isolation-label"
                                                            id="isolation-select"
                                                            className="select"
                                                            // onChange={handleSelectOxygen}
                                                            // input={<OutlinedInput />}
                                                            defaultValue="dal"
                                                        >
                                                            <MenuItem value="yes">Product</MenuItem>
                                                            <MenuItem value="coffee">Bru Coffee</MenuItem>
                                                            <MenuItem value="butter">Amul Butter</MenuItem>
                                                            <MenuItem value="mango">Real Fruit Mango</MenuItem>
                                                            <MenuItem value="rice">Baawat Basmati Rice</MenuItem>
                                                            <MenuItem value="dal">Tool Dal</MenuItem>

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            
                                            <Box className="member-table w-100" mt={2}>
                                                <MemberTable columns={segmentColumns} options={options} data={segmentData} />
                                            </Box>

                                            <Box className="flex-between w-100" mt={2}>
                                            <Typography component="h4" mt={1} mb={2} className="font-bold">DOESN'T APPLIES TO</Typography>

                                            <Button variant='outlined' mt={2} mb={2} className='retail-btn float-right' mr={2} >Add</Button>

                                            </Box>
                                            <Grid container spacing={3} mb={2}>
                                                <Grid item xs={12} md={4} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Segment</Typography>
                                                        <Select
                                                            labelId="demo-isolation-label"
                                                            id="isolation-select"
                                                            className="select"
                                                            // onChange={handleSelectOxygen}
                                                            // input={<OutlinedInput />}
                                                            defaultValue="5"
                                                        >
                                                            <MenuItem value="yes">Select Segment</MenuItem>
                                                            <MenuItem value="5">Diary</MenuItem>
                                                            <MenuItem value="10">Skin Care</MenuItem>
                                                            <MenuItem value="15">Baby Care</MenuItem>
                                                            <MenuItem value="15">Beverages</MenuItem>
                                                            <MenuItem value="15">Cooking</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Sub Segment</Typography>
                                                        <Select
                                                            labelId="demo-isolation-label"
                                                            id="isolation-select"
                                                            className="select"
                                                            // onChange={handleSelectOxygen}
                                                            // input={<OutlinedInput />}
                                                            defaultValue="5"
                                                        >
                                                            <MenuItem value="yes">Sub Segment</MenuItem>
                                                            <MenuItem value="5">Atta & Flours</MenuItem>
                                                            <MenuItem value="10">Oil & Ghee</MenuItem>
                                                            <MenuItem value="15">Rice</MenuItem>
                                                            <MenuItem value="15">Dal & Pulses</MenuItem>
                                                            <MenuItem value="15">Masala</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Product</Typography>
                                                        <Select
                                                            labelId="demo-isolation-label"
                                                            id="isolation-select"
                                                            className="select"
                                                            // onChange={handleSelectOxygen}
                                                            // input={<OutlinedInput />}
                                                            defaultValue="dal"
                                                        >
                                                            <MenuItem value="yes">Product</MenuItem>
                                                            <MenuItem value="coffee">Bru Coffee</MenuItem>
                                                            <MenuItem value="butter">Amul Butter</MenuItem>
                                                            <MenuItem value="mango">Real Fruit Mango</MenuItem>
                                                            <MenuItem value="rice">Baawat Basmati Rice</MenuItem>
                                                            <MenuItem value="dal">Tool Dal</MenuItem>

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Box className="member-table w-100" mt={2}>
                                                <MemberTable columns={segmentColumns} options={options} data={segmentData} />
                                            </Box>

                                            <Divider></Divider>
                                            <Box mt={2} mb={1}>
                                                <Typography component='b' className="small">VALIDITY</Typography>
                                            </Box>
                                            <Grid container spacing={3} mb={2}>

                                                <Grid item xs={12} md={4} >

                                                    <FormControl className="w-100">
                                                        <Typography component='label'>From Date</Typography>
                                                        <OutlinedInput type="date" />
                                                    </FormControl>

                                                </Grid>
                                                <Grid item xs={12} md={4} >
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>To Date</Typography>
                                                        <OutlinedInput type="date" />
                                                    </FormControl>
                                                </Grid>

                                            </Grid>
                                            <Divider></Divider>
                                            <Box className="flex-between w-100" mt={2}>
                                                <Typography component="h4" className="sub_title" mt={2}>EXISTING CUSTOMERS</Typography>
                                                <Box>
                                                <Button variant='outlined' mt={2} mb={2} className='retail-btn mr-20'  onClick={() => ref4.current.log()}>Add New Customer</Button>
                                                <Button variant='outlined' mt={2} mb={2} className='retail-btn' onClick={() => ref4.current.log()}>Delete Customer</Button>
                                                </Box>
                                            </Box>
                                            <Box className="member-table w-100" mt={2}>
                                                <MemberTable columns={customerColumns} options={options} data={customerData} />
                                            </Box>
                                            
                                            
                                           
                                           
                                            <Grid container spacing={2} mb={2} mt={2}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl className="add-image">

                                                        <Box component='label' for="files" class="btn">Add Image</Box>
                                                        <Box component='input' id="files" style={{ visibility: "hidden" }} type="file" />

                                                        {/* <OutlinedInput id="files" type="file" name="add_image[]" className="add-image" /> */}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <FormControl className="w-100">
                                                        <Typography component='label'>Description</Typography>
                                                        <Box component="textarea" placeholder="Staff Offer" className="br-10" rows={8} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Divider></Divider>
                                            <Box className=" w-100" mt={2}>
                                                <Button variant='outlined' mt={2} mb={2} className='retail-btn float-right' mr={2} onClick={() => ref4.current.log()}>Update</Button>

                                            </Box>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
                {/* <Toolbar /> */}
                <EditPromoModal ref={ref4} />
            </Box>
        </>
    )
}
import React from "react";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Label } from "@mui/icons-material";
import { Typography } from "@mui/material";

// const Card = () => (
//   <tr>
//     <td className="fullWidth">
//       <h1>
//         lorem ipsum dorel em quol acee, vion, bloolw, wafeo, feiwjfoiew,
//         foiwejifowefjweoi, fewjoewjfowei, fwefwefewfewfewf
//       </h1>
//     </td>
//   </tr>
// );

const ExpandableRowTable = (props) => {
  const columns = [
    {
      name: "Retailers ID",
    },
    {
      name: "Retailers Name",
    },
    {
      name: "Phone",
    },
    {
      name: "Total Orders",
    },
    {
      name: "City",
    },
    {
      name: "Retailer Type",
    },
  ];
  const data = [
    ["#147258", "VENKATA SAI TRADERS"],
    ["#258369", "OM PROVISION"],
    ["#369147", "S BALAJI PROVISION"],
  ];

  function createData(id, name) {
    return { id, name };
  }

  // const rows = [
  //   createData("Total Orders", 24),
  //   createData("City", "Visakhapatnam"),
  //   createData("Retailer Type", "Groceries")
  // ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: "single",
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Total Orders:</b> 31
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>City:</b> Visakhapatnam
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Retailer Type:</b> Kirana Store
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  return (
    <MUIDataTable
      data={props.data ? props.data : data}
      columns={props.columns ? props.columns : columns}
      options={props.options ? props.options : options}
      className="retailer-table"
    />
  );
};

export default ExpandableRowTable;

import React, { useImperativeHandle, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput, Divider } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MemberTable from '../Customer/MemberTable';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import wallet from '../images/wallet.png';
import credit from '../images/credit.png';
import upi from '../images/upi.png';
import customer from '../images/customers.svg';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const CustomerInvoiceDetailsModal = forwardRef((props, ref9) => {
    const [open, setOpen] = React.useState(false);
    const [pay, setPay] = React.useState(false);
    const [passwd, setPasswd] = React.useState(false);
    const handlePassword = () => {
        setPasswd(passwd => !passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const changePay = () =>{
        setPay(pay=>!pay);
    }

    const ItemColumns = [
        "SKU ID",
        "ITEM NAME & DETAILS",
        "QTY",
        "PRICE",
        "TOTAL",
        ""
    ];
    const itemData = [["#SKU89948", "Carrot & vegetable", "2", "$ 200", "₹ 3500.00"],
    ["#SKU89948", "Apples & Fruits", "2", "$ 200", "	₹ 8000.00",<Checkbox className='check'  />],
    ["#SKU89948", "Blue Lime & Crushers", "2", "$ 200", "	₹ 11500.00",<Checkbox className='check'  />],
    ["#SKU89948", "Apples & Fruits", "2", "	$ 200", "	₹ 500.00",<Checkbox className='check'  />],
    ["#SKU89948", "Samosa & Snacks", "2", "	$ 200", "	₹ 400.00",<Checkbox className='check' />],
    ["#SKU89948", "", "", "	CGST", "	₹ 100.00",<Checkbox className='check'  />],
    ["#SKU89948", "", "", "	SGST", "	₹ 100.00",<Checkbox className='check' />],

    ["#SKU89948", "", "", "	IGST", "	₹ 100.00"], ["", "", "", "	UTGST", "	₹ 100.00"],
    ["", "", "", "	Compensatory Cess Rate", "		₹ 50.00"]];
    const pcOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        responsive: "scrollMaxHeight",
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };
    const showSwalWithLink = () => {
        MySwal.fire({
            icon: 'error',
            text: "All Fields Are Mandatory",
        });
    };

    useImperativeHandle(ref9, () => ({
        log() {
            handleOpen();
        }
    }));


    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal '

            >
                <Box sx={{ ...style }} className='view-more-content profile-modal'>
                    <Grid container>
                        <Grid item xs={12} className="profile-header p-15" >

                            <Typography id="parent-modal-title" component="h4">INVOICE DETAILS</Typography>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <Divider></Divider>

                    <Grid container spacing={2} className='details-div p-15' >


                        <Grid item xs={12} md={12}>
                            <Box className="w-100 flex-between" mb={2}>
                                <Typography component='p'>#INVOICE23009</Typography>
                                <Typography component='p'>16-Mar-2021 | 03:34 PM</Typography>
                            </Box>
                            <Box className="w-100" mb={2}>
                                <Typography component='p'>Ph +91-9638527410</Typography>
                               
                            </Box>
                            
                            <MemberTable columns={ItemColumns} data={itemData} options={pcOptions} />
                            <Box className="w-100 text-center" mt={2} mb={2}>
                            <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={changePay} >Exchange</Button>
                            <Button variant='outlined' color='error' mt={2} className='retail-btn ml-20' onClick={changePay} >Refund</Button> 
                            </Box>
                        </Grid>
                     
                    </Grid>
                       
                </Box>
            </Modal>
        </div>
    );
});

export default CustomerInvoiceDetailsModal;
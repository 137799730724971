import logo from "./bazar-logo.svg";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

export default function VerificationScreen() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box className="Absolute-Box text-center">
          <Box sx={{ minWidth: 275, paddingTop: 3 }}>
            <Card variant="outlined">
              <Box
                src={logo}
                className="App-logo"
                alt="logo"
                mt={3}
                component="img"
              />
              <CardContent className="card-content text-center">
                <Typography
                  className="clr-primary fw-bold"
                  variant="h5"
                  gutterBottom
                >
                  Phone Verification
                </Typography>
                <Box className="">
                  {/* <FormControl className="w-100 mt-10" variant="standard">
                    <InputLabel shrink htmlFor="username-input">
                      Email OTP
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      id="email-input"
                      type="password"
                      className="mb-3"
                      placeholder="Enter Six Digit OTP Sent to Mail"
                    />
                  </FormControl> */}
                  <FormControl className="w-100 mt-10" variant="standard">
                    <InputLabel shrink htmlFor="username-input">
                      Phone OTP
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      id="mobile-input"
                      type="password"
                      placeholder="Enter Six Digit OTP Sent to Mobile"
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    className="mt-20 w-100 login-btn btn-primery _btn-outline active btn-clr-main py-2 mx-0"
                  >
                    Submit
                  </Button>

                  <Box className="mt-3">
                    <Typography className="" variant="body2" gutterBottom>
                      Still facing issue ?<Box component="br" /> Contact{" "}
                      <a href="tel:1234567890" className="frgt-pswrd">
                        {" "}
                        +91-1234567890
                      </a>{" "}
                      or Email{" "}
                      <a
                        href="mailto:someone@example.com"
                        className="frgt-pswrd"
                      >
                        {" "}
                        someone@example.com
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

// export default App;

import * as React from 'react';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormControl,InputLabel,Input } from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

export default function ShiftManager() {
  return (
    <Card className='shift-card'>
      <CardContent>
        <Typography component="p" color="text.secondary" gutterBottom>
        Shift Manager
        </Typography>
        <FormControl variant="standard">
        <InputLabel shrink htmlFor="bootstrap-input">
          Name
        </InputLabel>
        <BootstrapInput defaultValue="test" id="bootstrap-input" />
      </FormControl>
      <FormControl variant="standard" style={{marginTop:'10px'}}>
        <InputLabel shrink htmlFor="bootstrap-input">
          Phone Number
        </InputLabel>
        <BootstrapInput defaultValue="1234567890" id="bootstrap-input" />
      </FormControl>
      </CardContent>
      <CardActions>
        <Button className='submit-btn'>Submit</Button>
      </CardActions>
    </Card>
  );
}
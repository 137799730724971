import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import LocationModal from "./LocationModal";
import Bredcrum from "../Bredcrum";

export default function AddMerchant() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref4 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const eiColumns = ["ID", "Location", "Name"];
  const eiData = [
    ["#963258", "Srikakulam", "Ramya"],
    ["#963258", "Srikakulam", "Ramya"],
    ["#856214", "Vizianagaram", "Srilekha"],
  ];
  const mlColumns = [
    "DATE",
    "TYPE",
    "TASK",
    "LIFE OF REPAIR",
    "COST",
    "COMMENTS",
  ];

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: false,
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            ADD MERCHANT
                          </Typography>
                        </Box>
                        <Grid container spacing={2} className="p-15">
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Merchant Name
                              </Typography>
                              <OutlinedInput value="" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">City</Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Select City
                                </MenuItem>
                                <MenuItem value="car">visakhapatnam</MenuItem>
                                <MenuItem value="van">vijayawada</MenuItem>
                                <MenuItem value="van">Kurnool</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Merchant Type
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Select Merchant Type
                                </MenuItem>
                                <MenuItem value="Medical">
                                  Medical Store
                                </MenuItem>
                                <MenuItem value="Fancy">Fancy</MenuItem>
                                <MenuItem value="Grocires">Grocires</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">Email</Typography>
                              <OutlinedInput value="" placeholder="Email" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                GST Number
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="GST Number"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                GST Tax rate
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="GST Tax rate"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <OutlinedInput value="" type="file" />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            BANK INFO
                          </Typography>
                        </Box>
                        <Grid container spacing={3} className="p-15">
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Bank Name
                              </Typography>
                              <OutlinedInput value="" placeholder="HDFC Bank" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Bank Branch
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Bank Branch"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                IFSC Code
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Bank Branch"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Account Number
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="0254896856"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Account Holder Name
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Account Holder Name"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Payment Terms
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Payment Terms
                                </MenuItem>
                                <MenuItem value="visakhapatnam">
                                  Cheque
                                </MenuItem>
                                <MenuItem value="vijayawada">UPI</MenuItem>
                                <MenuItem value="vijayawada">
                                  Debit / Credit Card
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Commission Type
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Percentage
                                </MenuItem>
                                <MenuItem value="visakhapatnam">5%</MenuItem>
                                <MenuItem value="vijayawada">10%</MenuItem>
                                <MenuItem value="vijayawada">15%</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Origination Fee
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="enter Origination Fee"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Paytm No
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="enter paytm no"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Google Pay No
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="enter Google Pay No"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Phonepe No
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="enter Phonepe No"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Box className="p-15 text-center">
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={3}
                            className="retail-btn mr-20"
                          >
                            Add Merchant
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={3}
                            className="retail-btn"
                          >
                            Refresh
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            LOCATIONS
                          </Typography>
                          <Box>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn bg-white"
                              onClick={() => ref4.current.log()}
                              mr={2}
                            >
                              Add Locations
                            </Button>
                          </Box>
                        </Box>
                        <Box className="flex p-15 w-100" mt={2}></Box>

                        <Box className=" w-95 p-15 member-table">
                          <MemberTable
                            columns={eiColumns}
                            options={options}
                            data={eiData}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <LocationModal ref={ref4} />
      </Box>
    </>
  );
}

import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CardMedia from "@mui/material/CardMedia";
import customers from "../images/customers.svg";
import mapmarker from "../images/map-marker.png";
import logout from "../images/logout.svg";
import p2 from "../images/p2.png";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";

import logo from "../images/logo-white.png";
import ClearIcon from "../images/clear-cross.svg";
import pr1 from "../images/p1.png";
import shopimg from "../images/shopimg.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DistributorDetails() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };


  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid item xs="12">
            {/* <Card xs="11" className="custom_card"> */}
           
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  <Box>
                    <Typography component="h2">POS {pos}</Typography>
                  </Box>
                  <Box className="icons">
                    <Box
                      component="img"
                      src={customers}
                      onClick={() => ref.current.log()}
                    />

                    <Typography component="h5" ml={1}>
                      Ramesh Kumar
                    </Typography>
                    <Box component="img" src={logout} ml={2} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Grid container>
                    <Grid item md={12}>
                      <Typography variant="p" className="detail-heading">
                        Sri Srinivasa Merchant
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md={12}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box
                          component="img"
                          className="loc-img"
                          src={mapmarker}
                        />
                        <Typography
                          variant="p"
                          ml={1}
                          className="detail-location"
                        >
                          Hyderabad
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md="12">
                      <Box display={"flex"}>
                        <Box className="tag-badge">
                          <Typography className="tags">
                            Appliances & Electronics
                          </Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">Bakeware</Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">
                            Cookware & Non Stick
                          </Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">
                            Cockery & Cutlery
                          </Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">
                            Flask & Caserole
                          </Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">Gardening</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md="12">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordian-header"
                        >
                          <Typography>Ratings</Typography>
                          {/* <Typography>
                            Status:
                            <Box
                              component="img"
                              src={reddot}
                              className="status-icon"
                            />
                          </Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* <MemberTable
                            data={driverData}
                            columns={ShiftColumns}
                            options={shiftOptions}
                          /> */}
                          <Typography variant="p" className="accordion-text">
                            Mr. Nihalchand wanted to try his luck in the family
                            profession – business. He started small to sell
                            household provisions in 1952, and named it as
                            ‘Karachiwala Stores’ In Visakhapatnam.
                          </Typography>
                          <Typography
                            variant="p"
                            mt={1}
                            className="accordion-text"
                          >
                            His business acumen coupled with hard work started
                            paying off, and it soon became a big hit. The main
                            reason for its success was that he could identify
                            the potential existing in the area of provisions for
                            North Indians and others.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordian-header"
                        >
                          <Typography>Merchant Details</Typography>
                          {/* <Typography>
                            Status:
                            <Box
                              component="img"
                              src={reddot}
                              className="status-icon"
                            />
                          </Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* <MemberTable
                            data={driverData}
                            columns={ShiftColumns}
                            options={shiftOptions}
                          /> */}
                          <Typography variant="p" className="accordion-text">
                            Mr. Nihalchand wanted to try his luck in the family
                            profession – business. He started small to sell
                            household provisions in 1952, and named it as
                            ‘Karachiwala Stores’ In Visakhapatnam.
                          </Typography>
                          <Typography
                            variant="p"
                            mt={1}
                            className="accordion-text"
                          >
                            His business acumen coupled with hard work started
                            paying off, and it soon became a big hit. The main
                            reason for its success was that he could identify
                            the potential existing in the area of provisions for
                            North Indians and others.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Box component="img" className="w-100" src={shopimg} />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item md={2}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item md={12}>
                          <Typography className="m-heading">Filters</Typography>
                        </Grid>
                      </Grid>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Card>
                            <CardContent>
                              <Box display={"flex"}>
                                <Box
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Groceries</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                                <Box
                                  ml={1}
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Masala</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                              </Box>

                              <Box display={"flex"} mt={1}>
                                <Box
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">
                                    Chat Masala
                                  </Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                                <Box
                                  ml={1}
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Chunky</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Typography className="t-heading">Fruits</Typography>
                        </Grid>
                        <Grid item md={12} mt={1}>
                          <Typography className="t-heading">
                            Vegetables
                          </Typography>
                        </Grid>
                        <Grid item md={12} mt={1}>
                          <Typography className="t-heading">
                            Cleaning
                          </Typography>
                        </Grid>
                        <Grid item md={12} mt={1} mb={1}>
                          <Typography className="t-heading">
                            Cold Drinks
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Typography className="t-heading">Brands</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={10}>
                  <TabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box
                              display={"flex"}
                              justifyContent={"space-around"}
                            >
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                href="/procurement/ProductView"
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span" textAlign={"center"}>
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span" textAlign={"center"}>
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span" textAlign={"center"}>
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={3}>
                        <Card className="pos-card">
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            image={pr1}
                            className="card-img"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="p">
                              Everest Cholle Masala
                            </Typography>
                            <Box className="flex flex-between align-center">
                              <Typography
                                component="span"
                                className="text-danger"
                              >
                                Rs. 50/-
                              </Typography>
                              <Typography component="span" className="small">
                                50 gms
                              </Typography>
                            </Box>
                            <Box className="text-center">
                              <Button
                                variant="contained"
                                onClick={() => ref7.current.log()}
                              >
                                <ShoppingCartIcon /> ADD TO Cart
                              </Button>
                            </Box>
                          </CardContent>
                          <CardActions className="card-footer">
                            <Typography component="span">
                              <Box component="img" src={shoppingcounter}></Box>
                              Sri Srinivasa merchants
                            </Typography>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>

            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}

import logo from "./bazar-logo.svg";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useFormik } from "formik";
import LoginSchema from "./Validation";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

export default function Login() {
  const handleOnSubmit = (values) => {
    const fullName = Object.keys(values)
      .map((key) => values[key])
      .join(" ");
    alert(`Hello ${fullName}!`);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleOnSubmit,
  });

  const setInputValue = React.useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm" className="card-size">
        <Box className="Absolute-Box text-center">
          <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
              <Box
                src={logo}
                className="App-logo"
                alt="logo"
                component="img"
                mt={3}
              />
              <CardContent className="card-content text-center">
                <Typography
                  className="clr-primary mt-3 fw-bold "
                  variant="h5"
                  gutterBottom
                >
                  Login
                </Typography>

                <Box className="">
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl className="w-100" variant="standard">
                      <InputLabel shrink htmlFor="username-input">
                        Username
                      </InputLabel>
                      <BootstrapInput
                        fullWidth
                        id="username-input"
                        name="username"
                        className="mb-3"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    <Box Typography="small" className="float-left text-danger">
                      {errors.username && touched.username
                        ? "Please Enter userName"
                        : ""}
                    </Box>
                    <FormControl className="w-100 mt-10" variant="standard">
                      <InputLabel shrink htmlFor="password-input">
                        Password
                      </InputLabel>
                      <BootstrapInput
                        fullWidth
                        id="password-input"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.password && touched.password
                          ? "Please Enter Password"
                          : ""}
                      </Box>
                      <Box className="text-end mt-3">
                        <Box
                          href="/forgotPassword"
                          component="a"
                          className="float-right"
                        >
                          <Typography className="frgt-pswrd" variant="body2">
                            Forgot Password ?
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        variant="contained"
                        className="mt-3 btn-primary py-2 mx-0 btn-height"
                        href="/dashboard"
                      >
                        Login
                      </Button>
                      <Box
                        href="/register"
                        variant="outlined"
                        className="hvr-grow-shadow login-btn btn-primery  _btn-outline py-2 mx-0 mt-3"
                        component="a"
                      >
                        Register
                      </Box>
                      <Box className="mt-3">
                        <Typography className="" variant="body2" gutterBottom>
                          Still facing issue ?<Box component="br" /> Contact{" "}
                          <Box
                            href="tel:1234567890"
                            className="frgt-pswrd"
                            component="a"
                          >
                            {" "}
                            +91-1234567890
                          </Box>{" "}
                          or Email{" "}
                          <Box
                            href="mailto:someone@example.com"
                            className="frgt-pswrd"
                            component="a"
                          >
                            {" "}
                            someone@example.com
                          </Box>
                        </Typography>
                      </Box>
                    </FormControl>
                  </form>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

// export default App;

import React, { useState,useRef, forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import id from '../images/ID.svg';
import customers from '../images/customers.svg';
import mobile from "../images/mobile.svg";
import location from '../images/location.svg';
import status from '../images/order.svg';
import active from '../images/last-active.svg';
import rupee from '../images/rupee.svg';
import mail from '../images/mail.svg';
import MemberTable from '../Customer/MemberTable';
import ViewMoreModalRetailEdit from './ViewMoreModalRetailEdit';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ViewMoreModalRetail = forwardRef((props, ref3) => {
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleOpenEdit = () => setShow(true);
    const handleCloseAdd = () => setAddOpen(false);

    const data=[["12/03/2023","474858","Accepted","₹ 22890","Pending","Cheque","12345678","12/03/2023"]]

    const memeberColumns = [
        { name: "PO Date", options: { filterOptions: { fullWidth: true } } },
        "PO ID",
        "PO Status",
        "PO Total Invoice Amount",
        "Payment Status",
        "Transaction Type",
        "Transaction ID",
        "Transaction Date",
        {
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Select
                        labelId="demo-isolation-label"
                        id="isolation-select"
                        className="select retaile-select"
                        // onChange={handleSelectOxygen}
                        // input={<OutlinedInput />}
                        defaultValue="locations"
                    >
                        <MenuItem value="locations">Channel</MenuItem>
                        <MenuItem>Download</MenuItem>
                        <MenuItem>Email</MenuItem>
                        <MenuItem>Email Accounting</MenuItem>


                    </Select>
                        
                    )
                }
            },
           
        },
        {
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button onClick={() => console.log(value, tableMeta) } className='retail-btn'>
                            Payment
                        </Button>
                        
                    )
                }
            },
           
        }
    ];

    const ref4 = useRef();

    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        // pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref3, () => ({
        log() {
            handleOpen();
        }
    }));

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content retail-content'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            RETAILERS DETAILS
                        </Typography>
                        <Box className="sub-modal-header">
                            <Button variant='outlined' color='error' mt={2} mb={2} onClick={() => ref4.current.log()} className='retail-btn  mr-2'>Edit</Button>
                            <CloseIcon onClick={handleClose} />

                        </Box>
                    </Box>
                    <Divider></Divider>
                    <Box className="modal-body">
                    <Grid container spacing={{ xs: 2, md: 3 }} className='p-15' columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4} >
                            <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                    <Typography component="label">Retailer Name</Typography>
                                    <Typography>VENKATA SAI TRADERS</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                    <Typography component="label">Phone no.</Typography>
                                    <Typography>
                                        +91 9948222051</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Email</Typography>
                                    <Typography>venkat@gmail.com</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Retailer Address</Typography>
                                    <Typography>Kukatpally, Kapila Nagar Colony, Hyderabad</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Retailer Store Type</Typography>
                                    <Typography>Grocery</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">COD</Typography>
                                    <Typography>Allowed</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Credit Limit</Typography>
                                    <Typography>₹ 50,000</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Block Retailer</Typography>
                                    <Typography>Not Blocked</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Join Date</Typography>
                                    <Typography>02/02/2023</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                    <Typography component="label">Last Activity</Typography>
                                    <Typography>10/03/2023</Typography>
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>



                    <Grid container spacing={2} className='p-15'>

                        <Grid item xs={6} className="total_orders">
                            <Box className="box-primary">
                                <Typography component="h2">
                                    0
                                </Typography>
                                <Typography component="p">
                                    Total Orders
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className="customer-report">
                                <Typography component="h2">
                                    45000
                                </Typography>
                                <Typography component="p">
                                    Total Spent
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            BANK INFO
                        </Typography>

                    </Box>
                    <Grid container spacing={{ xs: 2, md: 3 }} className='p-15' columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4} >
                            <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                    <Typography component="label">Bank Name</Typography>
                                    <Typography>State Bank of India</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                    <Typography component="label">Bank Branch</Typography>
                                    <Typography>
                                        Muvvalavani Palem</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">IFSC Code</Typography>
                                    <Typography>SBIN00112233</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Account Number</Typography>
                                    <Typography>1234567890</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Account Holder Name</Typography>
                                    <Typography>Venkat</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Payment Terms</Typography>
                                    <Typography>Cheque</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Paytm Number</Typography>
                                    <Typography>1234567890</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Phonepe Number</Typography>
                                    <Typography>1234567890</Typography>
                                </Box>
                            </Box>
                        </Grid>




                    </Grid>

                    <Divider></Divider>
                    <Grid container className='p-15'>
                        <Typography component="h2">PAYOUT TRANSACTIONS</Typography>
                    </Grid>
                    <Box pl={2} pr={2} className="payout-table ">
                        <MemberTable columns={memeberColumns} options={memberOptions} data={data}  />
                    </Box>


                    </Box>
                    <Divider></Divider>
                    <Box className="p-15">
                        <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' onClick={handleClose}>Submit</Button>
                    </Box>
                </Box>
            </Modal>
         
           <ViewMoreModalRetailEdit ref={ref4} />

        </div>
    );
});

export default ViewMoreModalRetail;
import React, { useImperativeHandle, useRef, forwardRef } from "react";
import { Box, Toolbar, Button, Typography, Grid, Card, CardContent, CardActions, Breadcrumbs, Link, Divider, Select, MenuItem, Switch, FormControl, OutlinedInput } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import Calender from "../Calender/Calender";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import dal1 from '../images/dal1.jpg';
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import walmart from '../images/Walmart.jpg';
import AddLocationModal from './AddLocationModal';
import AddSubSegmentModal from "./AddSubSegmentModal";
import AddSegmentModal from "./AddSegmentModal";
import EditProfileModal from "./EditProfileModal";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
export default function Profile() {

    const [showState, setShowState] = React.useState(false);
    const buttonClickedHandler = () => {
        setShowState((showState) => showState = !showState)
    }
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [alignment, setAlignment] = React.useState('left');

    const ref = React.useRef();
    const ref5 = React.useRef();
    const ref1 = React.useRef();
    const ref3 = React.useRef();

    let navigate = useNavigate();
    const logoutDashboard = () => {
        navigate('/');
    }
    const ref4 = useRef();

    const pcColumns = [
        { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
        "Retailer Name",
        "Total PO Value",
        "Status",
        "Total PO"
    ];
    
    const data = [[<img src={dal1} />, "Coffee", "Biryani's", "Bru coffee", "21 Aug 2021", <IOSSwitch />, "₹ 200.00"],
    [<img src={dal1} />, "Coffee", "Beverages", "Bru coffee", "21 Aug 2021", <IOSSwitch />, "₹ 200.00"]];

    const segmentData = [[<img src={dal1} />, "1866", "Biryani's","percentage", "20 %", <IOSSwitch />],
    [<img src={dal1} />, "1867", "Egg Items", "percentage", "20 %", <IOSSwitch />]];
    const subSegmentData = [[ "1866", "Biryani's","Veg","flat", "$ 15", <IOSSwitch />],
    [ "1867", "Egg Items","Veg", "flat", "$ 15", <IOSSwitch />]];
    
    const locationData = [["Prism Nagar", "Prism Nagar, B Camp, vijayawada, Andhra Pradesh, India", "Prasad", "9963099252", '09:30 AM', "09:30 PM","10:30 AM"],
    ["Salam Nagar", "Salam Nagar, B Camp, Kurnool, Andhra Pradesh, India", "Narayanamma", "9963099252", '10:30 AM', "10:30 PM","10:30 AM"]];
    

    const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

    const pcOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        responsive: "scrollMaxHeight",
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    const memeberColumns = [
        "",
        { name: "ID", options: { filterOptions: { fullWidth: true } } },
        "SEGMENT NAME",
        "ISLE",
        "ROWS",
        "STATUS",
        
    ];

    const segmentColumns = [
        "",
        { name: "ID", options: { filterOptions: { fullWidth: true } } },
        "SEGMENT NAME",
        "ISLE",
        "ROWS",
        "Status",
        {
            
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => ref3.current.log()}>Edit</Button>
                    )
                }
            }
        }
    ];

    const subSegmentColumns = [

        { name: "ID", options: { filterOptions: { fullWidth: true } } },
        "SEGMENT NAME",
        "SUB SEGMENT NAME",
        "ISLE",
        "ROWS",
        "Status",
        {
            
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => ref3.current.log()}>Edit</Button>
                    )
                }
            }
        }
    ];

    const locationColumns = [
        "LOCATION NAME",
         "LOCATION ADDRESS",
        "CONTACT",
        "PHONE",
        "OPENING TIME",
        "CLOSING TIME",
        "OPENING TIME 2",
    ];

    const options = {
        filter: true,
        onFilterChange: (changedColumn, filterList) => {
            console.log(changedColumn, filterList);
        },
        selectableRows: false,
        isRowSelectable: false,
        expandableRowsHeader: false,
        icons: { Add: () => 'Add Row' },
        filterType: "dropdown",
        responsive: "scrollMaxHeight",
        rowsPerPage: 10,
        // expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            console.log(rowData, rowMeta);
            return (
                <React.Fragment>
                    <tr className="inner-row">
                        <td colSpan={6}>
                            <TableContainer>
                                <Table aria-label="simple table">

                                    <TableBody>
                                        {/* {rows.map(row => ( */}
                                        {/* <TableRow key={row.id}> */}
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Taxes:</b> ₹ 485.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Advance:</b> ₹ 321.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Due Amount:</b> ₹ 542.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>State:</b> State</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {/* ))} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>

                </React.Fragment>
            );
        },
        page: 1
    };

    const locationOptions = {
        filter: true,
        onFilterChange: (changedColumn, filterList) => {
            console.log(changedColumn, filterList);
        },
        selectableRows: false,
        isRowSelectable: false,
        expandableRowsHeader: false,
        icons: { Add: () => 'Add Row' },
        filterType: "dropdown",
        responsive: "scrollMaxHeight",
        rowsPerPage: 10,
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            console.log(rowData, rowMeta);
            return (
                <React.Fragment>
                    <tr className="inner-row">
                        <td colSpan={6}>
                            <TableContainer>
                                <Table aria-label="simple table">

                                    <TableBody>
                                        {/* {rows.map(row => ( */}
                                        {/* <TableRow key={row.id}> */}
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Taxes:</b> ₹ 485.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Advance:</b> ₹ 321.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>Due Amount:</b> ₹ 542.00</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography component="p"><b>State:</b> State</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {/* ))} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>

                </React.Fragment>
            );
        },
        page: 1
    };

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <>

            <Box
                component="main"


            >
                <Grid container >
                    <Grid xs="12">
                        <Card xs="11" className="custom_card store-item-card profile-card">
                            <Grid container className="full_content bazaar-breadcrumb">
                                <Grid className="inner-bredcum" xs="12">
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/">
                                            Home
                                        </Link>
                                        <Link underline="hover" color="inherit" href="#">
                                            Product Catalogue
                                        </Link>
                                        <Typography color="text.primary">Add New Item</Typography>
                                    </Breadcrumbs>
                                    <Box className="icons bredcum-icons">

                                        <SearchBar className="search-bar" />
                                        <PersonIcon onClick={() => ref.current.log()} />

                                        <NotificationsIcon />
                                        <LogoutIcon onClick={logoutDashboard} ml={2} />

                                    </Box>

                                </Grid>
                            </Grid>
                            <CardContent className="add_new_item profile-container">
                                <Card>
                                    <CardContent>

                                        <Box className="w-100 flex-between">
                                            <Typography component="h4" className='sub_title' mb={2}>PROFILE</Typography>
                                            <Button variant='outlined' color='error' mt={2} className='retail-btn float-right' onClick={() => ref5.current.log()}>Edit</Button>
                                        </Box>

                                        <Grid container spacing={4} mb={3}>
                                            <Grid item xs={2} sm={4} md={3}>
                                                <Box>

                                                    <Box component="img" src={walmart} className="profile-image" />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} sm={4} md={3} >
                                                <Box className="sub-details">
                                                    <Box mb={2}>
                                                        <Typography component="label">Supplier Name</Typography>
                                                        <Typography component="p">027 Avenue</Typography>
                                                    </Box>
                                                    <Box mb={2}>
                                                        <Typography component="label">Phone Number</Typography>
                                                        <Typography component="p">90528 45869</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography component="label">Primary Email</Typography>
                                                        <Typography component="p">027avenue@gmail.com</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} sm={4} md={3} >
                                                <Box className="sub-details">
                                                    <Box mb={2}>
                                                        <Typography component="label">City</Typography>
                                                        <Typography component="p">Kurnool</Typography>
                                                    </Box>

                                                    <Box>
                                                        <Typography component="label">GST Number</Typography>
                                                        <Typography component="p">6543376543</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} sm={4} md={3} >
                                                <Box className="sub-details">
                                                    <Box mb={2}>
                                                        <Typography component="label">Bussiness Service Name</Typography>
                                                        <Typography component="p">Groceries</Typography>
                                                    </Box>

                                                    <Box>
                                                        <Typography component="label">GST Tax rate</Typography>
                                                        <Typography component="p">18%</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                        </Grid>
                                           <Divider></Divider>
                                           <Grid container spacing={3} mb={3} mt={1}>
                                            
                                            <Grid item xs={2} sm={6} md={4} >
                                                <Box className="sub-details">
                                                    <Box mb={2}>
                                                        <Typography component="label">Bank Name</Typography>
                                                        <Typography component="p">Indian Ovrseas Bank</Typography>
                                                    </Box>
                                                    <Box mb={2}>
                                                        <Typography component="label">Account Holder Name</Typography>
                                                        <Typography component="p">027Avenue</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography component="label">Phone Pay No</Typography>
                                                        <Typography component="p">90528 45869</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} sm={6} md={4} >
                                                <Box className="sub-details">
                                                    <Box mb={2}>
                                                        <Typography component="label">Branch Name / IFSC Code</Typography>
                                                        <Typography component="p">Vuda Branch</Typography>
                                                    </Box>

                                                    <Box mb={2}>
                                                        <Typography component="label">Paytm No</Typography>
                                                        <Typography component="p">90528 45869</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography component="label">UPI Id's</Typography>
                                                        <Typography component="p">90528 45869@ybl</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} sm={6} md={4} >
                                                <Box className="sub-details">
                                                    <Box mb={2}>
                                                        <Typography component="label">Account Number</Typography>
                                                        <Typography component="p">164901000004325</Typography>
                                                    </Box>

                                                    <Box>
                                                        <Typography component="label">Google Pay No</Typography>
                                                        <Typography component="p">90528 45869</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                        </Grid>
                                        <Divider></Divider>
                                        <Grid container>
                                            <Box className="flex-between w-100" mt={2}>
                                                <Typography className="font-bold" component='h4'>SEGMENTS</Typography>
                                                <Button variant='outlined' mt={2} mb={2} className='retail-btn' mr={2} onClick={() => ref1.current.log()}>Add</Button>

                                            </Box>
                                            <Box className="member-table w-100">
                                                <MemberTable columns={segmentColumns} options={options} data={segmentData} />
                                            </Box>
                                        </Grid>
                                        <Grid container>
                                            <Box className="flex-between w-100" mt={2}>
                                                <Typography className="font-bold" component='h4'>SUB SEGMENTS</Typography>
                                                <Button variant='outlined' mt={2} mb={2} className='retail-btn' mr={2} onClick={() => ref3.current.log()}>Add</Button>

                                            </Box>
                                            <Box className="member-table w-100">
                                                <MemberTable columns={subSegmentColumns} options={options} data={subSegmentData} />
                                            </Box>
                                        </Grid>
                                        <Grid container>
                                            <Box className="flex-between w-100" mt={2}>
                                                <Typography className="font-bold" component='h4'>LOCATION DETAILS</Typography>
                                                <Button variant='outlined' mt={2} mb={2} className='retail-btn' mr={2} onClick={() => ref4.current.log()}>Add Location</Button>

                                            </Box>
                                            <Box className="member-table w-100">
                                                <MemberTable columns={locationColumns} options={locationOptions} data={locationData} />
                                            </Box>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
                {/* <Toolbar /> */}
                <AddLocationModal ref={ref4} />
                <AddSubSegmentModal ref={ref3} />
                <AddSegmentModal ref={ref1} />
                  <EditProfileModal ref={ref5} />
            </Box>
        </>
    )
}
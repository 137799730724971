import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import Calender from "../Calender/Calender";
import { OutlinedInput, FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Retailers/ExpandableRowTable";
import Bredcrum from "../Bredcrum";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import PurchaseOrdersViewMoreModal from "./PurchaseOrdersViewMoreModal";

const drawerWidth = 240;

const year = ["Accepted", "Inprogress", "Completed"];

export default function PurchaseOrders() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const purchaseOrdersQuickData = [["ABC", "DEF", "GHI"]];
  const purchaseOrdersQuick = [
    { name: "SKU ID", options: { filterOptions: { fullWidth: true } } },
    "SKU NAME",
    "QTY",
    "PRICE",
  ];
  const memeberColumns = [
    { name: "Customer", options: { filterOptions: { fullWidth: true } } },
    "Date",
    "Supplier",
    "Order ID",
    "Location",
    "Status",
  ];

  const memberOptions = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const salesOptions = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const data = [
    ["Total", "15", "₹ 1,26,000.00", "₹ 35,400.00", "₹ 12000.00", "₹ 7300.00"],
    [
      "Cash",
      "4",
      "₹ 10000.00",
      "₹ 1000.00",
      "₹ 1000.00",
      "₹ 500.00",
      "₹ 500.00",
      "₹ 10000.00",
      "₹ 2000.00",
      "₹ 7000.00",
    ],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Breadcrumbs aria-label="breadcrumb">
                      {/* <Link underline="hover" color="inherit" href="/">
                                                Home
                                            </Link> */}
                      <Link underline="hover" color="inherit" href="#">
                        Procurement
                      </Link>
                      <Typography color="text.primary">
                        Purchase Orders
                      </Typography>
                    </Breadcrumbs>
                    <Box className="icons bredcum-icons">
                      <SearchBar className="search-bar" />
                      <PersonIcon onClick={() => ref.current.log()} />

                      <NotificationsIcon />
                      <LogoutIcon onClick={logoutDashboard} ml={2} />
                    </Box>
                  </Grid>
                  <Divider className="bredcum-hr"></Divider>
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex">
                        <Calender className="calenders" />
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Today
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          This Month
                        </Button>
                      </Box>
                      <Box className="flex">
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Search
                        </Button>

                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">Download</MenuItem>
                          <MenuItem value="visakhapatnam">PDF</MenuItem>
                          <MenuItem value="vijayawada">Excel</MenuItem>

                          <MenuItem value="kurnool">Image</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid
                      xs="12"
                      mt={2}
                      mb={2}
                      className="transport-div salesreport-container"
                    >
                      <Box className="sales-report">
                        <Typography component="h4">3500</Typography>
                        <Typography
                          component="p"
                          className="btn-primary-gradient"
                        >
                          All
                        </Typography>
                      </Box>
                      <Box className="sales-report">
                        <Typography component="h4">1289</Typography>
                        <Typography
                          component="p"
                          className="btn-secondary-gradient"
                        >
                          New
                        </Typography>
                      </Box>
                      <Box className="sales-report">
                        <Typography component="h4">369</Typography>
                        <Typography
                          component="p"
                          className="btn-purple-gradient"
                        >
                          Future
                        </Typography>
                      </Box>
                      <Box className="sales-report">
                        <Typography component="h4">1285</Typography>
                        <Typography
                          component="p"
                          className="btn-success-gradient"
                        >
                          Inprogress
                        </Typography>
                      </Box>
                      <Box className="sales-report">
                        <Typography component="h4">521</Typography>
                        <Typography component="p" className="btn-info-gradient">
                          Completed
                        </Typography>
                      </Box>
                      <Box className="sales-report">
                        <Typography component="h4">36</Typography>
                        <Typography
                          component="p"
                          className="btn-warning-gradient"
                        >
                          Cancelled
                        </Typography>
                      </Box>
                      <Box className="sales-report">
                        <Typography component="h4">36</Typography>
                        <Typography
                          component="p"
                          className="btn-danger-gradient"
                        >
                          Abandoned
                        </Typography>
                      </Box>
                      <Box className="sales-report">
                        <Typography component="h4">36</Typography>
                        <Typography
                          component="p"
                          className="btn-primary-gradient"
                        >
                          Hold
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="member-table m-100">
                          <ExpandableRowTable
                            columns={memeberColumns}
                            options={salesOptions}
                            data={data}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={5}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2} mb={2}>
                          <Grid item md="4">
                            <Typography
                              component="p"
                              className="transport-details-title"
                            >
                              Order ID
                            </Typography>
                            <Typography
                              component="p"
                              className="transport-detail-value"
                            >
                              524261444
                            </Typography>
                          </Grid>
                          <Grid item md="4">
                            <Typography
                              component="p"
                              className="transport-details-title"
                            >
                              Order Status
                            </Typography>
                            <Typography
                              component="p"
                              className="transport-detail-value"
                            >
                              Progress
                            </Typography>
                          </Grid>
                          <Grid item md="4">
                            <Select
                              fullWidth
                              className="select select-height"
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="Cancelled"
                            >
                              <MenuItem>year</MenuItem>
                              {year.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          {/* <Grid item md="3" textAlign={"right"}>
                            <Button
                              variant="outlined"
                              className="btn-primary-outline"
                              onClick={() => ref3.current.log()}
                            >
                              View More
                            </Button>
                          </Grid> */}
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                          <Grid item md="4">
                            <Typography
                              component="p"
                              className="transport-details-title"
                            >
                              Date Created
                            </Typography>
                            <Typography
                              component="p"
                              className="transport-detail-value"
                            >
                              DD-MM-YYYY
                            </Typography>
                          </Grid>
                          <Grid item md="4">
                            <Typography
                              component="p"
                              className="transport-details-title"
                            >
                              Due Date
                            </Typography>
                            <Typography
                              component="p"
                              className="transport-detail-value"
                            >
                              DD-MM-YYYY
                            </Typography>
                          </Grid>
                          <Grid item md="4" textAlign={"right"}>
                            <Button
                              variant="outlined"
                              className="btn-primary-outline"
                              onClick={() => ref3.current.log()}
                            >
                              View More
                            </Button>
                          </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Grid container mt={2} mb={2}>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={custicon}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Supplier
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                Srinivasa Kirana Store
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={mobile}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Mobile
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                +1 3864733440
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container mt={2} mb={2}>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={mail}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Email
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                lokesh.bezavada@gmail.com
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={location}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Location
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                Kakinada
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Grid container mt={2} mb={2}>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={driver}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Delivery
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                Ravi P
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={pickup}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                On the way
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container mt={2} mb={2}>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={mobile}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Mobile
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                +91 90528 45869
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container mt={2} mb={2}>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={dropoff}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Payment Due Date
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                DD-MM-YYYY
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            md="6"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Box
                              component="img"
                              src={pickup}
                              className="T-icon"
                            />
                            <Box sx={{ marginLeft: "6px" }}>
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Payment Status
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                Suresh
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Grid container spacing={2} mt={0} mb={2}>
                          <Grid item md={12}>
                            <MemberTable
                              columns={purchaseOrdersQuick}
                              options={memberOptions}
                              data={purchaseOrdersQuickData}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <PurchaseOrdersViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}

import React, { useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import rupee from "../images/rupee.svg";
import mail from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import test from "../images/default.jpg";
import custicon from "../images/cust-icon.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FleetViewMoreModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const viColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Inspection",
    "Status",
    "Comments",
  ];

  const viOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content">
          <Box className="modal-header p-15">
            <Typography
              id="modal-modal-title"
              variant="h5"
              className="m-heading"
              component="h5"
            >
              Fleet Information
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12} paddingRight={2}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography className="t-heading">Fleet Information</Typography>
                <Button variant="outlined" className="btn-primary-outline">
                  Edit
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Box component="img" src={test} />
            </Grid>
            <Grid item md={4} alignItems={"center"}>
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Vehicle ID
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  #1
                </Typography>
              </Box>
              <Box sx={{ marginLeft: "6px", marginTop: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Model
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  Transit Connect
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} alignContent={"center"}>
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Make
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  Ford
                </Typography>
              </Box>
              <Box sx={{ marginLeft: "6px", marginTop: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Year
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  2015
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Typography variant="h4" className="t-heading">
                Regualtory
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} mb={2}>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
              <Box component="img" src={custicon} className="T-icon" />
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Proof of Insurance
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  Insurance Document
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
              <Box component="img" src={custicon} className="T-icon" />
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Registration Expiration
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  451078
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
              <Box component="img" src={custicon} className="T-icon" />
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Insured By
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  Hudson Insurance Company
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
              <Box component="img" src={custicon} className="T-icon" />
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Policy ID
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  #
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
              <Box component="img" src={custicon} className="T-icon" />
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Expiration
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  45107
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
              <Box component="img" src={custicon} className="T-icon" />
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Proof of Insurance
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  Insurance Document
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
              <Box component="img" src={custicon} className="T-icon" />
              <Box sx={{ marginLeft: "6px" }}>
                <Typography component="p" className="transport-details-title">
                  Insured By
                </Typography>
                <Typography component="p" className="transport-detail-value">
                  Hudson Insurance Company
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Typography className="t-heading">Vehicle Inspections</Typography>
            </Grid>
            <Grid item md={12}>
              <MemberTable columns={viColumns} options={viOptions} />
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Typography className="t-heading">Maintenance Log</Typography>
            </Grid>
            <Grid item md={12}>
              <MemberTable columns={viColumns} options={viOptions} />
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Typography className="t-heading">Utilization Log</Typography>
            </Grid>
            <Grid item md={12}>
              <MemberTable columns={viColumns} options={viOptions} />
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={2}
              className="save-btn"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-user-modal" sx={style}>
          <Box className="add-user-div p-15">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New user
            </Typography>
            <CloseIcon onClick={handleCloseAdd} />
          </Box>
          <Divider></Divider>
          <Grid
            className="inputfield-container"
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
          >
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">First Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Phone No</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Email</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Password</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Button
            variant="outlined"
            color="error"
            mt={2}
            mb={2}
            className="save-btn p-15"
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

export default FleetViewMoreModal;

import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { OutlinedInput, FormControl } from "@mui/material";
import MemberTable from "../Customer/MemberTable";
import Bredcrum from "../Bredcrum";
// import { useDemoData } from "@mui/x-data-grid-generator";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const year = ["2023", "2022", "2021", "2020"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "customerId",
    headerName: "Customer ID",
    width: 200,
    editable: true,
  },
  {
    field: "customerName",
    headerName: "Customer Name",
    width: 200,
    editable: true,
  },
  {
    field: "phoneNumber",
    headerName: "Mobile Number",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    type: "number",
    width: 200,
    editable: true,
  },
];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const rows = [
  {
    id: 1,
    customerId: 1,
    customerName: "Snow",
    email: "Jon",
    phoneNumber: 8121542167,
  },
  {
    id: 2,
    customerId: 2,
    customerName: "Lannister",
    email: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    customerId: 3,
    customerName: "Lannister",
    email: "Jaime",
    phoneNumber: 8121542167,
  },
  {
    id: 4,
    customerId: 4,
    customerName: "Stark",
    email: "Arya",
    phoneNumber: 8121542167,
  },
  {
    id: 5,
    customerId: 5,
    customerName: "Targaryen",
    email: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    customerId: 6,
    customerName: "Melisandre",
    email: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    customerId: 7,
    customerName: "Clifford",
    email: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    customerId: 8,
    customerName: "Frances",
    email: "Rossini",
    phoneNumber: 8121542167,
  },
  {
    id: 9,
    customerId: 9,
    customerName: "Roxie",
    email: "Harvey",
    phoneNumber: 8121542167,
  },
];

const messagesColumns = [
  { name: "Customer ID", options: { filterOptions: { fullWidth: true } } },
  "Customer Name",
  "Phone",
  "Email",
];

const messagesdata = [
  ["1", "AccessToCare", "303-495-1667", "Accesstoclaimdenver@EMSC.net"],
  ["1", "AccessToCare", "303-495-1667", "Accesstoclaimdenver@EMSC.net"],
  ["1", "AccessToCare", "303-495-1667", "Accesstoclaimdenver@EMSC.net"],
  ["1", "AccessToCare", "303-495-1667", "Accesstoclaimdenver@EMSC.net"],
  ["1", "AccessToCare", "303-495-1667", "Accesstoclaimdenver@EMSC.net"],
  ["1", "AccessToCare", "303-495-1667", "Accesstoclaimdenver@EMSC.net"],
  ["1", "AccessToCare", "303-495-1667", "Accesstoclaimdenver@EMSC.net"],
  ["1", "AccessToCare", "303-495-1667", "Accesstoclaimdenver@EMSC.net"],
];

const messagesOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Messages() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                 
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Box>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Locations</MenuItem>
                          <MenuItem>Debray</MenuItem>
                        </Select>
                      </Box>

                      <Box>
                        <Box>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                          >
                            <Tab
                              className="toggle-tabs"
                              label="Customers"
                              {...a11yProps(0)}
                            />
                            <Tab label="Team" {...a11yProps(1)} />
                          </Tabs>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <TabPanel className="tab-content" value={value} index={0}>
                    <Grid container mt={3} className="pickers">
                      <Grid xs="12" mt={2} mb={2}>
                        <Box className="member-datepickers">
                          <Box>
                            <Box className="border-right">
                              <Typography>JOIN DATE</Typography>
                              <Box className="sub_date">
                                <FormControl>
                                  <Typography component="label">
                                    From
                                  </Typography>
                                  <OutlinedInput
                                    type="date"
                                    placeholder="Please enter text"
                                  />
                                </FormControl>
                                <FormControl>
                                  <Typography component="label">To</Typography>
                                  <OutlinedInput
                                    type="date"
                                    placeholder="Please enter text"
                                  />
                                </FormControl>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="border-right">
                            <Typography>LAST ACTIVE</Typography>
                            <Box className="sub_date">
                              <FormControl>
                                <Typography component="label">From</Typography>
                                <OutlinedInput type="date" />
                              </FormControl>
                              <FormControl>
                                <Typography component="label">To</Typography>
                                <OutlinedInput type="date" />
                              </FormControl>
                            </Box>
                          </Box>
                          <Box className="border-right">
                            <Typography>BILLINGS</Typography>
                            <Box className="sub_date">
                              <FormControl>
                                <Typography component="label">From</Typography>
                                <OutlinedInput type="number" />
                              </FormControl>
                              <FormControl>
                                <Typography component="label">To</Typography>
                                <OutlinedInput type="number" />
                              </FormControl>
                            </Box>
                          </Box>
                          <Box>
                            <Typography>TRANSPORTS</Typography>
                            <Box className="sub_date">
                              <FormControl>
                                <Typography component="label">From</Typography>
                                <OutlinedInput type="number" />
                              </FormControl>
                              <FormControl>
                                <Typography component="label">To</Typography>
                                <OutlinedInput type="number" />
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs="12" md="12" mt={3}>
                        <Card md="12">
                          <CardContent>
                            <Grid container>
                              <Grid item md="12">
                                <Typography mb={2} className="t-heading">
                                  Customers
                                </Typography>
                              </Grid>
                            </Grid>
                            <Box sx={{ height: 400, width: "100%" }}>
                              <MemberTable
                                columns={messagesColumns}
                                options={messagesOptions}
                                data={messagesdata}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel className="tab-content" value={value} index={1}>
                    <Grid container spacing={2}>
                      <Grid item xs="12" md="12" mt={3}>
                        <Card md="12">
                          <CardContent>
                            <Grid container>
                              <Grid item md="12">
                                <Typography mb={2} className="t-heading">
                                  Team
                                </Typography>
                              </Grid>
                            </Grid>
                            <Box sx={{ height: 400, width: "100%" }}>
                              <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                  pagination: {
                                    paginationModel: {
                                      pageSize: 5,
                                    },
                                  },
                                }}
                                pageSizeOptions={[5]}
                                checkboxSelection
                                disableRowSelectionOnClick
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                  },
                                }}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput, TextareaAutosize, FormControlLabel, Switch, FormGroup, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import MemberTable from '../Customer/MemberTable';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const EditPromoModal = forwardRef((props, ref2, ref4) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);


    const selectColumns = [
        { name: "Name", options: { filterOptions: { fullWidth: true } } },
        "Contact No",
        "Location",
        {
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                   <Checkbox />
                  );
                }
              }
            }
    ];
    const selectData = [["patnaik", "9513574862", "Kakinada"],
    ["Neeha", "7084327025", "Vizianagram"],
    ["naveen", "8624791305", "Visakhapatnam"]];

    const memeberColumns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        "Last Name",
        "Email",
        "Password"
    ];
    const pcOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };
    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        // pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref2, () => ({
        log() {
            handleOpen();
        }
    }));

    useImperativeHandle(ref4, () => ({
        log() {
            handleOpen();
        }
    }));

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content add-product '>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            ADD NEW CUSTOMER
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <Divider></Divider>

                    <Grid container mt={4} className="pickers" spacing={2}>
                        <Grid item xs="12" md={12} className='pl-0'>
                            <Box className="member-datepickers custom-date-pickers w-100">

                                <Box className="border-right">
                                    <Typography>JOIN DATE</Typography>
                                    <Box className="sub_date">

                                        <FormControl>
                                            <Typography component="label">From Date</Typography>
                                            <OutlinedInput type="date" placeholder="Please enter text" />
                                            {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}

                                        </FormControl>
                                        <FormControl>
                                            <Typography component="label">To Date</Typography>
                                            <OutlinedInput type="date" placeholder="Please enter text" />

                                        </FormControl>

                                    </Box>
                                </Box>


                                <Box className="border-right" mt={2}>
                                    <Typography>TOTAL SPEND</Typography>
                                    <Box className="sub_date">

                                        <FormControl>
                                            <Typography component="label">Min</Typography>
                                            <OutlinedInput placeholder="$" />

                                        </FormControl>
                                        <FormControl>
                                            <Typography component="label">Max</Typography>
                                            <OutlinedInput placeholder="$" />

                                        </FormControl>

                                    </Box>
                                </Box>
                                <Box className="border-right" mt={2}>
                                    <Typography>LAST ACTIVE</Typography>
                                    <Box className="sub_date">

                                        <FormControl>
                                            <Typography component="label">From Date</Typography>
                                            <OutlinedInput type="date" />

                                        </FormControl>
                                        <FormControl>
                                            <Typography component="label">To Date</Typography>
                                            <OutlinedInput type="date" />

                                        </FormControl>

                                    </Box>
                                </Box>

                                <Box className="border-right" mt={2}>
                                    <Typography>TOTAL ORDERS</Typography>
                                    <Box className="sub_date">

                                        <FormControl>
                                            <Typography component="label">Min</Typography>
                                            <OutlinedInput placeholder="$" />

                                        </FormControl>
                                        <FormControl>
                                            <Typography component="label">Max</Typography>
                                            <OutlinedInput placeholder="$" />

                                        </FormControl>

                                    </Box>
                                </Box>

                            </Box>

                        </Grid>
                    </Grid>
                    <Grid container className='p-15' mt={2}>
                        <FormControlLabel
                            control={
                                <Checkbox  name="select all" />
                            }
                            label="Select All"
                        />
                        <Grid item xs={12} md={12}>
                            <Box className="w-100 member-table">
                                <MemberTable columns={selectColumns} options={pcOptions} data={selectData} />
                            </Box>
                        </Grid>

                    </Grid>

                    <Box className="p-15" mb={2}>
                        <Button variant='outlined' color='error'  className='retail-btn float-right' onClick={handleClose}>Submit</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
});

export default EditPromoModal;
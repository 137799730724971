import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import LocationModal from "./LocationModal";
import Bredcrum from "../Bredcrum";

export default function DeliveryFeeEngine() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref4 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const eiColumns = ["ID", "Location", "Name"];
  const eiData = [
    ["#963258", "Srikakulam", "Ramya"],
    ["#963258", "Srikakulam", "Ramya"],
    ["#856214", "Vizianagaram", "Srilekha"],
  ];
  const mlColumns = [
    "DATE",
    "TYPE",
    "TASK",
    "LIFE OF REPAIR",
    "COST",
    "COMMENTS",
  ];
  const ulData = [
    ["11/12/2021", "Hialeah", "09:00", "19:00", "12 Equipment", "12 Equipment"],
  ];
  const ulColumns = [
    "DATE",
    "EMPLOYEE",
    "START TIME",
    "END TIME",
    "PRE TRIP CHECK",
    "POST TRIP CHECK",
  ];
  const mlData = [
    [
      "11/12/2021",
      "Brakes",
      "Fail",
      "HandBrake Fail",
      "6 months",
      "$ 120",
      "Hand Brake Issue",
    ],
  ];
  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const chargeColumns = [
    { name: "From", options: { filterOptions: { fullWidth: true } } },
    "To",
  ];

  const chargeOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const equipmentoptions = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: true,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,

    page: 1,
  };
  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: false,
  };

  const data = [
    ["Coffee", "Beverages", "Bru coffee", "21 Aug 2021", "86", "₹ 200.00"],
    ["Coffee", "Beverages", "Bru coffee", "21 Aug 2021", "86", "₹ 200.00"],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />

              <CardContent className="bg-white">
                <Box
                  mb={2}
                  mt={2}
                  className="filters box-shadow sm-selects bg-light p-15"
                >
                  <Box className="w-100">
                    <Select
                      labelId="demo-isolation-label"
                      id="isolation-select"
                      className="select select-equipment float-right"
                      // onChange={handleSelectOxygen}
                      // input={<OutlinedInput />}
                      defaultValue="Percentage"
                    >
                      <MenuItem value="Percentage">Select City</MenuItem>
                      <MenuItem value="Visakhapatnam">Visakhapatnam</MenuItem>
                      <MenuItem value="Vijayawada">Vijayawada</MenuItem>
                      <MenuItem value="Kurnool">Kurnool</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            2 WHEELER DELIVERY DETAILS
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Base Delivery Charge
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Delivery Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Base Pick & drop Delivery Charge
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Delivery Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Default Kms
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Default Kms"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Additional Charge Per Km
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Charge Per Km"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                StopOver Charge
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="StopOver Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">GST</Typography>
                              <OutlinedInput value="" placeholder="Gst" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Box>
                              <Typography className="t-heading">
                                Base Delivery Charge
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">From</Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Min Base Delivery Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Max Base Delivery Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">Price</Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Base Delivery Price"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} textAlign={"center"}>
                            <Button className="btn-outline-primary">Add</Button>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <MemberTable
                              columns={chargeColumns}
                              options={chargeOptions}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            3 WHEELER DELIVERY DETAILS
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Base Delivery Charge
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Delivery Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Base Pick & drop Delivery Charge
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Delivery Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Default Kms
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Default Kms"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Additional Charge Per Km
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Charge Per Km"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                StopOver Charge
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="StopOver Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">GST</Typography>
                              <OutlinedInput value="" placeholder="Gst" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Box>
                              <Typography className="t-heading">
                                Base Delivery Charge
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">From</Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Min Base Delivery Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Max Base Delivery Charge"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">Price</Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Base Delivery Price"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} textAlign={"center"}>
                            <Button className="btn-outline-primary">Add</Button>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <MemberTable
                              columns={chargeColumns}
                              options={chargeOptions}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={12}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            FEES ON TOTAL AMOUNTS
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Merchant ConvenienceFee
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="ConvenienceFee"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Pickup & drop ConvenienceFee
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="ConvenienceFee"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Late Night Hours
                              </Typography>
                              <OutlinedInput
                                value=""
                                type="time"
                                placeholder="Hours"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Late Night Fee
                              </Typography>
                              <OutlinedInput value="" placeholder="Fee" />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            MIN ORDER CHARGES
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Minimum Order Amount
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Order Amount"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Minimum Order Fee
                              </Typography>
                              <OutlinedInput value="" placeholder="Order Fee" />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            EASH CASH AND COD LIMIT
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Cod Order Limit for Customer
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Code Order Limit"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                EaseCash Percentage
                              </Typography>
                              <OutlinedInput
                                value=""
                                placeholder="Percentage"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                <Box className="w-100 text-center" mt={2}>
                  <Button
                    variant="outlined"
                    mt={2}
                    mb={2}
                    className="retail-btn bg-white"
                    mr={2}
                    onClick={() => ref3.current.log()}
                  >
                    Submit
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <LocationModal ref={ref4} />
      </Box>
    </>
  );
}

import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { OutlinedInput } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Bredcrum from "../Bredcrum";
// import { useDemoData } from "@mui/x-data-grid-generator";

const year = ["2023", "2022", "2021", "2020"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "orderDate",
    headerName: "Order Date",
    width: 200,
    editable: true,
  },
  {
    field: "orderId",
    headerName: "Order Id",
    width: 200,
    editable: true,
  },
  {
    field: "customerName",
    headerName: "Customer Name",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "customerPhone",
    headerName: "Customer Phone",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "refundAmount",
    headerName: "Refund Amount",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "refundDate",
    headerName: "Refund Date",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "refundTxnNo",
    headerName: "Refund Txn No",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "refundStatus",
    headerName: "Refund Status",
    type: "number",
    width: 150,
    editable: true,
  },
];

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const rows = [
  { id: 1, driverCity: "Snow", driverName: "Jon", phoneNumber: 8121542167 },
  {
    id: 2,
    driverCity: "Lannister",
    driverName: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    driverCity: "Lannister",
    driverName: "Jaime",
    phoneNumber: 8121542167,
  },
  { id: 4, driverCity: "Stark", driverName: "Arya", phoneNumber: 8121542167 },
  {
    id: 5,
    driverCity: "Targaryen",
    driverName: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    driverCity: "Melisandre",
    driverName: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    driverCity: "Clifford",
    driverName: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    driverCity: "Frances",
    driverName: "Rossini",
    phoneNumber: 8121542167,
  },
  { id: 9, driverCity: "Roxie", driverName: "Harvey", phoneNumber: 8121542167 },
];

const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Phonetransport() {
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
            <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                   
                  </Grid>
                  <Divider className="bredcum-hr"></Divider>
                  <Grid
                    container
                    mt={2}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Typography variant="h5">Vehicle Bookings</Typography>
                    </Box>
                    <Box>
                      <Button
                        variant="outlined"
                        className="btn-primary-outline"
                      >
                        Clone Transport
                      </Button>
                    </Box>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs="12" md="12" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container spacing={2} mb={3}>
                            <Grid item md="6">
                              <Box>
                                <Grid container mb={2}>
                                  <Grid item md="12">
                                    <Typography
                                      className="t-heading"
                                      variant="h1"
                                    >
                                      Caller Details
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container spacing={2} mt={1}>
                                  <Grid item md="6">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        First Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md="6">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Last Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1}>
                                  <Grid item md="6">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Email
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md="6">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Phone Number
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Company Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Address
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item md="6">
                              <Box>
                                <Grid container mb={2}>
                                  <Grid item md="12">
                                    <Typography
                                      className="t-heading"
                                      variant="h1"
                                    >
                                      Bill To
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container spacing={2} mt={1}>
                                  <Grid item md="6">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        First Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md="6">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Last Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1}>
                                  <Grid item md="6">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Email
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md="6">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Phone Number
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Company Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Address
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container spacing={2} mt={1}>
                            <Grid item md="6">
                              <Grid container spacing={2} mt={1}>
                                <Grid item md="12">
                                  <Typography className="t-heading">
                                    Time of Booking
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} mt={1}>
                                <Grid item md="6">
                                  <FormControl fullWidth>
                                    <Typography component="label">
                                      Onwards Date
                                    </Typography>
                                    <OutlinedInput
                                      type="date"
                                      placeholder="Please enter text"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item md="6">
                                  <FormControl fullWidth>
                                    <Typography component="label">
                                      Onwards Time
                                    </Typography>
                                    <OutlinedInput
                                      type="time"
                                      placeholder="Please enter text"
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} mt={1}>
                                <Grid item md="6">
                                  <FormControl className="w-100" size="small">
                                    <InputLabel id="city-select-label">
                                      Trip
                                    </InputLabel>
                                    <Select
                                      fullWidth
                                      className="rounded-pill"
                                      labelId="city-select-label"
                                      id="city-select"
                                      label="City"
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      <MenuItem value={10}>One Way</MenuItem>
                                      <MenuItem value={20}>Round Trip</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md="6">
                                  <FormControl className="w-100" size="small">
                                    <InputLabel id="city-select-label">
                                      Isolation
                                    </InputLabel>
                                    <Select
                                      fullWidth
                                      className="rounded-pill"
                                      labelId="city-select-label"
                                      id="city-select"
                                      label="City"
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      <MenuItem value={10}>
                                        No Isolation
                                      </MenuItem>
                                      <MenuItem value={20}>
                                        Droplet Isolation
                                      </MenuItem>
                                      <MenuItem value={30}>
                                        Contact Isolation
                                      </MenuItem>
                                      <MenuItem value={40}>Covid</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2} mt={1}>
                                <Grid item md="12">
                                  <Typography className="t-heading">
                                    Transport Services
                                  </Typography>
                                </Grid>
                                <Grid item md="12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        value="ambulatory"
                                        control={<Radio />}
                                        label="Ambulatory (Able to Walk)"
                                      />
                                      <FormControlLabel
                                        value="wheelchair"
                                        control={<Radio />}
                                        label="Wheelchair"
                                      />
                                      <FormControlLabel
                                        value="stretcher"
                                        control={<Radio />}
                                        label="Stretcher"
                                      />
                                      <FormControlLabel
                                        value="bakeract"
                                        control={<Radio />}
                                        label="Baker Act"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} mt={1}>
                                <Grid item md="12">
                                  <Typography className="t-heading">
                                    Transport Add-Ons
                                  </Typography>
                                </Grid>
                                <Grid item md="12">
                                  <FormGroup className="">
                                    <FormControlLabel
                                      control={<Checkbox defaultChecked />}
                                      label="Oxygen"
                                    />
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label="CNA (Long Distance Only)"
                                    />
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label="Auxiliary Power Pack"
                                    />
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label="Out of Area"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1}>
                            <Grid item md="12">
                              <Typography className="t-heading">
                                Passenger Details
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1}>
                            <Grid item md="4">
                              <FormControl
                                fullWidth
                                className="rounded-pill w-100"
                                size="small"
                              >
                                <InputLabel
                                  shrink
                                  htmlFor="confirm-password"
                                  sx={{ mt: 1, mb: 0.5 }}
                                >
                                  Patient ID
                                </InputLabel>
                                <BootstrapInput
                                  fullWidth
                                  id="confirm-password"
                                  className="mb-3"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md="4">
                              <FormControl
                                fullWidth
                                className="rounded-pill w-100"
                                size="small"
                              >
                                <InputLabel
                                  shrink
                                  htmlFor="confirm-password"
                                  sx={{ mt: 1, mb: 0.5 }}
                                >
                                  First Name
                                </InputLabel>
                                <BootstrapInput
                                  fullWidth
                                  id="confirm-password"
                                  className="mb-3"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md="4">
                              <FormControl
                                fullWidth
                                className="rounded-pill w-100"
                                size="small"
                              >
                                <InputLabel
                                  shrink
                                  htmlFor="confirm-password"
                                  sx={{ mt: 1, mb: 0.5 }}
                                >
                                  Last Name
                                </InputLabel>
                                <BootstrapInput
                                  fullWidth
                                  id="confirm-password"
                                  className="mb-3"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1} mb={3}>
                            <Grid item md="4">
                              <FormControl
                                fullWidth
                                className="rounded-pill w-100"
                                size="small"
                              >
                                <InputLabel
                                  shrink
                                  htmlFor="confirm-password"
                                  sx={{ mt: 1, mb: 0.5 }}
                                >
                                  Phone Number
                                </InputLabel>
                                <BootstrapInput
                                  fullWidth
                                  id="confirm-password"
                                  className="mb-3"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md="4">
                              <FormControl
                                fullWidth
                                className="rounded-pill w-100"
                                size="small"
                              >
                                <InputLabel
                                  shrink
                                  htmlFor="confirm-password"
                                  sx={{ mt: 1, mb: 0.5 }}
                                >
                                  Passenger Weight
                                </InputLabel>
                                <BootstrapInput
                                  fullWidth
                                  id="confirm-password"
                                  className="mb-3"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md="4">
                              <FormControl
                                fullWidth
                                className="rounded-pill w-100"
                                size="small"
                              >
                                <InputLabel
                                  shrink
                                  htmlFor="confirm-password"
                                  sx={{ mt: 1, mb: 0.5 }}
                                >
                                  Baggage
                                </InputLabel>
                                <BootstrapInput
                                  fullWidth
                                  id="confirm-password"
                                  className="mb-3"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container spacing={2} mt={2} mb={3}>
                            <Grid item md="6" paddingRight={2} borderRight={1}>
                              <Box>
                                <Grid container mb={2}>
                                  <Grid item md="12">
                                    <Typography
                                      className="t-heading"
                                      variant="h1"
                                    >
                                      Pickup Details
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container spacing={2} mt={0}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Address
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Pickup Contact Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Pickup Contact Number
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Company Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item md="6">
                              <Box>
                                <Grid container mb={2}>
                                  <Grid item md="12">
                                    <Typography
                                      className="t-heading"
                                      variant="h1"
                                    >
                                      Dropoff Details
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container spacing={2} mt={0}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Address
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Pickup Contact Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Pickup Contact Number
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid container mt={1}>
                                  <Grid item md="12">
                                    <FormControl
                                      fullWidth
                                      className="rounded-pill w-100"
                                      size="small"
                                    >
                                      <InputLabel
                                        shrink
                                        htmlFor="confirm-password"
                                        sx={{ mt: 1, mb: 0.5 }}
                                      >
                                        Company Name
                                      </InputLabel>
                                      <BootstrapInput
                                        fullWidth
                                        id="confirm-password"
                                        className="mb-3"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Bredcrum from "../Bredcrum";
import MemberTable from "../Customer/MemberTable";
// import { useDemoData } from "@mui/x-data-grid-generator";

const year = ["2023", "2022", "2021", "2020"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const refundsColumns = [
  { name: "Order Date", options: { filterOptions: { fullWidth: true } } },
  "Order ID",
  "Customer Name",
  "Customer Phone",
  "Refund Amount",
  "Refund Date",
  "Refund Txn No",
  "Refund Status",
];

const refundsdata = [
  [
    "09 Nov 2022",
    "45967",
    "Advent Health Hospice",
    "386-671-2138",
    "10.00",
    "2022-12-22 04:38:36",
  ],
  [
    "09 Nov 2022",
    "45967",
    "Advent Health Hospice",
    "386-671-2138",
    "10.00",
    "2022-12-22 04:38:36",
  ],
  [
    "09 Nov 2022",
    "45967",
    "Advent Health Hospice",
    "386-671-2138",
    "10.00",
    "2022-12-22 04:38:36",
  ],
  [
    "09 Nov 2022",
    "45967",
    "Advent Health Hospice",
    "386-671-2138",
    "10.00",
    "2022-12-22 04:38:36",
  ],
  [
    "09 Nov 2022",
    "45967",
    "Advent Health Hospice",
    "386-671-2138",
    "10.00",
    "2022-12-22 04:38:36",
  ],
  [
    "09 Nov 2022",
    "45967",
    "Advent Health Hospice",
    "386-671-2138",
    "10.00",
    "2022-12-22 04:38:36",
  ],
  [
    "09 Nov 2022",
    "45967",
    "Advent Health Hospice",
    "386-671-2138",
    "10.00",
    "2022-12-22 04:38:36",
  ],
  [
    "09 Nov 2022",
    "45967",
    "Advent Health Hospice",
    "386-671-2138",
    "10.00",
    "2022-12-22 04:38:36",
  ],
];

const refundsOptions = {
  search: false,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "orderDate",
    headerName: "Order Date",
    width: 200,
    editable: true,
  },
  {
    field: "orderId",
    headerName: "Order Id",
    width: 200,
    editable: true,
  },
  {
    field: "customerName",
    headerName: "Customer Name",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "customerPhone",
    headerName: "Customer Phone",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "refundAmount",
    headerName: "Refund Amount",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "refundDate",
    headerName: "Refund Date",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "refundTxnNo",
    headerName: "Refund Txn No",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "refundStatus",
    headerName: "Refund Status",
    type: "number",
    width: 150,
    editable: true,
  },
];

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const rows = [
  { id: 1, driverCity: "Snow", driverName: "Jon", phoneNumber: 8121542167 },
  {
    id: 2,
    driverCity: "Lannister",
    driverName: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    driverCity: "Lannister",
    driverName: "Jaime",
    phoneNumber: 8121542167,
  },
  { id: 4, driverCity: "Stark", driverName: "Arya", phoneNumber: 8121542167 },
  {
    id: 5,
    driverCity: "Targaryen",
    driverName: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    driverCity: "Melisandre",
    driverName: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    driverCity: "Clifford",
    driverName: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    driverCity: "Frances",
    driverName: "Rossini",
    phoneNumber: 8121542167,
  },
  { id: 9, driverCity: "Roxie", driverName: "Harvey", phoneNumber: 8121542167 },
];

const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Refunds() {
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
            <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  
                  
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Select
                        labelId="demo-isolation-label"
                        id="isolation-select"
                        className="select"
                        defaultValue="locations"
                      >
                        <MenuItem value="locations">All Locations</MenuItem>
                        <MenuItem>Debray</MenuItem>
                      </Select>
                      <Box className="select_div">
                        <div className="select_div">
                          <Button variant="outlined" className="today">
                            Today
                          </Button>
                          <Button variant="outlined" className="today">
                            Yesterday
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs="12" md="12" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container>
                            <Grid item md="12">
                              <Typography mb={2} className="t-heading">
                                Transport Refunds
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box sx={{ height: 400, width: "100%" }}>
                            <MemberTable
                              columns={refundsColumns}
                              options={refundsOptions}
                              data={refundsdata}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

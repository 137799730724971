import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput, TextareaAutosize, FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MemberTable from '../Customer/MemberTable';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const AddNewModal = forwardRef((props, ref3, ref4) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const memeberColumns = [
        "DATE",
        "DRIVER",
        "START TIME",
        "END TIME",
        "PRE TRIP CHECK",
        "POST TRIP CHECK"
    ];

    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref3, () => ({
        log() {
            handleOpen();
        }
    }));

    useImperativeHandle(ref4, () => ({
        log() {
            handleOpen();
        }
    }));

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content new-entry'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            ADD EQUIPMENT INFORMATION
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <Divider></Divider>
                    <Grid container spacing={3} className='p-15' >
                        <Grid item xs={2} sm={3} md={4} >
                            <Box className="information-div">

                                <FormControl className="add-image">

                                    <Box component='label' for="files" class="btn">Add Image</Box>
                                    <Box component='input' id="files" style={{ visibility: "hidden" }} type="file" />

                                    {/* <OutlinedInput id="files" type="file" name="add_image[]" className="add-image" /> */}
                                </FormControl>


                            </Box>
                            <Box component='input' id="files" type="file" />
                        </Grid>
                        <Grid item xs={2} sm={3} md={4}>
                            <Box className="">
                                <Box>
                                    <FormControl className='w-100' >
                                        <Typography component="label">Equipment ID</Typography>
                                        <OutlinedInput value='' />
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <FormControl className='w-100'>
                                        <Typography component="label">Equipment Model</Typography>
                                        <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Vehicle Type</MenuItem>
                                            <MenuItem value="car">car</MenuItem>
                                            <MenuItem value="van">Van</MenuItem>



                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <FormControl className='w-100' mt={2}>
                                        <Typography component="label">Equipment Number</Typography>
                                        <OutlinedInput value='' />
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <FormControl className='w-100' mt={2}>
                                        <Typography component="label">Equipment Colour</Typography>
                                        <OutlinedInput value='' />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={3} md={4}>
                            <Box className="">
                                <Box>
                                    <FormControl className='w-100' >
                                        <Typography component="label">City</Typography>
                                        <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Select City</MenuItem>
                                            <MenuItem value="car">Jacksonville</MenuItem>
                                            <MenuItem value="van">Miami</MenuItem>



                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <FormControl className='w-100'>
                                        <Typography component="label">Equipment Make</Typography>
                                        <OutlinedInput value='' />
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <FormControl className='w-100' mt={2}>
                                        <Typography component="label">Equipment Year</Typography>
                                        <OutlinedInput value='' />
                                    </FormControl>
                                </Box>

                            </Box>
                        </Grid>


                    </Grid>


                    <Divider></Divider>
                    <Grid container spacing={4} className='p-15' >

                        <Box className="w-100" mt={2}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                                <Tab label="Maintenance Log" {...a11yProps(0)} />
                                <Tab label="Utilization Log" {...a11yProps(1)} />

                            </Tabs>

                            <TabPanel value={value} index={0}>
                                <Box className="w-100 flex-between" mt={2}>
                                    <Typography component="h5" className='promo-subtitle'>Maintenance Log</Typography>
                                    <Button variant='outlined' mt={2} mb={2} className='retail-btn bg-white' mr={2}>Add</Button>
                                </Box>
                                <Grid container spacing={3} >
                                    <Grid item xs={2} sm={3} md={4} >
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Date</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Employee</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">
                                                    Start Time</Typography>
                                                <OutlinedInput value='' type='time' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">End Time</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Pre Trip Check</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Post Trip Check</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>


                                </Grid>
                                <Box className="member-table w-100" mt={2}>
                                    <MemberTable columns={memeberColumns} options={memberOptions} />
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box className="w-100 flex-between" mt={2}>
                                    <Typography component="h5" className='promo-subtitle'>Utilization Log</Typography>
                                    <Button variant='outlined' mt={2} mb={2} className='retail-btn bg-white' mr={2}>Add</Button>
                                </Box>
                                <Grid container spacing={3} >
                                    <Grid item xs={2} sm={3} md={4} >
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Date</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Type</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Task</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Life of Repair</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Cost</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={3} md={4}>
                                        <Box className="information-div">
                                            <FormControl className='w-100'>
                                                <Typography component="label">Comments</Typography>
                                                <OutlinedInput value='' />
                                            </FormControl>
                                        </Box>
                                    </Grid>


                                </Grid>
                                <Box className="member-table w-100" mt={2}>
                                    <MemberTable columns={memeberColumns} options={memberOptions} />
                                </Box>

                            </TabPanel>

                        </Box>

                    </Grid>






                    <Divider></Divider>
                    <Box className="p-15">
                        <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' onClick={handleClose}>Submit</Button>
                    </Box>
                </Box>
            </Modal >
        </div >
    );
});

export default AddNewModal;
import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Bredcrum from "../Bredcrum";
import MemberTable from "../Customer/MemberTable";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchBar from "material-ui-search-bar";
// import { useDemoData } from "@mui/x-data-grid-generator";

const year = ["2023", "2022", "2021", "2020"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const driverColumns = [
  { name: "Driver Name", options: { filterOptions: { fullWidth: true } } },
  "Driver City",
  "Phone Number",
];
const driverdata = [["Andrew", "Ohio", "8121542167"]];
const driverOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const drivertransportsColumns = [
  { name: "Transport ID", options: { filterOptions: { fullWidth: true } } },
  "Date",
  "Customer Name",
  "Earnings",
];
const drivertransportsOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const driverstatementsColumns = [
  { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
  "End Date",
  "Total Transport",
  "Total Amount",
  "PayOut Date",
  "PayOut mode",
  "Payment CNF ID",
];
const driverstatementsdata = [
  ["02-27-2023", "03-02-2023", "49", "$1234", "", "", ""],
  ["02-27-2023", "03-02-2023", "49", "$1234", "", "", ""],
  ["02-27-2023", "03-02-2023", "49", "$1234", "", "", ""],
  ["02-27-2023", "03-02-2023", "49", "$1234", "", "", ""],
  ["02-27-2023", "03-02-2023", "49", "$1234", "", "", ""],
  ["02-27-2023", "03-02-2023", "49", "$1234", "", "", ""],
  ["02-27-2023", "03-02-2023", "49", "$1234", "", "", ""],
];
const driverstatementsOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "driverName",
    headerName: "Driver Name",
    width: 200,
    editable: true,
  },
  {
    field: "driverCity",
    headerName: "Driver City",
    width: 200,
    editable: true,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    type: "number",
    width: 150,
    editable: true,
  },
];

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const rows = [
  { id: 1, driverCity: "Snow", driverName: "Jon", phoneNumber: 8121542167 },
  {
    id: 2,
    driverCity: "Lannister",
    driverName: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    driverCity: "Lannister",
    driverName: "Jaime",
    phoneNumber: 8121542167,
  },
  { id: 4, driverCity: "Stark", driverName: "Arya", phoneNumber: 8121542167 },
  {
    id: 5,
    driverCity: "Targaryen",
    driverName: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    driverCity: "Melisandre",
    driverName: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    driverCity: "Clifford",
    driverName: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    driverCity: "Frances",
    driverName: "Rossini",
    phoneNumber: 8121542167,
  },
  { id: 9, driverCity: "Roxie", driverName: "Harvey", phoneNumber: 8121542167 },
];

const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Drivers() {
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const ref = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  ></Grid>
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Select
                        labelId="demo-isolation-label"
                        id="isolation-select"
                        className="select"
                        defaultValue="locations"
                      >
                        <MenuItem value="locations">All Locations</MenuItem>
                        <MenuItem>Debray</MenuItem>
                      </Select>
                      <Box className="select_div">
                        <div className="select_div">
                          <Button variant="outlined" className="today">
                            Today
                          </Button>
                          <Button variant="outlined" className="today">
                            Yesterday
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs="12" md="7" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container>
                            <Grid item md="12">
                              <Typography mb={2} className="t-heading">
                                Drivers
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box sx={{ height: 400, width: "100%" }}>
                            <MemberTable
                              columns={driverColumns}
                              options={driverOptions}
                              data={driverdata}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs="12" md="5" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container mb={2}>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Driver Id
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  #2
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Name
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Andrew Carmiche
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mobile}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Phone No.
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  3864733440
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mail}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Email
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  carmiche.andrew@gmail.com
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="12"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={location}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Home Address
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  2201 Newmark Dr Deltona, FL 32738
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Driver Since
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  20 Jul 2022
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mail}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Last Activity
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  25 Days
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid container spacing={2} mt={2}>
                              <Grid item md="6">
                                <Box className="clr-one">
                                  <Typography variant="h5" className="t-value">
                                    79
                                  </Typography>
                                  <Typography variant="h5" className="t-title">
                                    Total Transports
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md="6">
                                <Box className="clr-two">
                                  <Typography variant="h5" className="t-value">
                                    0
                                  </Typography>
                                  <Typography variant="h5" className="t-title">
                                    Total Visits
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid item md="12">
                              <Typography variant="h6" className="t-heading">
                                Pay Statements
                              </Typography>
                            </Grid>
                            <Grid item md="12" mt={2}>
                              <Box sx={{ height: 400, width: "100%" }}>
                                <MemberTable
                                  columns={driverstatementsColumns}
                                  options={driverstatementsOptions}
                                  data={driverstatementsdata}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid item md="12">
                              <Typography variant="h6" className="t-heading">
                                Transports
                              </Typography>
                            </Grid>
                            <Grid item md="12" mt={2}>
                              <Box sx={{ height: 400, width: "100%" }}>
                                <MemberTable
                                  columns={drivertransportsColumns}
                                  options={drivertransportsOptions}
                                  // data={drivertransportsdata}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

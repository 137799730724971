import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput, TextareaAutosize, FormControlLabel, Switch, FormGroup, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import upload from '../images/upload.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const EditProfileModal = forwardRef((props, ref5) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);
      const [show,setShow] =  React.useState(false);

      const handleChange = () =>{ setShow(show=>!show)};
    

    const memeberColumns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        "Last Name",
        "Email",
        "Password"
    ];

    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        // pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref5, () => ({
        log() {
            handleOpen();
        }
    }));



    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content adm-modal'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            EDIT PROFILE
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <Divider></Divider>
                    <Box className="add_new_item">





                        <Grid container spacing={2} className='p-15'>

                            <Grid mt={2} xs={12} md={3}>
                                <Box component="img" src={upload} mt={2} />
                                <Box mt={2}>
                                    <Button variant="contained" component="label" className="btn-upload">
                                        Upload image
                                        <input hidden accept="image/*" multiple type="file" />
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={9} >
                                <Grid container>
                                    <FormControl className="w-100">
                                        <Typography component='label'>Item Name</Typography>
                                        <OutlinedInput placeholder="Item Name" />
                                    </FormControl>
                                </Grid>

                                <Grid className='flex gap-10' spacing={2} mt={1} mb={2}>
                                    <Grid item xs={12} md={6} >
                                        <FormControl className="w-100">
                                            <Typography component='label'>Category</Typography>
                                            <Select
                                                labelId="demo-isolation-label"
                                                id="isolation-select"
                                                className="select"
                                                // onChange={handleSelectOxygen}
                                                // input={<OutlinedInput />}
                                                defaultValue="Retailer"
                                            >
                                                <MenuItem value="Retailer">Retailer</MenuItem>
                                                <MenuItem value="Merchant">Merchant</MenuItem>


                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormControl className="w-100">
                                            <Typography component='label'>City</Typography>
                                            <Select
                                                labelId="demo-isolation-label"
                                                id="isolation-select"
                                                className="select"
                                                // onChange={handleSelectOxygen}
                                                // input={<OutlinedInput />}
                                                defaultValue="Kurnool"
                                            >
                                                <MenuItem value="Kurnool">Kurnool</MenuItem>
                                                <MenuItem value="one">One</MenuItem>
                                                <MenuItem value="two">Two</MenuItem>
                                                <MenuItem value="two">Three</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Grid className='flex gap-10' spacing={2} >
                                    <Grid item xs={12} md={6} >
                                        <FormControl className="w-100">
                                            <Typography component='label'>Primary Email</Typography>
                                            <OutlinedInput value="027avenue@gmail.com" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormControl className="w-100">
                                            <Typography component='label'>Phone Number</Typography>
                                            <OutlinedInput value="90528 45869" />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Grid container mb={1} mt={2}>
                                    <FormControl className="w-100" >
                                        <Typography component='label'>Having a GST Number ?</Typography>
                                        <IOSSwitch onChange={handleChange} />
                                    </FormControl>
                                </Grid>
                                {
                                    show?<Grid className='flex gap-10' spacing={2} >
                                    <Grid item xs={12} md={6} >
                                        <FormControl className="w-100">
                                            <Typography component='label'>GST Number</Typography>
                                            <OutlinedInput value="123456789" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormControl className="w-100">
                                            <Typography component='label'>GST Tax Rate</Typography>
                                            <OutlinedInput placeholder="18" />
                                        </FormControl>
                                    </Grid>

                                </Grid>:''
                                }
                            </Grid>
                            <Divider></Divider>
                            <Grid container spacing={3} mt={1} mb={3} className="textarea-div">
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>Bank Name</Typography>
                                        <OutlinedInput value="Indian Overseas Bank" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>Branch Name</Typography>
                                        <OutlinedInput value="Vada Branch" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>IFSC Code</Typography>
                                        <OutlinedInput value="IOBA0001649" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>Account Number</Typography>
                                        <OutlinedInput value="164901000004325" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>Account Holder Name</Typography>
                                        <OutlinedInput placeholder="027Avenue" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>Other UPI ID's (Enter UPI Id's with comma separated values)</Typography>
                                        <OutlinedInput placeholder="venkat@ybl" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>Paytm No</Typography>
                                        <OutlinedInput placeholder="90528 45869" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>Google Pay No</Typography>
                                        <OutlinedInput placeholder="90528 45869" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className="w-100">
                                        <Typography component='label'>Phonepe No</Typography>
                                        <OutlinedInput placeholder="90528 45869" />
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                    <Divider></Divider>
                    <Box className="p-15">
                        <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' onClick={handleClose}>Submit</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
});

export default EditProfileModal;
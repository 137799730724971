import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput, TextareaAutosize, FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import id from '../images/ID.svg';
import customers from '../images/customers.svg';
import mobile from "../images/mobile.svg";
import location from '../images/location.svg';
import status from '../images/order.svg';
import active from '../images/last-active.svg';
import rupee from '../images/rupee.svg';
import mail from '../images/mail.svg';
import MemberTable from '../Customer/MemberTable';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ViewMoreModalRetailEdit = forwardRef((props, ref3, ref4) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);



    const memeberColumns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        "Last Name",
        "Email",
        "Password"
    ];

    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        // pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref3, () => ({
        log() {
            handleOpen();
        }
    }));

    useImperativeHandle(ref4, () => ({
        log() {
            handleOpen();
        }
    }));

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content retail-edit'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            RETAILERS DETAILS
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <Divider></Divider>
                    <Grid container spacing={{ xs: 2, md: 3 }} className='p-15' columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4} >
                            <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                    <Typography component="label">Retailer Name</Typography>
                                    <OutlinedInput value='Jaya Surya' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                    <Typography component="label">Phone no.</Typography>
                                    <OutlinedInput value='+91 9876543210' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Email</Typography>
                                    <OutlinedInput value='jayasurya.thota@gmail.com' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Retailer Address</Typography>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={3}
                                        value="Kukatpally, Kapila Nagar Colony, Hyderabad"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Retailer Store Type</Typography>
                                    <OutlinedInput value='Grocery' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">COD</Typography>
                                    <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }} />}

                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Credit Limit</Typography>
                                    <OutlinedInput value='₹ 50,000' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Block Retailer</Typography>
                                    <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }} />}

                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Join Date</Typography>
                                    <OutlinedInput type="date" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                    <Typography component="label">Last Activity</Typography>
                                    <OutlinedInput type="date" />
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>



                    
                    <Divider></Divider>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            BANK INFO
                        </Typography>

                    </Box>
                    <Grid container spacing={{ xs: 2, md: 3 }} className='p-15' columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4} >
                            <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                    <Typography component="label">Bank Name</Typography>
                                    <OutlinedInput value='State Bank of India' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                    <Typography component="label">Bank Branch</Typography>
                                    <OutlinedInput value='Muvvalavani Palem' />

                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">IFSC Code</Typography>
                                    <OutlinedInput value='SBIN0123456' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Account Number</Typography>
                                    <OutlinedInput value='012344567890' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Account Holder Name</Typography>
                                    <OutlinedInput value='Venkat' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Payment Terms</Typography>
                                    <OutlinedInput value='Cheque' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Paytm Number</Typography>
                                    <OutlinedInput value='1234567890' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Google Pay Number</Typography>
                                    <OutlinedInput value='1234567890' />

                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Phonepe Number</Typography>
                                    <OutlinedInput value='1234567890' />

                                </Box>
                            </Box>
                        </Grid>




                    </Grid>


                    <Divider></Divider>
                    <Box className="p-15">
                        <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' onClick={handleClose}>Submit</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
});

export default ViewMoreModalRetailEdit;
import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import ambulance from "../images/Ambulance.svg";
import transports from "../images/transports.svg";
import customer from "../images/c_customer.svg";
import dollar from "../images/dollar.svg";
import ceye from "../images/C-eye.svg";
import transport from "../images/transport.png";
import Revenue from "../images/revenue.png";
import cust from "../images/customer.png";
import utilization from "../images/utilization.png";
import { OutlinedInput, FormControl } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Calender from "../Calender/Calender";
import { Image, Label } from "@mui/icons-material";
import AddCustomerModal from "../Customer/AddCustomerModal";
import Bredcrum from "../Bredcrum";
import MemberTable from "../Customer/MemberTable";
import EditModal from "../Customer/EditModal";
import ViewMoreModal from "../Customer/ViewMoreModal";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchBar from "material-ui-search-bar";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Customers() {
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters customer-account"
                    >
                      <Box>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Locations</MenuItem>
                          <MenuItem>Debray</MenuItem>
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">Member</MenuItem>
                          <MenuItem>Non Member</MenuItem>
                        </Select>
                      </Box>
                      <Box className="date-download">
                        <FormControl>
                          <OutlinedInput type="date" />
                        </FormControl>
                        <FormControl>
                          <OutlinedInput type="date" />
                        </FormControl>
                        <Button
                          color="error"
                          ml={3}
                          variant="contained"
                          className="download-btn"
                        >
                          Download Report
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container mt={3} className="pickers">
                    <Grid xs="12" mt={2} mb={2} className="transport-div">
                      <Box>
                        <Typography component="h4">0</Typography>
                        <Typography component="p" className="text-green">
                          No Of Transports
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="h4">$0.00</Typography>
                        <Typography component="p" className="text-pink">
                          Total Amount
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="h4">$0</Typography>
                        <Typography component="p" className="text-sand">
                          Driver Fee
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="h4">$0.00</Typography>
                        <Typography component="p" className="text-green">
                          No Of Transports
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={3} spacing={2} style={{ display: "flex" }}>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <MemberTable />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent></CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddCustomerModal ref={ref} />
        <EditModal ref={ref1} />
        <ViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}

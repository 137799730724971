import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Select, MenuItem } from '@mui/material';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Hair Steamer', <Select
    labelId="demo-isolation-label"
    id="isolation-select"
    className="select"
    // onChange={handleSelectOxygen}
    // input={<OutlinedInput />}
    defaultValue="Suppliers"

  >
    <MenuItem value="Suppliers">Select Priority</MenuItem>
    <MenuItem value="car">High</MenuItem>
    <MenuItem value="van">Medium</MenuItem>
    <MenuItem value="van">Low</MenuItem>


  </Select>, <Select
    labelId="demo-isolation-label"
    id="isolation-select"
    className="select"
    // onChange={handleSelectOxygen}
    // input={<OutlinedInput />}
    defaultValue="Suppliers"

  >
    <MenuItem value="Suppliers">Select Roles</MenuItem>
    <MenuItem value="car">Driver</MenuItem>
    <MenuItem value="van">Dispatcher</MenuItem>
    <MenuItem value="van">Cashier</MenuItem>

  </Select>, <Select
    labelId="demo-isolation-label"
    id="isolation-select"
    className="select"
    // onChange={handleSelectOxygen}
    // input={<OutlinedInput />}
    defaultValue="Suppliers"

  >
    <MenuItem value="Suppliers">Select Type</MenuItem>
    <MenuItem value="car">SMS</MenuItem>
    <MenuItem value="van">Email</MenuItem>
    <MenuItem value="van">In App Notification</MenuItem>
  </Select>, 'red'),
  createData('Hair Straightner', <Select
  labelId="demo-isolation-label"
  id="isolation-select"
  className="select"
  // onChange={handleSelectOxygen}
  // input={<OutlinedInput />}
  defaultValue="Suppliers"

>
  <MenuItem value="Suppliers">Select Priority</MenuItem>
  <MenuItem value="car">High</MenuItem>
  <MenuItem value="van">Medium</MenuItem>
  <MenuItem value="van">Low</MenuItem>


</Select>, <Select
  labelId="demo-isolation-label"
  id="isolation-select"
  className="select"
  // onChange={handleSelectOxygen}
  // input={<OutlinedInput />}
  defaultValue="Suppliers"

>
  <MenuItem value="Suppliers">Select Roles</MenuItem>
  <MenuItem value="car">Driver</MenuItem>
  <MenuItem value="van">Dispatcher</MenuItem>
  <MenuItem value="van">Cashier</MenuItem>

</Select>, <Select
  labelId="demo-isolation-label"
  id="isolation-select"
  className="select"
  // onChange={handleSelectOxygen}
  // input={<OutlinedInput />}
  defaultValue="Suppliers"

>
  <MenuItem value="Suppliers">Select Type</MenuItem>
  <MenuItem value="car">SMS</MenuItem>
  <MenuItem value="van">Email</MenuItem>
  <MenuItem value="van">In App Notification</MenuItem>
</Select>, 'Orange'),
];

export default function EquipmentTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>TASK NAME</TableCell>
            <TableCell align="right">PRIORITY</TableCell>
            <TableCell align="right">ALERT</TableCell>
            <TableCell align="right">ALERT TYPE</TableCell>
            <TableCell align="right">STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import id from '../images/ID.svg';
import customers from '../images/customers.svg';
import mobile from "../images/mobile.svg";
import location from '../images/location.svg';
import status from '../images/order.svg';
import active from '../images/last-active.svg';
import rupee from '../images/rupee.svg';
import mail from '../images/mail.svg';
import MemberTable from './MemberTable';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ViewMoreModal = forwardRef((props, ref3) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);

    const memeberColumns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        "Last Name",
        "Email",
        "Password"
    ];

    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        // pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref3, () => ({
        log() {
            handleOpen();
        }
    }));

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            CUSTOMER DETAILS
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <Divider></Divider>
                    <Grid container spacing={{ xs: 2, md: 3 }} className='p-15' columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4} >
                            <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                    <Typography component="label">Customer Name</Typography>
                                    <Typography>Access2Care</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                    <Typography component="label">Phone no.</Typography>
                                    <Typography>+1 303-495-1667</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Email</Typography>
                                    <Typography>A2Cclaimsdenver@EMSC.net</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">COD</Typography>
                                    <Typography>Blocked</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                    <Typography component="label">Block Status</Typography>
                                    <Typography>Not Blocked</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="locations"
                            >
                                <MenuItem value="locations">Saved Address</MenuItem>
                                <MenuItem>OFFICE</MenuItem>

                            </Select>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={mail} />
                                <Box>
                                    <Typography component="label">Admin Email</Typography>
                                    <Typography>A2Cclaimsdenver@EMSC.net</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                    <Typography component="label">Admin Password</Typography>
                                    <Typography>******</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <FormControl>
                                <Typography component="label">Reset Password</Typography>
                                <OutlinedInput placeholder='Enter Password' />
                                <Button variant='outlined' color='error' mt={2} mb={2} className='reset-btn'>Reset</Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Box className="p-15">
                        <Typography component="label">
                            Office
                        </Typography>
                        <Typography>
                            6200 S.Syracuse Way, STE 200 Greenwood village,<br />
                            CO 80111
                        </Typography>
                    </Box>
                    <Divider></Divider>
                    <Grid container spacing={2} className='p-15'>
                        <Grid item xs={6} mt={2}>
                            <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                    <Typography component="label">Customer Since</Typography>
                                    <Typography>20 Jul 2022</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} mt={2}>
                            <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                    <Typography component="label">Last Activity</Typography>
                                    <Typography>-</Typography>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className='p-15'>

                        <Grid item xs={6} >
                            <Box className="clr-one">
                                <Typography component="h2">
                                    0
                                </Typography>
                                <Typography component="p">
                                    Total Transports
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className="clr-two">
                                <Typography component="h2">
                                    $ 0.00
                                </Typography>
                                <Typography component="p">
                                    Total Transports
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Grid container className='p-15'>
                        <Typography component="h2">TRANSACTIONS STATEMENTS</Typography>
                    </Grid>
                    <Box pl={2} pr={2}>
                    <MemberTable columns={memeberColumns} options={memberOptions}  />
                    </Box>
                    <Divider></Divider>
                    <Grid container className='add-user-div p-15'>
                        
                            <Typography component="h2">Company</Typography>
                            <Button variant='outlined' color='error' mt={2} mb={2} className='save-btn' onClick={handleOpenAdd}>Add user</Button>
                       

                    </Grid>
                    <Box pl={2} pr={2}>
                    <MemberTable columns={memeberColumns} options={memberOptions}  />
                    </Box>
                    
                    <Divider></Divider>
                    <Box className="p-15">
                    <Button variant='outlined' color='error' mt={2} mb={2} className='save-btn' onClick={handleClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={addOpen}
                onClose={handleCloseAdd}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box className='add-user-modal' sx={style}>
                    <Box className="add-user-div p-15">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add New user
                        </Typography>
                        <CloseIcon onClick={handleCloseAdd} />
                    </Box>
                    <Divider></Divider>
                    <Grid className="inputfield-container" container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} mt={2}>

                        <Grid item xs={2} sm={4} md={4} >
                            <FormControl >
                                <Typography component="label">First Name</Typography>
                                <OutlinedInput />

                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4} >
                            <FormControl>
                                <Typography component="label">Last Name</Typography>
                                <OutlinedInput />

                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <FormControl  >
                                <Typography component="label">Phone No</Typography>
                                <OutlinedInput />

                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <FormControl  >
                                <Typography component="label">Email</Typography>
                                <OutlinedInput />

                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <FormControl  >
                                <Typography component="label">Password</Typography>
                                <OutlinedInput />

                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Button variant='outlined' color='error' mt={2} mb={2} className='save-btn p-15'>Save</Button>
                </Box>
            </Modal>
        </div>
    );
});

export default ViewMoreModal;